import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { link } from './styles/styles';
const sizeMap = {
    xs: '2xs',
    sm: 'xs',
    md: 'sm',
    lg: 'md',
};
export const Link = forwardRef(({ icon, disabled, children, alignIcon = 'left', variant, size, as: Component = 'a', ...props }, ref) => {
    const tokens = useTokens();
    const styles = link(tokens)({
        disabled,
        variant,
        size,
    });
    return (_jsxs(Component, { css: styles, ref: ref, ...props, children: [icon && alignIcon === 'left' ? (_jsx(Icon, { as: icon, size: mapResponsiveValue(size, (size) => sizeMap[size]), css: styles.icon })) : null, children, icon && alignIcon === 'right' ? (_jsx(Icon, { as: icon, size: mapResponsiveValue(size, (size) => sizeMap[size]), css: styles.icon })) : null] }));
});
Link.displayName = 'Link';
