import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { Children, forwardRef } from 'react';
/**
 * A list of items that can be used to link to other pages within the navigation. Important: a maximum of five items will be shown. If more items are provided they will not be displayed.
 */
export const NavigationBarActionsPrimaryList = forwardRef(({ items, onBackground, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { as: "ul", direction: "horizontal", align: "center", css: {
            gap: tokens.styles[`navigation-bar-actions-primary-navigationList-base-gap`],
            listStyleType: 'none',
            marginBlock: 0,
            marginInline: 0,
            paddingInline: 0,
        }, ...props, ref: ref, children: Children.map(items.map((item) => item?.({ onBackground })), (item) => item) }));
});
NavigationBarActionsPrimaryList.displayName = 'NavigationBarActionsPrimaryList';
