import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { badgeIconIcon } from './styles/badge-icon-icon';
export const BadgeIconIcon = forwardRef(({ variant, size, ...props }, ref) => {
    const tokens = useTokens();
    const styles = badgeIconIcon(tokens)({ size, variant });
    return (_jsx(Icon, { css: styles, ...props, size: mapResponsiveValue(size, (size) => ({
            sm: 'xs',
            md: 'sm',
            lg: 'md',
        }[size])), ref: ref }));
});
BadgeIconIcon.displayName = 'BadgeIconIcon';
