import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Divider } from '../../component-divider';
import { useTokens } from '../../component-themes';
import { Children, forwardRef } from 'react';
import { featureList } from './styles/feature-list';
export const FeatureList = forwardRef(({ size, items, gridSize, as: Component = 'div', variant, withDivider, ...props }, ref) => {
    const tokens = useTokens();
    const styles = featureList(tokens)({
        size,
        gridSize,
        noOfItems: items.length,
        variant,
    });
    return (_jsx(Component, { css: styles.list, ...props, ref: ref, children: Children.map(items.map((item) => item({
            size,
            variant,
        })), (child, index) => withDivider ? (_jsxs("div", { css: styles.item, children: [child, _jsx(Divider, { emphasis: "low", orientation: "horizontal", css: styles.divider(index) })] })) : (child)) }));
});
FeatureList.displayName = 'FeatureList';
