import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { m as motion } from 'framer-motion';
import { useMemo } from 'react';
import { forwardRef } from 'react';
import { paginationBullet } from './styles/pagination-bullet';
/**
 * Is used to display pagination bullets.
 */
export const PaginationBullet = forwardRef(({ as: Component = 'div', state = 'inactive', variant, visible, ...props }, ref) => {
    const tokens = useTokens();
    const styles = paginationBullet(tokens)({ state, variant, visible });
    const MotionComponent = useMemo(() => motion(Component), [Component]);
    // @ts-expect-error framer-motion overrides types in an incompatible manner
    return _jsx(MotionComponent, { ...props, css: styles, ref: ref, layout: true });
});
PaginationBullet.displayName = 'PaginationBullet';
