import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box } from '../../component-box';
import { Stack } from '../../component-stack';
import { Children, forwardRef } from 'react';
/**
 * Used to display legal information within the `Footer` component.
 */
export const FooterLegalInformation = forwardRef(({ text, footnotes, ...props }, ref) => {
    return (_jsxs(Stack, { direction: "vertical", spacing: "small-md", ...props, ref: ref, children: [text?.({}), _jsx(Box, { children: Children.map(footnotes?.map((footnote) => footnote({})), (footnote) => footnote) })] }));
});
FooterLegalInformation.displayName = 'FooterLegalInformation';
