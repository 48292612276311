import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { forwardRef } from 'react';
/**
 * Should be used to display the footer of `EditorialTeaserContent`.
 */
export const EditorialTeaserContentFooter = forwardRef(({ size, variant, button, tags, ...props }, ref) => {
    return (_jsxs(Stack, { spacing: "small-md", justify: "space-between", align: "center", ...props, ref: ref, children: [button?.({ size }), tags?.({ variant })] }));
});
EditorialTeaserContentFooter.displayName = 'EditorialTeaserContentFooter';
