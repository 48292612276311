import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the detail list in the `OrderSummaryContent`
 */
export const OrderSummaryContentDetailList = forwardRef(({ size, items, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { direction: "vertical", css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`orderSummary-content-detailList-base-${size}-gap`],
        })), ...props, ref: ref, children: items({ size }) }));
});
OrderSummaryContentDetailList.displayName = 'OrderSummaryContentDetailList';
