import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Info } from '../../component-icons';
import { Link } from '../../component-link';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display the link within `EnergyLabelClassification` that should be used to link to the EU data sheet.
 */
export const EnergyLabelClassificationLink = forwardRef(({ size, ...props }, ref) => {
    return (_jsx(Link, { size: mapResponsiveValue(size, (size) => (size === 'sm' ? 'xs' : 'sm')), variant: "prominent", alignIcon: 'right', icon: Info, target: "_blank", ref: ref, ...props }));
});
EnergyLabelClassificationLink.displayName = 'EnergyLabelClassificationLink';
