import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { Minus } from '../../component-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * `SelectAmountDecrement` subcomponent is used to decrement the amount in `SelectAmount`. The `SelectAmountDecrement` subcomponent is using the `ButtonAction` component.
 */
export const SelectAmountDecrement = forwardRef(({ min, value, disabled, variant, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(ButtonAction, { icon: Minus, variant: variant === 'default' ? 'subtle' : 'prominent', disabled: disabled || (min != null && value != null && value <= min), css: {
            borderTopRightRadius: tokens.radii.none,
            borderBottomRightRadius: tokens.radii.none,
        }, ...props, ref: ref }));
});
SelectAmountDecrement.displayName = 'SelectAmountDecrement';
