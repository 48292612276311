import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { productTitleHeadlineValueClass } from './styles/product-title-headline-value-class';
/**
 * Used to show the value class within `ProductTitleHeadline`.
 */
export const ProductTitleHeadlineValueClass = forwardRef(({ as: Component = 'span', size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = productTitleHeadlineValueClass(tokens)({ size, variant });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
ProductTitleHeadlineValueClass.displayName = 'ProductTitleHeadlineValueClass';
