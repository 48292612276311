import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { PriceSubline } from '../../component-price';
import { forwardRef } from 'react';
export const ProductTeaserMiniContentInformationPriceSubline = forwardRef(({ ...props }, ref) => {
    return (_jsx(PriceSubline, { css: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '20ch',
        }, ...props, ref: ref }));
});
ProductTeaserMiniContentInformationPriceSubline.displayName =
    'ProductTeaserMiniContentInformationPriceSubline';
