import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const navigationBarBrandAreaBase = (tokens) => ({ size, }) => {
    return {
        brandArea: mergeStyles([
            {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingInlineEnd: tokens.styles[`navigation-bar-brandArea-base-${size}-paddingRight`],
            },
        ]),
    };
};
export const navigationBarBrandArea = (tokens) => ({ size, variant, }) => ({
    brandArea: getResponsiveStyles(size, (size) => navigationBarBrandAreaBase(tokens)({ size, variant }).brandArea),
});
