import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the product teaser list in the `OrderSummaryContent`
 */
export const OrderSummaryContentProductTeaserList = forwardRef(({ size, teaser, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { direction: "vertical", css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`orderSummary-content-productTeaserList-base-${size}-gap`],
        })), ...props, ref: ref, children: teaser({ size, variant: 'default' }) }));
});
OrderSummaryContentProductTeaserList.displayName =
    'OrderSummaryContentProductTeaserList';
