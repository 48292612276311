import { iconBase } from '../../../component-icon';
import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
import Color from 'color';
export const buttonBase = (tokens) => ({ variant, size, isLoading, hovered, active, }) => {
    const buttonInitalStyles = {
        backgroundColor: tokens.styles[`button-base-${variant}-${size}-initial-background`],
        height: tokens.styles[`button-base-${variant}-${size}-initial-height`],
        paddingInlineStart: tokens.styles[`button-base-${variant}-${size}-initial-paddingLeft`],
        paddingInlineEnd: tokens.styles[`button-base-${variant}-${size}-initial-paddingRight`],
        borderStyle: tokens.styles[`button-base-${variant}-${size}-initial-borderStyle`],
        borderRadius: tokens.styles[`button-base-${variant}-${size}-initial-borderRadius`],
        borderWidth: tokens.styles[`button-base-${variant}-${size}-initial-borderWidth`],
        borderColor: tokens.styles[`button-base-${variant}-${size}-initial-borderColor`],
        opacity: tokens.styles[`button-base-${variant}-${size}-initial-opacity`],
        gap: tokens.styles[`button-base-${variant}-${size}-initial-gap`],
    };
    const buttonHoverStyles = {
        backgroundColor: tokens.styles[`button-base-${variant}-${size}-hover-background`],
        height: tokens.styles[`button-base-${variant}-${size}-hover-height`],
        paddingInlineStart: tokens.styles[`button-base-${variant}-${size}-hover-paddingLeft`],
        paddingInlineEnd: tokens.styles[`button-base-${variant}-${size}-hover-paddingRight`],
        borderStyle: tokens.styles[`button-base-${variant}-${size}-hover-borderStyle`],
        borderRadius: tokens.styles[`button-base-${variant}-${size}-hover-borderRadius`],
        borderWidth: tokens.styles[`button-base-${variant}-${size}-hover-borderWidth`],
        borderColor: tokens.styles[`button-base-${variant}-${size}-hover-borderColor`],
        opacity: tokens.styles[`button-base-${variant}-${size}-hover-opacity`],
        gap: tokens.styles[`button-base-${variant}-${size}-hover-gap`],
    };
    const buttonPressedStyles = {
        backgroundColor: tokens.styles[`button-base-${variant}-${size}-pressed-background`],
        height: tokens.styles[`button-base-${variant}-${size}-pressed-height`],
        paddingInlineStart: tokens.styles[`button-base-${variant}-${size}-pressed-paddingLeft`],
        paddingInlineEnd: tokens.styles[`button-base-${variant}-${size}-pressed-paddingRight`],
        borderStyle: tokens.styles[`button-base-${variant}-${size}-pressed-borderStyle`],
        borderRadius: tokens.styles[`button-base-${variant}-${size}-pressed-borderRadius`],
        borderWidth: tokens.styles[`button-base-${variant}-${size}-pressed-borderWidth`],
        borderColor: tokens.styles[`button-base-${variant}-${size}-pressed-borderColor`],
        opacity: tokens.styles[`button-base-${variant}-${size}-pressed-opacity`],
        gap: tokens.styles[`button-base-${variant}-${size}-pressed-gap`],
    };
    const buttonFocusedStyles = {
        backgroundColor: tokens.styles[`button-base-${variant}-${size}-focused-background`],
        height: tokens.styles[`button-base-${variant}-${size}-focused-height`],
        paddingInlineStart: tokens.styles[`button-base-${variant}-${size}-focused-paddingLeft`],
        paddingInlineEnd: tokens.styles[`button-base-${variant}-${size}-focused-paddingRight`],
        borderStyle: tokens.styles[`button-base-${variant}-${size}-focused-borderStyle`],
        borderRadius: tokens.styles[`button-base-${variant}-${size}-focused-borderRadius`],
        borderWidth: tokens.styles[`button-base-${variant}-${size}-focused-borderWidth`],
        borderColor: tokens.styles[`button-base-${variant}-${size}-focused-borderColor`],
        opacity: tokens.styles[`button-base-${variant}-${size}-focused-opacity`],
        gap: tokens.styles[`button-base-${variant}-${size}-focused-gap`],
    };
    const buttonDisabledStyles = {
        backgroundColor: tokens.styles[`button-base-${variant}-${size}-disabled-background`],
        height: tokens.styles[`button-base-${variant}-${size}-disabled-height`],
        paddingInlineStart: tokens.styles[`button-base-${variant}-${size}-disabled-paddingLeft`],
        paddingInlineEnd: tokens.styles[`button-base-${variant}-${size}-disabled-paddingRight`],
        borderStyle: tokens.styles[`button-base-${variant}-${size}-disabled-borderStyle`],
        borderRadius: tokens.styles[`button-base-${variant}-${size}-disabled-borderRadius`],
        borderWidth: tokens.styles[`button-base-${variant}-${size}-disabled-borderWidth`],
        borderColor: tokens.styles[`button-base-${variant}-${size}-disabled-borderColor`],
        opacity: tokens.styles[`button-base-${variant}-${size}-disabled-opacity`],
        gap: tokens.styles[`button-base-${variant}-${size}-disabled-gap`],
    };
    const labelInitialStyles = {
        fontFamily: tokens.styles[`button-label-${variant}-${size}-initial-fontFamily`],
        fontWeight: tokens.styles[`button-label-${variant}-${size}-initial-fontWeight`],
        lineHeight: tokens.styles[`button-label-${variant}-${size}-initial-lineHeight`],
        fontSize: tokens.styles[`button-label-${variant}-${size}-initial-fontSize`],
        letterSpacing: tokens.styles[`button-label-${variant}-${size}-initial-letterSpacing`],
        color: Color(tokens.styles[`button-label-${variant}-${size}-initial-color`])
            .alpha(tokens.styles[`button-label-${variant}-${size}-initial-opacity`])
            .string(),
    };
    const labelHoverStyles = {
        fontFamily: tokens.styles[`button-label-${variant}-${size}-hover-fontFamily`],
        fontWeight: tokens.styles[`button-label-${variant}-${size}-hover-fontWeight`],
        lineHeight: tokens.styles[`button-label-${variant}-${size}-hover-lineHeight`],
        fontSize: tokens.styles[`button-label-${variant}-${size}-hover-fontSize`],
        letterSpacing: tokens.styles[`button-label-${variant}-${size}-hover-letterSpacing`],
        color: Color(tokens.styles[`button-label-${variant}-${size}-hover-color`])
            .alpha(tokens.styles[`button-label-${variant}-${size}-hover-opacity`])
            .string(),
    };
    const labelPressedStyles = {
        fontFamily: tokens.styles[`button-label-${variant}-${size}-pressed-fontFamily`],
        fontWeight: tokens.styles[`button-label-${variant}-${size}-pressed-fontWeight`],
        lineHeight: tokens.styles[`button-label-${variant}-${size}-pressed-lineHeight`],
        fontSize: tokens.styles[`button-label-${variant}-${size}-pressed-fontSize`],
        letterSpacing: tokens.styles[`button-label-${variant}-${size}-pressed-letterSpacing`],
        color: Color(tokens.styles[`button-label-${variant}-${size}-pressed-color`])
            .alpha(tokens.styles[`button-label-${variant}-${size}-pressed-opacity`])
            .string(),
    };
    const labelFocusedStyles = {
        fontFamily: tokens.styles[`button-label-${variant}-${size}-focused-fontFamily`],
        fontWeight: tokens.styles[`button-label-${variant}-${size}-focused-fontWeight`],
        lineHeight: tokens.styles[`button-label-${variant}-${size}-focused-lineHeight`],
        fontSize: tokens.styles[`button-label-${variant}-${size}-focused-fontSize`],
        letterSpacing: tokens.styles[`button-label-${variant}-${size}-focused-letterSpacing`],
        color: Color(tokens.styles[`button-label-${variant}-${size}-focused-color`])
            .alpha(tokens.styles[`button-label-${variant}-${size}-focused-opacity`])
            .string(),
    };
    const labelDisabledStyles = {
        fontFamily: tokens.styles[`button-label-${variant}-${size}-disabled-fontFamily`],
        fontWeight: tokens.styles[`button-label-${variant}-${size}-disabled-fontWeight`],
        lineHeight: tokens.styles[`button-label-${variant}-${size}-disabled-lineHeight`],
        fontSize: tokens.styles[`button-label-${variant}-${size}-disabled-fontSize`],
        letterSpacing: tokens.styles[`button-label-${variant}-${size}-disabled-letterSpacing`],
        color: Color(tokens.styles[`button-label-${variant}-${size}-disabled-color`])
            .alpha(tokens.styles[`button-label-${variant}-${size}-disabled-opacity`])
            .string(),
    };
    const iconStyles = iconBase(tokens);
    return {
        button: mergeStyles([
            {
                position: 'relative',
                overflow: 'hidden',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'inline-flex',
                cursor: 'pointer',
                maxWidth: '100%',
                transition: `all ${tokens.animation['duration-quick']} ${tokens.animation['timing-soft']}`,
                ...buttonInitalStyles,
                ...labelInitialStyles,
                '@media (hover: hover)': {
                    ':hover': hovered == null
                        ? { ...buttonHoverStyles, ...labelHoverStyles }
                        : undefined,
                },
                ':focus-visible': { ...buttonFocusedStyles, ...labelFocusedStyles },
                '@supports not selector(:focus-visible)': {
                    ':focus': { ...buttonFocusedStyles, ...labelFocusedStyles },
                },
                ':active': active == null
                    ? { ...buttonPressedStyles, ...labelPressedStyles }
                    : undefined,
                ':disabled': {
                    ...buttonDisabledStyles,
                    ...labelDisabledStyles,
                    cursor: 'not-allowed',
                },
            },
            isLoading
                ? { ...buttonDisabledStyles, ...labelDisabledStyles, cursor: 'wait' }
                : {},
            hovered ? { ...buttonHoverStyles, ...labelHoverStyles } : {},
            active ? { ...buttonPressedStyles, ...labelPressedStyles } : {},
        ]),
        icon: mergeStyles([
            iconStyles({ size }),
            {
                color: 'inherit',
            },
        ]),
    };
};
export const button = (tokens) => ({ variant, size, isLoading, hovered, active, }) => ({
    button: getResponsiveStyles(size, (size) => buttonBase(tokens)({ variant, size, isLoading, hovered, active })
        .button),
    icon: getResponsiveStyles(size, (size) => buttonBase(tokens)({ variant, size, isLoading }).icon),
});
