import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Heading } from '../../component-heading';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display a headline within the `FooterLinkList`.
 */
export const FooterLinkListHeadline = forwardRef(({ ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Heading, { as: "h3", size: "large-6", emphasis: "high", css: [{ color: tokens.styles['footer-linklist-headline-background'] }], ...props, ref: ref }));
});
FooterLinkListHeadline.displayName = 'FooterLinkListHeadline';
