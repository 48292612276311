import { combineResponsiveValues, getResponsiveStyles, mergeStyles, } from '../../../component-themes';
export const linkBase = (tokens) => ({ variant, size, disabled, }) => {
    const linkInitialStyles = {
        gap: tokens.styles[`link-base-${size}-initial-gap`],
    };
    const linkHoverStyles = {
        gap: tokens.styles[`link-base-${size}-hover-gap`],
    };
    const linkPressedStyles = {
        gap: tokens.styles[`link-base-${size}-pressed-gap`],
    };
    const linkFocusedStyles = {
        gap: tokens.styles[`link-base-${size}-focused-gap`],
    };
    const linkDisabledStyles = {
        gap: tokens.styles[`link-base-${size}-disabled-gap`],
    };
    const labelinitalStyles = {
        fontFamily: tokens.styles[`link-label-${variant}-${size}-initial-fontFamily`],
        fontWeight: tokens.styles[`link-label-${variant}-${size}-initial-fontWeight`],
        lineHeight: tokens.styles[`link-label-${variant}-${size}-initial-lineHeight`],
        letterSpacing: tokens.styles[`link-label-${variant}-${size}-initial-letterSpacing`],
        fontSize: tokens.styles[`link-label-${variant}-${size}-initial-fontSize`],
        color: tokens.styles[`link-label-${variant}-${size}-initial-color`],
        textDecoration: tokens.styles[`link-label-${variant}-${size}-initial-textDecoration`],
        opacity: tokens.styles[`link-label-${variant}-${size}-initial-opacity`],
    };
    const labelHoverStyles = {
        fontFamily: tokens.styles[`link-label-${variant}-${size}-hover-fontFamily`],
        fontWeight: tokens.styles[`link-label-${variant}-${size}-hover-fontWeight`],
        lineHeight: tokens.styles[`link-label-${variant}-${size}-hover-lineHeight`],
        letterSpacing: tokens.styles[`link-label-${variant}-${size}-hover-letterSpacing`],
        fontSize: tokens.styles[`link-label-${variant}-${size}-hover-fontSize`],
        color: tokens.styles[`link-label-${variant}-${size}-hover-color`],
        textDecoration: tokens.styles[`link-label-${variant}-${size}-hover-textDecoration`],
        opacity: tokens.styles[`link-label-${variant}-${size}-hover-opacity`],
    };
    const labelFocusedStyles = {
        fontFamily: tokens.styles[`link-label-${variant}-${size}-focused-fontFamily`],
        fontWeight: tokens.styles[`link-label-${variant}-${size}-focused-fontWeight`],
        lineHeight: tokens.styles[`link-label-${variant}-${size}-focused-lineHeight`],
        letterSpacing: tokens.styles[`link-label-${variant}-${size}-focused-letterSpacing`],
        fontSize: tokens.styles[`link-label-${variant}-${size}-focused-fontSize`],
        color: tokens.styles[`link-label-${variant}-${size}-focused-color`],
        textDecoration: tokens.styles[`link-label-${variant}-${size}-focused-textDecoration`],
        opacity: tokens.styles[`link-label-${variant}-${size}-focused-opacity`],
    };
    const labelPressedStyles = {
        fontFamily: tokens.styles[`link-label-${variant}-${size}-pressed-fontFamily`],
        fontWeight: tokens.styles[`link-label-${variant}-${size}-pressed-fontWeight`],
        lineHeight: tokens.styles[`link-label-${variant}-${size}-pressed-lineHeight`],
        letterSpacing: tokens.styles[`link-label-${variant}-${size}-pressed-letterSpacing`],
        fontSize: tokens.styles[`link-label-${variant}-${size}-pressed-fontSize`],
        color: tokens.styles[`link-label-${variant}-${size}-pressed-color`],
        textDecoration: tokens.styles[`link-label-${variant}-${size}-pressed-textDecoration`],
        opacity: tokens.styles[`link-label-${variant}-${size}-pressed-opacity`],
    };
    const labelDisabledStyles = {
        fontFamily: tokens.styles[`link-label-${variant}-${size}-disabled-fontFamily`],
        fontWeight: tokens.styles[`link-label-${variant}-${size}-disabled-fontWeight`],
        lineHeight: tokens.styles[`link-label-${variant}-${size}-disabled-lineHeight`],
        letterSpacing: tokens.styles[`link-label-${variant}-${size}-disabled-letterSpacing`],
        fontSize: tokens.styles[`link-label-${variant}-${size}-disabled-fontSize`],
        color: tokens.styles[`link-label-${variant}-${size}-disabled-color`],
        textDecoration: tokens.styles[`link-label-${variant}-${size}-disabled-textDecoration`],
        opacity: tokens.styles[`link-label-${variant}-${size}-disabled-opacity`],
    };
    return mergeStyles([
        {
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        disabled
            ? {
                ...linkDisabledStyles,
                ...labelDisabledStyles,
                cursor: 'default',
            }
            : {
                ...linkInitialStyles,
                ...labelinitalStyles,
                ':hover': {
                    ...linkHoverStyles,
                    ...labelHoverStyles,
                },
                ':focus': {
                    ...linkFocusedStyles,
                    ...labelFocusedStyles,
                },
                ':active': {
                    ...linkPressedStyles,
                    ...labelPressedStyles,
                },
            },
        {},
    ]);
};
export const link = (tokens) => ({ variant, size, disabled, }) => getResponsiveStyles(combineResponsiveValues(size, variant), ([size, variant]) => linkBase(tokens)({ variant, size, disabled }));
