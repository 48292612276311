import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * `Divider` is used to display a visual separator for components such as `Accordion`, `Footer`, `FeatureList` and others.
 */
export const Divider = forwardRef(({ as: Component = 'div', color, emphasis, orientation, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: [
            {
                borderRadius: tokens.radii.full,
                opacity: 1,
                backgroundColor: color
                    ? tokens.colors[color]
                    : tokens.colors['surface-base-100'],
            },
            getResponsiveStyles(orientation, (orientation) => orientation === 'horizontal'
                ? {
                    height: emphasis === 'low'
                        ? '1px'
                        : tokens.styles['divider-size-default'],
                    width: '100%',
                }
                : {
                    width: emphasis === 'low'
                        ? '1px'
                        : tokens.styles['divider-size-default'],
                    height: '100%',
                }),
        ], ...props, ref: ref }));
});
Divider.displayName = 'Divider';
