import React from 'react';
import { useTheme } from '../../ThemeProvider/ThemeProvider';

export interface DividerProps {
  /**
   * The orientation of the divider
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * The color of the divider
   */
  color?: string;
}

const dividerThickness = '0.0625rem';

export function Divider({
  orientation = 'horizontal',
  color,
  ...props
}: DividerProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof DividerProps>) {
  const { tokens } = useTheme();

  return (
    <div
      css={{
        background: color ?? tokens.colors['secondary-base-200'],
        ...(orientation === 'horizontal'
          ? {
              width: '100%',
              height: dividerThickness,
            }
          : { width: dividerThickness, height: '100%' }),
      }}
      {...props}
    />
  );
}
