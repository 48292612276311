import React from 'react';
import { Fetcher } from 'swr';
import supportedIconNames from './icon-names.json';
import useSWRImmutable from 'swr/immutable';

export interface IconProps {
  /**
   * Icon name. Refers to the name of the svg file in the static assets folder. The names of the files are based on the
   * bosch brand guide icons you can find here https://brandguide.bosch.com/document/2275#/-/uebersicht-icons
   * If the icon is not found, the string value is displayed.
   */
  value: (typeof supportedIconNames)[number];
  /**
   * Icon color
   */
  color?: string;
  /**
   * Icon size
   */
  size?: 'sm' | 'md' | 'lg' | 'xl';

  /**
   * Public path to the static assets. Defaults to env var DSSF_ICONS_PUBLIC_PATH or path '/assets/icons/' if env
   * var does not exist and no value is provided.
   */
  publicPath?: string;
}

const iconSize = {
  xl: '3rem',
  lg: '1.5rem',
  md: '1.25rem',
  sm: '1rem',
};

export function Icon({
  value,
  color,
  publicPath,
  size = 'sm',
  ...props
}: IconProps & Omit<Partial<React.HTMLAttributes<HTMLImageElement>>, keyof IconProps>) {
  if (!supportedIconNames.includes(value)) {
    return <div>{value}</div>;
  }

  const svg = useIconSVG(value, publicPath);
  return (
    <div
      css={{
        display: 'flex',
        svg: {
          height: size && iconSize[size],
          fill: color ?? 'currentColor',
          '& > *': { fill: color ?? 'currentColor' },
        },
      }}
      dangerouslySetInnerHTML={{ __html: svg || '' }}
      {...props}
    />
  );
}

export function useIconSVG(name: (typeof supportedIconNames)[number], publicPath?: string) {
  const effectivePath = `${publicPath ?? process.env.DSSF_ICONS_PUBLIC_PATH ?? '/assets/icons/'}${name}.svg`;
  const { data } = useSWRImmutable(effectivePath, fetcher);
  return data;
}

const fetcher: Fetcher<string, string> = (...args) =>
  fetch(...args).then(res => {
    if (res.ok) {
      return res.text();
    }
    return Promise.reject(res);
  });
