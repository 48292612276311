import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const textMap = {
    xs: 'sm',
    sm: 'md',
    md: 'md',
    lg: 'lg',
};
export const Avatar = forwardRef(({ size, variant = 'default', image, text, ...props }, ref) => {
    const tokens = useTokens();
    const sizeMap = {
        xs: tokens.size['small-lg'],
        sm: tokens.size['small-xl'],
        md: tokens.size['small-2xl'],
        lg: tokens.size['small-3xl'],
    };
    return (_jsx(Stack, { direction: "vertical", justify: "center", align: "center", css: [
            {
                borderRadius: tokens.radii.full,
                backgroundColor: variant === 'default'
                    ? tokens.colors['surface-base-100']
                    : tokens.colors['surface-base-900'],
            },
            getResponsiveStyles(size, (size) => ({
                width: sizeMap[size],
                height: sizeMap[size],
            })),
        ], ...props, ref: ref, children: image
            ? image?.({ size })
            : text?.({
                size: mapResponsiveValue(size, (size) => textMap[size]),
                variant,
            }) }));
});
Avatar.displayName = 'Avatar';
