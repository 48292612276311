import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const productTitleHeadlineBase = (tokens) => ({ size, variant, }) => mergeStyles([
    {
        margin: 0,
        color: tokens.styles[`productTitle-headline-base-${variant}-${size}-color`],
        fontSize: tokens.styles[`productTitle-headline-base-${variant}-${size}-fontSize`],
        fontWeight: tokens.styles[`productTitle-headline-base-${variant}-${size}-fontWeight`],
        fontFamily: tokens.styles[`productTitle-headline-base-${variant}-${size}-fontFamily`],
        lineHeight: tokens.styles[`productTitle-headline-base-${variant}-${size}-lineHeight`],
        letterSpacing: tokens.styles[`productTitle-headline-base-${variant}-${size}-letterSpacing`],
    },
]);
export const productTitleHeadline = (tokens) => ({ size, variant, }) => getResponsiveStyles(size, (size) => productTitleHeadlineBase(tokens)({ size, variant }));
