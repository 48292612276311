import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `EnergyLabelDescription` is used to display the description in `EnergyLabel`
 */
export const EnergyLabelDescription = forwardRef(({ as: Component = 'div', size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: [
            getResponsiveStyles(size, (size) => ({
                lineHeight: tokens.styles[`energylabel-description-${size}-lineHeight`],
                color: tokens.styles[`energylabel-description-${size}-color`],
                fontSize: tokens.styles[`energylabel-description-${size}-fontSize`],
                fontFamily: tokens.styles[`energylabel-description-${size}-fontFamily`],
                fontWeight: tokens.styles[`energylabel-description-${size}-fontWeight`],
                letterSpacing: tokens.styles[`energylabel-description-${size}-letterSpacing`],
                opacity: tokens.styles[`energylabel-description-${size}-opacity`],
            })),
            {
                lineClamp: 1,
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
            },
        ], ...props, ref: ref }));
});
EnergyLabelDescription.displayName = 'EnergyLabelDescription';
