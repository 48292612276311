import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display a price line in an `OrderSummaryContentDetailListItem`
 */
export const OrderSummaryContentDetailListItemPriceLine = forwardRef(({ size, variant, label, price, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`orderSummary-content-detailList-item-priceLine-base-${size}-gap`],
            flex: 1,
        })), justify: "space-between", ...props, ref: ref, children: [label({ variant: variant === 'benefit' ? 'subtle' : variant, size }), price?.({ variant, size })] }));
});
OrderSummaryContentDetailListItemPriceLine.displayName =
    'OrderSummaryContentDetailListItemPriceLine';
