import { mergeStyles } from '../../../component-themes';
export const floatingCard = (tokens) => ({ elevation, variant, edge, }) => {
    return mergeStyles([
        {
            position: 'relative',
            width: 'fit-content',
            height: 'fit-content',
            backgroundColor: tokens.styles[`floatingCard-base-${edge}-${variant}-${elevation}-background`],
            borderTopLeftRadius: tokens.styles[`floatingCard-base-${edge}-${variant}-${elevation}-borderTopLeftRadius`],
            borderBottomLeftRadius: tokens.styles[`floatingCard-base-${edge}-${variant}-${elevation}-borderBottomLeftRadius`],
            borderTopRightRadius: tokens.styles[`floatingCard-base-${edge}-${variant}-${elevation}-borderTopRightRadius`],
            borderBottomRightRadius: tokens.styles[`floatingCard-base-${edge}-${variant}-${elevation}-borderBottomRightRadius`],
            boxShadow: tokens.styles[`floatingCard-base-${edge}-${variant}-${elevation}-boxShadow`]['box-shadow'],
        },
    ]);
};
