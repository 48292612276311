import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { combineResponsiveValues } from '../../component-themes';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the content of the `OrderSummary`
 */
export const OrderSummaryContent = forwardRef(({ size, variant, header, teaserList, detailList, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: "vertical", justify: "center", css: getResponsiveStyles(combineResponsiveValues(size, variant), ([size, variant]) => ({
            width: '100%',
            gap: tokens.styles[`orderSummary-content-base-${variant}-${size}-gap`],
            paddingInlineStart: tokens.styles[`orderSummary-content-base-${variant}-${size}-paddingLeft`],
            paddingInlineEnd: tokens.styles[`orderSummary-content-base-${variant}-${size}-paddingRight`],
            paddingBlockStart: tokens.styles[`orderSummary-content-base-${variant}-${size}-paddingTop`],
        })), ...props, ref: ref, children: [header({ size }), detailList?.({ size }), teaserList?.({ size })] }));
});
OrderSummaryContent.displayName = 'OrderSummaryContent';
