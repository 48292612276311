import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { AnimatePresence } from 'framer-motion';
import { forwardRef } from 'react';
/**
 * Should be used to display a number within `NavigationInfoBarContentCountdownItem`.
 */
export const NavigationInfoBarContentCountdownItemNumber = forwardRef(({ digit1, digit2, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { css: {
            width: tokens.styles['navigation-infobar-content-countdown-item-number-width'],
            position: 'relative',
        }, ...props, ref: ref, children: _jsxs(AnimatePresence, { initial: false, children: [digit1({}), digit2({})] }) }));
});
NavigationInfoBarContentCountdownItemNumber.displayName =
    'NavigationInfoBarContentCountdownItemNumber';
