import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
import { popoverContent } from './styles/popover-content';
const headlineMap = {
    sm: 'small-5',
    lg: 'large-5',
};
/**
 * The `PopoverContent` should be used to display the content within the `Popover`.
 */
export const PopoverContent = forwardRef(({ size, text, closeButton, headline, ...props }, ref) => {
    const tokens = useTokens();
    const styles = popoverContent(tokens)({ size });
    return (_jsxs(Stack, { direction: "vertical", spacing: mapResponsiveValue(size, (size) => size === 'lg' ? 'small-sm' : 'small-xxs'), css: getResponsiveStyles(size, (size) => size === 'sm'
            ? { padding: tokens.space['small-md'] }
            : { padding: tokens.space['small-lg'] }), ...props, ref: ref, children: [_jsxs(Stack, { justify: "space-between", align: "center", children: [headline({
                        size: mapResponsiveValue(size, (size) => headlineMap[size]),
                    }), _jsx("div", { css: styles.closeButton, children: closeButton({}) })] }), text({})] }));
});
PopoverContent.displayName = 'PopoverContent';
