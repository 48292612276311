import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Link } from '../../component-link';
import { forwardRef } from 'react';
/**
 * Used to display a link within the `FooterLinkList`.
 */
export const FooterLinkListItem = forwardRef(({ ...props }, ref) => {
    return _jsx(Link, { size: "md", variant: "neutral", ...props, ref: ref });
});
FooterLinkListItem.displayName = 'FooterLinkListItem';
