import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { editorialTeaserMedia } from './styles/editorial-teaser-media';
/**
 * Should be used to display a (non product) image in the media slot of `EditorialTeaser`.
 */
export const EditorialTeaserMedia = forwardRef(({ orientation, size, action, image, withImagePadding, ...props }, ref) => {
    const tokens = useTokens();
    const styles = editorialTeaserMedia(tokens)({
        size,
        orientation,
        withImagePadding,
    });
    return (_jsxs(Stack, { align: "center", css: styles.wrapper, ...props, ref: ref, children: [image?.({ withImagePadding }), action && _jsx("div", { css: styles.action, children: action?.({}) })] }));
});
EditorialTeaserMedia.displayName = 'EditorialTeaserMedia';
