import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { priceOfferline } from './styles/price-offerline';
/**
 * Used for additional information related to `PriceStrikethrough` if necessary. Use `PriceOfferline`.
 */
export const PriceOfferline = forwardRef(({ as: Component = 'div', size, ...props }, ref) => {
    const tokens = useTokens();
    const styles = priceOfferline(tokens)({ size });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
PriceOfferline.displayName = 'PriceOfferline';
