import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { createIntl, createIntlCache, IntlShape, PrimitiveType, MessageDescriptor } from 'react-intl';
import { Heading } from '../../components/core/Heading/Heading';

// markup HTML tags that can occur in the translation string and are mapped properly
const markupTags: (keyof JSX.IntrinsicElements)[] = [
  'h1',
  'h2',
  'h3',
  'br',
  'b',
  'i',
  'ul',
  'ol',
  'li',
  'sub',
  'sup',
  'span',
];

// const TranslationContextContext = createContext<IntlShape>(createIntl({ locale: 'en', messages: {} }));
const TranslationContextContext = createContext<IntlShape>({} as any);

const markupTagValues = markupTags.map(Tag => [
  Tag,
  (content: any) => {
    switch (Tag) {
      case 'h1':
        return <Heading style="header">{content}</Heading>;
      case 'h2':
        return <Heading style="subheader">{content}</Heading>;
      case 'h3':
        return <Heading style="title">{content}</Heading>;
      default:
        return <Tag>{content}</Tag>;
    }
  },
]);

export type TranslationFunctionValues = { [key: string]: PrimitiveType | ((children: ReactNode) => ReactNode) };
export type TranslationFunction = (
  id: string,
  values?: { [key: string]: PrimitiveType },
  options?: MessageDescriptor,
) => string;
export type TranslationFormattedFunction = (
  id: string,
  values?: TranslationFunctionValues,
  options?: MessageDescriptor,
) => ReactNode;

export interface TranslationHook {
  /**
   * Translate a string with simple string replacements
   */
  t: TranslationFunction;
  /**
   * Translate a string with markup tags and replacement values and functions
   */
  tm: TranslationFormattedFunction;
  /**
   * The current language
   */
  lang: string;
}

export interface TranslationContextProps {
  /**
   * The language of the translations
   */
  language: string;
  /**
   * The default language
   */
  defaultLanguage: string;
  /**
   * The translations
   */
  messages: { [key: string]: string };
  /**
   * The children using translation
   */
  children: ReactNode;
}

/**
 * Hook to access the TranslationContext
 */
export function useTranslation(): TranslationHook {
  const intl = useContext(TranslationContextContext);

  return {
    t: (id, values?, options = {}) => intl.formatMessage({ id, ...options }, values),
    tm: (id, values?, options = {}) =>
      intl.formatMessage({ id, ...options }, Object.fromEntries([...markupTagValues, ...Object.entries(values ?? {})])),
    lang: intl.locale,
  };
}

/**
 * Provider for the `TranslationContext` which sets the language and the messages available for translations
 */
export function TranslationContext({ children, messages, language, defaultLanguage }: TranslationContextProps) {
  const intl = useMemo(() => {
    const cache = createIntlCache();
    return createIntl(
      {
        locale: language,
        defaultLocale: defaultLanguage,
        messages: { ...flattenMessages(require(`../../../lang/${language}.json`)), ...flattenMessages(messages) },
      },
      cache,
    );
  }, [messages]);

  return <TranslationContextContext.Provider value={intl}>{children}</TranslationContextContext.Provider>;
}

export function flattenMessages(nestedMessages: { [key: string]: any }, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: { [key: string]: any }, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}
