import React, { ReactNode } from 'react';
import { useTheme } from '../../ThemeProvider/ThemeProvider';
import { LinearLayout } from '../LinearLayout/LinearLayout';
import { Button } from '../Button/Button';
import { Icon } from '../../Icon/Icon';

export interface ChipProps {
  /**
   * Callback function that is called when the chip is clicked. When not provided, the chip will not be clickable.
   */
  onClick?: () => void;
  /**
   * If a callback function is provided, a close button will be rendered.
   */
  onRemove?: () => void;
  /**
   * Icon to display in the chip.
   */
  icon?: ReactNode;
  /**
   * Icon for the remove button
   */
  removeIcon?: ReactNode;
  /**
   * Display the chip as active
   */
  active?: boolean;
  /**
   * Whether the chip is disabled.
   */
  disabled?: boolean;
  /**
   * Size of the chip
   */
  size?: 'sm' | 'md';
  /**
   * The content of the chip.
   */
  children: ReactNode;
}

export function Chip({
  onClick,
  onRemove,
  icon,
  removeIcon = 'close',
  active = false,
  disabled = false,
  size = 'md',
  children,
  ...props
}: ChipProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof ChipProps>) {
  const { tokens } = useTheme();

  return (
    <LinearLayout
      spacing="tiny"
      orientation="horizontal"
      align="center"
      onClick={disabled ? undefined : onClick}
      css={{
        height: size === 'md' ? '2rem' : '1.5rem',
        background: active
          ? disabled
            ? tokens.colors['primary-base-300']
            : tokens.colors['primary-base-500']
          : disabled
            ? tokens.colors['secondary-base-100']
            : tokens.colors['secondary-base-200'],
        color: active ? 'white' : disabled ? tokens.colors['secondary-base-600'] : 'black',
        borderRadius: '1rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: size === 'md' ? '0 1rem' : '0 0.5rem',
        ...(!disabled && onClick
          ? {
              cursor: 'pointer',
              '&:hover': {
                background: active ? tokens.colors['primary-base-600'] : tokens.colors['secondary-base-300'],
              },
              '&:active': {
                background: active ? tokens.colors['primary-base-700'] : tokens.colors['secondary-base-400'],
              },
            }
          : {}),
      }}
      {...props}
    >
      {icon && (
        <div css={{ marginLeft: '-0.5rem' }}>
          {typeof icon === 'string' ? (
            <Icon value={icon} size={size === 'md' ? 'md' : 'sm'} color={active ? 'white' : 'black'} />
          ) : (
            icon
          )}
        </div>
      )}
      <div
        css={{
          fontSize: size === 'md' ? '1rem' : '0.75rem',
          lineHeight: size === 'md' ? '1.5rem' : '1.125rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
      {onRemove && (
        <Button
          variant="icononly"
          icon={removeIcon}
          iconColor={active ? 'white' : 'black'}
          onClick={event => {
            event.stopPropagation();
            onRemove();
          }}
          disabled={disabled}
          css={{ display: 'grid', placeContent: 'center', marginRight: '-0.5rem' }}
        />
      )}
    </LinearLayout>
  );
}
