import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used for the navigation on top of a page.
 */
export const Navigation = forwardRef(({ as: Component = 'div', size, variant, background, bar, brandBar, infoBar, infoBarPosition, ...props }, ref) => (_jsxs(Component, { ...props, ref: ref, children: [brandBar?.({
            size: mapResponsiveValue(size, (size) => (size === 'sm' ? 'sm' : 'lg')),
        }), infoBarPosition === 'top' ? infoBar?.({ size }) : null, bar({ size, variant, background }), infoBarPosition === 'bottom' ? infoBar?.({ size }) : null] })));
Navigation.displayName = 'Navigation';
