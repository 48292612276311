import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const iconBase = (tokens) => ({ size, color, emphasis, }) => {
    return mergeStyles([
        { flexShrink: 0 },
        size === '2xs'
            ? {
                width: tokens.fontSize['2xs'],
                height: tokens.fontSize['2xs'],
            }
            : {},
        size === 'xs'
            ? {
                width: tokens.fontSize['xs'],
                height: tokens.fontSize['xs'],
            }
            : {},
        size === 'sm'
            ? {
                width: tokens.fontSize['sm'],
                height: tokens.fontSize['sm'],
            }
            : {},
        size === 'md'
            ? {
                width: tokens.fontSize['md'],
                height: tokens.fontSize['md'],
            }
            : {},
        size === 'lg'
            ? {
                width: tokens.fontSize['lg'],
                height: tokens.fontSize['lg'],
            }
            : {},
        color ? { color: tokens.colors[color] } : {},
        emphasis ? { opacity: tokens.emphasis[emphasis] } : {},
    ]);
};
export const icon = (tokens) => ({ size, color, emphasis, }) => getResponsiveStyles(size, (size) => iconBase(tokens)({ size, color, emphasis }));
