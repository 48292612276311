import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { loadingSpinner } from './styles/loading-spinner';
/**
 * The `LoadingSpinner` is an animated icon that can be used to indicate to the user that something is loading.
 */
export const LoadingSpinner = forwardRef(({ as: Component = 'div', size, color, ...props }, ref) => {
    const tokens = useTokens();
    const styles = loadingSpinner(tokens)({ size, color });
    return (_jsx(Component, { css: styles.wrapper, ...props, ref: ref, children: _jsx("svg", { css: styles.spinner, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: _jsx("circle", { css: styles.path, fill: "none", strokeWidth: "2", strokeLinecap: "round", cx: "12", cy: "12", r: "10" }) }) }));
});
LoadingSpinner.displayName = 'LoadingSpinner';
