import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Divider } from '../../component-divider';
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { framerDuration } from '../../component-utilities';
import { AnimatePresence, m as motion } from 'framer-motion';
import { forwardRef } from 'react';
const MotionStack = motion(Stack);
/**
 * Can be used to display primary actions such as menuButton, list and logo.
 */
export const NavigationBarActionsPrimary = forwardRef(({ as: Component = Stack, size, variant, background, list, logo, menuButton, ...props }, ref) => {
    const tokens = useTokens();
    const durations = {
        fast: framerDuration(tokens.animation['duration-fast']),
        quick: framerDuration(tokens.animation['duration-quick']),
        slow: framerDuration(tokens.animation['duration-slow']),
    };
    const transition = { type: 'spring', stiffness: 250, damping: 50, mass: 1 };
    return (_jsxs(Component, { justify: "start", align: "center", spacing: "small-xs", css: {
            overflow: 'hidden',
        }, ...props, ref: ref, children: [_jsx("div", { css: getResponsiveStyles(size, (size) => ({
                    display: size === 'sm' ? 'none' : 'block',
                })), children: menuButton({
                    size,
                    onBackground: background,
                }) }), _jsx(AnimatePresence, { initial: true, children: variant === 'condensed' && (_jsxs(MotionStack, { align: "center", initial: { width: 0, x: -24 }, animate: {
                        width: 'auto',
                        transition: {
                            delay: durations.quick,
                            ...transition,
                        },
                        x: 0,
                    }, exit: {
                        width: 0,
                        transition: {
                            delay: durations.fast,
                            ...transition,
                        },
                        x: -24,
                    }, children: [_jsx(motion.div, { initial: { opacity: 0 }, animate: {
                                opacity: 1,
                                transition: {
                                    delay: 2 * durations.quick,
                                    ...transition,
                                },
                            }, exit: {
                                opacity: 0,
                            }, transition: { duration: durations.fast }, children: logo({
                                size: mapResponsiveValue(size, (size) => size === 'sm' ? 'md' : 'lg'),
                            }) }), _jsx(Divider, { orientation: "vertical", emphasis: "high", css: [
                                getResponsiveStyles(size, (size) => size != 'sm' ? { display: 'flex' } : { display: 'none' }),
                                {
                                    color: tokens.styles['navigation-bar-actions-primary-divider-solid-background'],
                                    alignSelf: 'stretch',
                                    height: 'auto',
                                    marginInline: tokens.space['small-lg'],
                                },
                            ] })] })) }), _jsx("div", { css: getResponsiveStyles(size, (size) => ({
                    display: size === 'sm' ? 'none' : 'block',
                })), children: list?.({ onBackground: background }) })] }));
});
NavigationBarActionsPrimary.displayName = 'NavigationBarActionsPrimary';
