import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { floatingCard } from './styles/floating-card';
/**
 * Can be used for functional components as popovers, tooltips etc.
 */
export const FloatingCard = forwardRef(({ arrow, content, variant, edge = 'edgeless', elevation, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = floatingCard(tokens)({ variant, elevation, edge });
    return (_jsxs(Component, { css: styles, ...props, ref: ref, children: [arrow?.({ variant }), content({})] }));
});
FloatingCard.displayName = 'FloatingCard';
