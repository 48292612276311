import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { priceMain } from './styles/price-main';
/**
 * Can be used to display the current price of a product.
 */
export const PriceMain = forwardRef(({ as: Component = 'div', size, ...props }, ref) => {
    const tokens = useTokens();
    const styles = priceMain(tokens)({ size });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
PriceMain.displayName = 'PriceMain';
