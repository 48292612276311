import { labelBase } from '../../../component-label';
import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
import Color from 'color';
const selectAmountCountBase = (tokens) => ({ size, variant, }) => {
    const labelStyles = labelBase(tokens);
    return mergeStyles([
        size === 'sm'
            ? {
                width: tokens.size['small-xl'],
                ...labelStyles({ size: 'md' }),
            }
            : {},
        size === 'md'
            ? {
                width: tokens.size['small-2xl'],
                ...labelStyles({ size: 'md' }),
            }
            : {},
        size === 'lg'
            ? {
                width: tokens.size['small-3xl'],
                ...labelStyles({ size: 'lg' }),
            }
            : {},
        {
            flex: 1,
            borderStyle: 'none',
            cursor: 'pointer',
            textAlign: 'center',
            // remove controls from input field
            MozAppearance: 'textfield',
            '::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
            },
            '::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
            },
            transitionDuration: tokens.animation['duration-quick'],
            ':hover': {
                filter: `${tokens.filter['brightness-darker-sm']} ${tokens.filter['contrast-lower-sm']}`,
            },
            ':active': {
                filter: `${tokens.filter['brightness-darker-md']} ${tokens.filter['contrast-lower-lg']}`,
            },
        },
        variant === 'prominent'
            ? {
                backgroundColor: tokens.styles['state-action-prominent-background-initial'],
                color: Color(tokens.styles['state-action-prominent-onBackground-initial'])
                    .alpha(tokens.emphasis.full)
                    .string(),
                outline: 'none',
                ':focus': {
                    backgroundColor: tokens.styles['state-action-prominent-background-pressed'],
                    color: Color(tokens.styles['state-action-prominent-onBackground-pressed'])
                        .alpha(tokens.emphasis.high)
                        .string(),
                },
            }
            : {},
        variant === 'default'
            ? {
                backgroundColor: tokens.colors['surface-base-100'],
                color: Color(tokens.colors['surface-onBase-200'])
                    .alpha(tokens.emphasis.high)
                    .string(),
                outline: 'none',
                ':focus': {
                    backgroundColor: tokens.colors['surface-base-200'],
                    color: Color(tokens.colors['surface-onBase-200'])
                        .alpha(tokens.emphasis.high)
                        .string(),
                },
            }
            : {},
        {},
    ]);
};
export const selectAmountCount = (tokens) => ({ size, variant, }) => getResponsiveStyles(size, (size) => selectAmountCountBase(tokens)({ size, variant }));
