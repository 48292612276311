import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Children, forwardRef } from 'react';
/**
 * The `Accordion` displays a list of high-level options that can expand/collapse to reveal more information.
 */
export const Accordion = forwardRef(({ size, items, divider, dividerTop, dividerBottom, as: Component = 'div', ...props }, ref) => {
    return (_jsxs(Component, { ...props, ref: ref, children: [dividerTop && divider({}), Children.map(items.map((item) => item({
                size,
            })), (child, index) => [
                child,
                (index != items.length - 1 || dividerBottom) && divider({}),
            ])] }));
});
Accordion.displayName = 'Accordion';
