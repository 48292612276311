import { mergeStyles } from '../../../component-themes';
export const navigationButtonHelper = (tokens) => ({ active }) => mergeStyles([
    {
        lineClamp: 1,
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
        maxWidth: `15ch`,
        color: tokens.styles[`navigationButton-helper-${active}-initial-color`],
        opacity: tokens.styles[`navigationButton-helper-${active}-initial-opacity`],
        fontSize: tokens.styles[`navigationButton-helper-${active}-initial-fontSize`],
        fontFamily: tokens.styles[`navigationButton-helper-${active}-initial-fontFamily`],
        lineHeight: tokens.styles[`navigationButton-helper-${active}-initial-lineHeight`],
        fontWeight: tokens.styles[`navigationButton-helper-${active}-initial-fontWeight`],
        letterSpacing: tokens.styles[`navigationButton-helper-${active}-initial-letterSpacing`],
    },
    {
        ':hover': {
            color: tokens.styles[`navigationButton-helper-${active}-hover-color`],
            opacity: tokens.styles[`navigationButton-helper-${active}-hover-opacity`],
            fontSize: tokens.styles[`navigationButton-helper-${active}-hover-fontSize`],
            fontFamily: tokens.styles[`navigationButton-helper-${active}-hover-fontFamily`],
            lineHeight: tokens.styles[`navigationButton-helper-${active}-hover-lineHeight`],
            fontWeight: tokens.styles[`navigationButton-helper-${active}-hover-fontWeight`],
            letterSpacing: tokens.styles[`navigationButton-helper-${active}-hover-letterSpacing`],
        },
        ':active': {
            color: tokens.styles[`navigationButton-helper-${active}-pressed-color`],
            opacity: tokens.styles[`navigationButton-helper-${active}-pressed-opacity`],
            fontSize: tokens.styles[`navigationButton-helper-${active}-pressed-fontSize`],
            fontFamily: tokens.styles[`navigationButton-helper-${active}-pressed-fontFamily`],
            lineHeight: tokens.styles[`navigationButton-helper-${active}-pressed-lineHeight`],
            fontWeight: tokens.styles[`navigationButton-helper-${active}-pressed-fontWeight`],
            letterSpacing: tokens.styles[`navigationButton-helper-${active}-pressed-letterSpacing`],
        },
    },
]);
