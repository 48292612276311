import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { productTitleSubline } from './styles/product-title-subline';
/**
 * Used to show the headline within `ProductTitle`.
 */
export const ProductTitleSubline = forwardRef(({ as: Component = 'div', size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = productTitleSubline(tokens)({ size, variant });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
ProductTitleSubline.displayName = 'ProductTitleSubline';
