import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box } from '../../component-box';
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationButton } from './styles/navigation-button';
/**
 * A button that can be used to navigate, e.g. within a nested menu or dropdown.
 */
export const NavigationButton = forwardRef(({ as: Component = 'button', size, variant, active, iconLeft, children, iconRight, helper, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationButton(tokens)({
        size,
        variant,
        active: active ? 'active' : 'inactive',
    });
    return (_jsxs(Component, { css: styles.base, ...props, ref: ref, children: [_jsxs(Stack, { spacing: "small-sm", align: "center", children: [iconLeft && _jsx(Box, { styles: styles.icon, as: iconLeft }), _jsx("span", { css: styles.label, children: children })] }), _jsxs(Stack, { spacing: "small-sm", align: "center", children: [helper?.({ active }), iconRight && _jsx(Box, { styles: styles.icon, as: iconRight })] })] }));
});
NavigationButton.displayName = 'NavigationButton';
