import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the headline in the `OrderSummaryContentHeader`
 */
export const OrderSummaryContentHeaderHeadline = forwardRef(({ size, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            color: tokens.styles[`orderSummary-content-header-headline-${size}-color`],
            fontSize: tokens.styles[`orderSummary-content-header-headline-${size}-fontSize`],
            fontFamily: tokens.styles[`orderSummary-content-header-headline-${size}-fontFamily`],
            lineHeight: tokens.styles[`orderSummary-content-header-headline-${size}-lineHeight`],
            fontWeight: tokens.styles[`orderSummary-content-header-headline-${size}-fontWeight`],
            letterSpacing: tokens.styles[`orderSummary-content-header-headline-${size}-letterSpacing`],
        })), ...props, ref: ref }));
});
OrderSummaryContentHeaderHeadline.displayName =
    'OrderSummaryContentHeaderHeadline';
