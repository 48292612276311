import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { useTokens } from '../../component-themes';
import { getResponsiveStyles } from '../../component-themes';
import { forwardRef } from 'react';
const styleMap = {
    sm: { textAlign: 'center' },
    lg: { textAlign: 'start' },
};
/**
 * Used to display the copyright information within the `Footer`.
 */
export const FooterCopyright = forwardRef(({ size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Text, { size: "xs", variant: "default", css: [
            getResponsiveStyles(size, (size) => styleMap[size]),
            { color: tokens.styles['footer-copyrightText-background'] },
        ], ...props, ref: ref }));
});
FooterCopyright.displayName = 'FooterCopyright';
