import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const sizeMap = {
    sm: 'md',
    lg: 'lg',
};
const colorMap = {
    default: 'surface-onBase-100',
    highlighted: 'surface-onBase-50',
    image: 'light-900',
};
export const FeatureTileDescription = forwardRef(({ variant, size, ...props }, ref) => (_jsx(Text, { variant: "default", size: mapResponsiveValue(size, (size) => sizeMap[size]), color: colorMap[variant], emphasis: "high", style: {
        lineClamp: 2,
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
    }, ...props, ref: ref })));
FeatureTileDescription.displayName = 'FeatureTileDescription';
