import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { Status } from '../../component-status';
import { Text } from '../../component-text';
import { useTokens } from '../../component-themes';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to inform about the availability of an item. It uses the `Status`component to indicate the availability by color and it provides further information.
 */
export const Availability = forwardRef(({ size, alignment, status, variant, children, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { spacing: mapResponsiveValue(size, (size) => size === 'sm' ? 'small-xxs' : 'small-xs'), direction: mapResponsiveValue(alignment, (alignment) => alignment === 'left' ? 'horizontal' : 'horizontal-reverse'), align: "center", ...props, ref: ref, children: [_jsx(Status, { variant: status, size: size }), _jsx(Text, { size: mapResponsiveValue(size, (size) => size === 'sm' ? '2xs' : 'xs'), variant: "default", emphasis: status === 'unavailable' ? 'medium' : 'high', css: {
                    color: tokens.styles[`page-${variant}-onBase`],
                    lineClamp: 1,
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                    overflow: 'hidden',
                }, children: children })] }));
});
Availability.displayName = 'Availability';
