import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display the list of bades in the `Gallery`.
 */
export const GalleryBadgeList = forwardRef(({ badges, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { css: { gap: tokens.styles['gallery-badgeList-gap'] }, ...props, ref: ref, children: badges({}) }));
});
GalleryBadgeList.displayName = 'GalleryBadgeList';
