import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { framerDuration } from '../../component-utilities';
import { AnimatePresence, m as motion } from 'framer-motion';
import { forwardRef, useMemo } from 'react';
import { navigationBarBrandArea } from './styles/navigation-bar-brand-area';
/**
 * Can be used to display tagline, logo, menuButton and other components within the navigation.
 */
export const NavigationBarBrandArea = forwardRef(({ size, variant, background, logo, tagline, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarBrandArea(tokens)({
        variant,
        size,
    });
    const durations = {
        fast: framerDuration(tokens.animation['duration-fast']),
        quick: framerDuration(tokens.animation['duration-quick']),
        slow: framerDuration(tokens.animation['duration-slow']),
    };
    const transition = { type: 'spring', stiffness: 250, damping: 50, mass: 1 };
    const MotionComponent = useMemo(() => motion(Stack), []);
    return (_jsx(AnimatePresence, { initial: false, children: (variant === 'default' || variant === 'progress') && (
        /* @ts-expect-error ignore onAnimationStart */
        _jsx(MotionComponent, { css: { alignSelf: 'stretch' }, ...props, ref: ref, children: _jsx(motion.div, { css: { flex: 1 }, initial: { height: 0, y: -24 }, animate: { height: 'auto', y: 0 }, exit: {
                    height: 0,
                    y: -24,
                }, transition: {
                    duration: durations.quick,
                    ...transition,
                }, children: _jsxs(motion.div, { initial: { opacity: 0 }, animate: {
                        opacity: 1,
                        transition: {
                            duration: durations.quick,
                            delay: durations.quick,
                            ...transition,
                        },
                    }, exit: {
                        opacity: 0,
                        transition: {
                            duration: durations.fast,
                            stiffness: 250,
                            damping: 50,
                            mass: 1,
                        },
                    }, css: styles.brandArea, children: [logo({
                            size: mapResponsiveValue(size, (size) => size === 'sm' ? 'md' : 'lg'),
                        }), tagline?.({
                            size: mapResponsiveValue(size, (size) => size === 'sm' ? 'sm' : 'lg'),
                            onBackground: background,
                        })] }) }) })) }));
});
NavigationBarBrandArea.displayName = 'NavigationBarBrandArea';
