import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const productTitleHeadlineValueClassBase = (tokens) => ({ size, variant, }) => mergeStyles([
    {
        display: 'inline',
        color: tokens.styles[`productTitle-headline-valueClass-base-${variant}-${size}-color`],
        fontSize: tokens.styles[`productTitle-headline-valueClass-base-${variant}-${size}-fontSize`],
        fontWeight: tokens.styles[`productTitle-headline-valueClass-base-${variant}-${size}-fontWeight`],
        fontFamily: tokens.styles[`productTitle-headline-valueClass-base-${variant}-${size}-fontFamily`],
        lineHeight: tokens.styles[`productTitle-headline-valueClass-base-${variant}-${size}-lineHeight`],
        letterSpacing: tokens.styles[`productTitle-headline-valueClass-base-${variant}-${size}-letterSpacing`],
    },
]);
export const productTitleHeadlineValueClass = (tokens) => ({ size, variant, }) => getResponsiveStyles(size, (size) => productTitleHeadlineValueClassBase(tokens)({ size, variant }));
