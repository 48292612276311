import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBarActionsMenuButton } from './styles/navigation-bar-actions-menu-button';
/**
 * Icon to be used for the menu button in the navigation.
 */
export const NavigationBarActionsMenuButton = forwardRef(({ as: Component = 'button', icon: ComponentIcon, alignIcon, size, onBackground, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarActionsMenuButton(tokens)({
        size,
        onBackground,
        alignIcon,
    });
    return (_jsx(Component, { css: styles.menuButton, ...props, ref: ref, children: _jsx(ComponentIcon, { css: styles.icon }) }));
});
NavigationBarActionsMenuButton.displayName = 'NavigationBarActionsMenuButton';
