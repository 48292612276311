import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { forwardRef } from 'react';
/**
 *  `GalleryContentNavigationButtonAction` component is used to display an action within `GalleryContentNavigation`.
 */
export const GalleryContentNavigationButtonAction = forwardRef((props, ref) => {
    return _jsx(ButtonAction, { size: "sm", variant: "default", ...props, ref: ref });
});
GalleryContentNavigationButtonAction.displayName =
    'GalleryContentNavigationButtonAction';
