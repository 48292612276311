import { iconBase } from '../../../component-icon';
import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const ratingIconBase = (tokens) => ({ value, size, }) => {
    const iconStyles = iconBase(tokens);
    return {
        icon: mergeStyles([
            iconStyles({ size }),
            {
                display: 'block',
                color: value > 0
                    ? tokens.styles['rating-iconColor-prominent-default']
                    : tokens.styles['rating-iconColor-prominent-disabled'],
                opacity: value > 0 ? tokens.emphasis.full : tokens.emphasis.low,
                clipPath: value > 0
                    ? `polygon(0% 0%, ${value * 100}% 0%, ${value * 100}% 100%, 0% 100%)`
                    : undefined,
            },
        ]),
        background: mergeStyles([
            iconStyles({ size }),
            {
                display: 'block',
                color: tokens.styles['rating-iconColor-prominent-default'],
                position: 'absolute',
                insetBlockStart: 0,
                insetInlineStart: 0,
                insetInlineEnd: 0,
                insetBlockEnd: 0,
            },
        ]),
    };
};
export const ratingIcon = (tokens) => ({ value, size, }) => ({
    icon: getResponsiveStyles(size, (size) => ratingIconBase(tokens)({ size, value }).icon),
    background: getResponsiveStyles(size, (size) => ratingIconBase(tokens)({ size, value }).background),
});
