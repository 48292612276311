import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Link } from '../../component-link';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to render links within the breadcrumbs. It supports all `Link` props.
 */
export const BreadcrumbLink = forwardRef((props, ref) => {
    const tokens = useTokens();
    return (_jsx(Link, { size: "md", variant: "neutral", css: { opacity: tokens.emphasis.high }, ...props, ref: ref }));
});
BreadcrumbLink.displayName = 'BreadcrumbLink';
