import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { useActiveState, useHoveredState } from '../../component-hooks';
import { ChevronDown, ChevronUp } from '../../component-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the expand button in the `floating` variant of the `OrderSummary`.
 */
export const OrderSummaryExpandButton = forwardRef(({ as: Component = 'button', expanded, sticky, ...props }, ref) => {
    const { active, ...activeProps } = useActiveState(props);
    const { hovered, ...hoveredProps } = useHoveredState(props);
    const tokens = useTokens();
    const stickyState = sticky ? 'sticky' : 'initial';
    return (_jsx(Component, { css: {
            width: '100%',
            border: 'none',
            paddingInline: 'unset',
            paddingBlockStart: tokens.styles[`orderSummary-expandButton-${stickyState}-paddingTop`],
            paddingBlockEnd: tokens.styles[`orderSummary-expandButton-${stickyState}-paddingBottom`],
            backgroundColor: tokens.styles[`orderSummary-expandButton-${stickyState}-background`],
            borderWidth: 0,
            borderBlockEndWidth: tokens.styles[`orderSummary-expandButton-${stickyState}-borderBottomWidth`],
            borderStyle: tokens.styles[`orderSummary-expandButton-${stickyState}-borderStyle`],
            borderColor: tokens.styles[`orderSummary-expandButton-${stickyState}-borderColor`],
            boxShadow: tokens.styles[`orderSummary-expandButton-${stickyState}-boxShadow`]['box-shadow'],
            transitionProperty: 'all',
            transitionDuration: tokens.animation['duration-slow'],
            transitionTimingFunction: tokens.animation['timing-soft'],
        }, ...props, ...activeProps, ...hoveredProps, ref: ref, children: _jsx(ButtonAction, { as: "div", variant: "subtle", size: "sm", hovered: hovered, active: active, icon: expanded ? ChevronDown : ChevronUp }) }));
});
OrderSummaryExpandButton.displayName = 'OrderSummaryExpandButton';
