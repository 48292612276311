import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniServicesDivider = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            borderRadius: tokens.styles['productTeaserMini-services-divider-borderRadius'],
            backgroundColor: tokens.styles['productTeaserMini-services-divider-background'],
            height: tokens.styles['productTeaserMini-services-divider-borderWidth'],
        }, ...props, ref: ref }));
});
ProductTeaserMiniServicesDivider.displayName =
    'ProductTeaserMiniServicesDivider';
