import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { Plus } from '../../component-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * `SelectAmountIncrement` subcomponent is used to increment the amount in `SelectAmount`. The `SelectAmountIncrement` subcomponent is using the `ButtonAction` component.
 */
export const SelectAmountIncrement = forwardRef(({ max, value, disabled, size, variant, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(ButtonAction, { size: size, icon: Plus, variant: variant === 'default' ? 'subtle' : 'prominent', disabled: disabled || (max != null && value != null && value >= max), css: {
            borderTopLeftRadius: tokens.radii.none,
            borderBottomLeftRadius: tokens.radii.none,
        }, ...props, ref: ref }));
});
SelectAmountIncrement.displayName = 'SelectAmountIncrement';
