import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display the label for `ProgressStepsStepListStep`.
 */
export const ProgressStepsStepListStepLabel = forwardRef(({ as: Component = 'div', active, state, variant, ...props }, ref) => {
    const tokens = useTokens();
    const activeState = active ? 'active' : 'inactive';
    const interactionState = state === 'initial' ? 'hover' : 'pressed';
    return (_jsx(Component, { css: {
            color: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-color`],
            opacity: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-opacity`],
            fontSize: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-fontSize`],
            fontFamily: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-fontFamily`],
            fontWeight: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-fontWeight`],
            lineHeight: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-lineHeight`],
            letterSpacing: tokens.styles[`progressSteps-stepList-step-label-${variant}-${activeState}-${state}-letterSpacing`],
            transition: `all ${tokens.styles[`interaction-${interactionState}-duration`]} ${tokens.styles[`interaction-${interactionState}-timing`]}`,
        }, ...props, ref: ref }));
});
ProgressStepsStepListStepLabel.displayName = 'ProgressStepsStepListStepLabel';
