import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const HintContentDescription = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            color: tokens.styles['hint-content-description-color'],
            fontSize: tokens.styles['hint-content-description-fontSize'],
            fontWeight: tokens.styles['hint-content-description-fontWeight'],
            fontFamily: tokens.styles['hint-content-description-fontFamily'],
            lineHeight: tokens.styles['hint-content-description-lineHeight'],
            letterSpacing: tokens.styles['hint-content-description-letterSpacing'],
        }, ...props, ref: ref }));
});
HintContentDescription.displayName = 'HintContentDescription';
