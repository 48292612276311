import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { Info } from '../../component-icons';
import { forwardRef } from 'react';
/**
 * Icon to be used in the `FormControlLabel`.
 */
export const FormControlLabelIcon = forwardRef(({ disabled, ...props }, ref) => {
    return (_jsx(Icon, { size: "sm", color: "surface-onBase-50", emphasis: disabled ? 'low' : 'high', as: Info, ...props, ref: ref }));
});
FormControlLabelIcon.displayName = 'FormControlLabelIcon';
