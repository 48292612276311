import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { LoadingSpinner } from '../../component-loading-spinner';
import { useCurrentResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
import { buttonAction } from './styles/button-action';
export const ButtonAction = forwardRef(({ as: Component = 'button', icon, isLoading = false, interactive = true, variant, size, hovered, active, ...props }, ref) => {
    const tokens = useTokens();
    const styles = buttonAction(tokens)({
        variant,
        size,
        isLoading,
        interactive,
        hovered,
        active,
    });
    const buttonVariant = useCurrentResponsiveValue(variant) || 'default';
    const buttonSize = useCurrentResponsiveValue(size) || 'sm';
    return (_jsx(Component, { css: styles, ...props, ref: ref, children: isLoading ? (_jsx(LoadingSpinner, { size: size, color: tokens.styles[`buttonAction-icon-${buttonVariant}-${buttonSize}-disabled-background`] })) : (_jsx(Icon, { size: size, as: icon, css: { color: 'inherit' } })) }));
});
ButtonAction.displayName = 'ButtonAction';
