import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { m as motion } from 'framer-motion';
import { forwardRef, useMemo } from 'react';
/**
 * Wrapper with which you can use the `motion` component polymorphically
 */
export const Motion = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const MotionComponent = useMemo(() => motion(Component), [Component]);
    /* @ts-expect-error ignore incompatible style prop */
    return _jsx(MotionComponent, { ...props, ref: ref });
});
Motion.displayName = 'Motion';
