import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `OverlayCard` can be used to display the content for `Modal` and `Drawer` components.
 */
export const OverlayCard = forwardRef(({ size, header, content, footer, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { css: [
            { height: '100%' },
            getResponsiveStyles(size, (size) => ({
                backgroundColor: tokens.styles[`overlayCard-base-${size}-background`],
            })),
        ], direction: "vertical", justify: "space-between", ...props, ref: ref, children: [header ? (_jsxs(Stack, { direction: "vertical", css: { flex: 1 }, children: [header({ size }), content({ size })] })) : (_jsx(_Fragment, { children: content({ size }) })), footer?.({ size })] }));
});
OverlayCard.displayName = 'OverlayCard';
