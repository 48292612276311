import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { productTitleHeadline } from './styles/product-title-headline';
/**
 * Used to show the headline within `ProductTitle`.
 */
export const ProductTitleHeadline = forwardRef(({ as: Component = 'h3', size, variant, valueClass, children, ...props }, ref) => {
    const tokens = useTokens();
    const styles = productTitleHeadline(tokens)({ size, variant });
    return (_jsxs(Component, { css: styles, ...props, ref: ref, children: [valueClass?.({ size, variant }), " ", children] }));
});
ProductTitleHeadline.displayName = 'ProductTitleHeadline';
