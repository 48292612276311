import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef, useState } from 'react';
import { InputContext } from './input-context';
/**
 * The `Input` can be used to display an input field.
 */
export const Input = forwardRef(({ size, variant, indicatorLeading, indicatorTrailing, content, onPointerEnter, onPointerLeave, disabled = false, invalid = false, ...props }, ref) => {
    const tokens = useTokens();
    const [focused, setFocused] = useState(false);
    const [filled, setFilled] = useState(false);
    const [hovered, setHovered] = useState(false);
    const errorState = invalid ? 'error' : 'idle';
    const state = disabled
        ? filled
            ? 'filled-disabled'
            : 'initial-disabled'
        : focused
            ? 'focused'
            : hovered
                ? 'hover'
                : filled
                    ? 'filled'
                    : 'initial';
    return (_jsx(InputContext.Provider, { value: {
            focused,
            filled,
            hovered,
            setFocused,
            setFilled,
            setHovered,
        }, children: _jsxs(Stack, { as: "label", align: "center", css: getResponsiveStyles(size, (size) => ({
                cursor: 'text',
                position: 'relative',
                paddingInlineEnd: tokens.styles[`input-base-${size}-${errorState}-${state}-paddingRight`],
                paddingInlineStart: tokens.styles[`input-base-${size}-${errorState}-${state}-paddingLeft`],
                height: tokens.styles[`input-base-${size}-${errorState}-${state}-height`],
                background: tokens.styles[`input-base-${size}-${errorState}-${state}-background`],
                borderStyle: tokens.styles[`input-base-${size}-${errorState}-${state}-borderStyle`],
                borderColor: tokens.styles[`input-base-${size}-${errorState}-${state}-borderColor`],
                borderBlockEndWidth: tokens.styles[`input-base-${size}-${errorState}-${state}-borderBottomWidth`],
                borderInlineStartWidth: tokens.styles[`input-base-${size}-${errorState}-${state}-borderLeftWidth`],
                borderInlineEndWidth: tokens.styles[`input-base-${size}-${errorState}-${state}-borderRightWidth`],
                borderBlockStartWidth: tokens.styles[`input-base-${size}-${errorState}-${state}-borderTopWidth`],
                borderEndStartRadius: tokens.styles[`input-base-${size}-${errorState}-${state}-borderBottomLeftRadius`],
                borderEndEndRadius: tokens.styles[`input-base-${size}-${errorState}-${state}-borderBottomRightRadius`],
                borderStartStartRadius: tokens.styles[`input-base-${size}-${errorState}-${state}-borderTopLeftRadius`],
                borderStartEndRadius: tokens.styles[`input-base-${size}-${errorState}-${state}-borderTopRightRadius`],
                transition: `all ${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
                ':active': {
                    transition: `all ${tokens.styles['interaction-pressed-duration']} ${tokens.styles['interaction-pressed-timing']}`,
                },
            })), onPointerEnter: (event) => {
                setHovered(true);
                if (onPointerEnter) {
                    onPointerEnter(event);
                }
            }, onPointerLeave: (event) => {
                setHovered(false);
                if (onPointerLeave) {
                    onPointerLeave(event);
                }
            }, ...props, ref: ref, children: [indicatorLeading?.({
                    disabled,
                    invalid,
                    focused,
                    filled,
                    hovered,
                }), content({ variant, disabled, focused, filled, hovered }), indicatorTrailing?.({
                    disabled,
                    invalid,
                    focused,
                    filled,
                    hovered,
                })] }) }));
});
Input.displayName = 'Input';
