import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { m as motion } from 'framer-motion';
import { forwardRef } from 'react';
import { galleryThumbListThumb } from './styles/gallery-thumb-list-thumb';
/**
 * The `GalleryThumbListThumb` is a single thumbnail to be rendered in the `GalleryThumbList`.
 */
export const GalleryThumbListThumb = forwardRef(({ size, variant, animationGroupId, buttonAction, image, ...props }, ref) => {
    const tokens = useTokens();
    const styles = galleryThumbListThumb(tokens)({ size, variant });
    return (_jsxs(Stack, { justify: "center", align: "center", css: styles.thumb, ...props, ref: ref, children: [image({}), buttonAction && (_jsx("div", { css: { position: 'absolute' }, children: buttonAction?.({}) })), variant === 'active' && (_jsx(motion.div, { css: styles.highlight, layoutId: animationGroupId }))] }));
});
GalleryThumbListThumb.displayName = 'GalleryThumbListThumb';
