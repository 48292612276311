import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const outer = () => ({ direction = 'bottom', }) => {
    const sides = 16;
    return mergeStyles([
        {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: `${sides}px`,
            height: `${sides}px`,
            clip: `rect(0px, ${sides / 2}px, ${sides}px, 0px)`,
            transform: {
                left: 'rotate(0deg)',
                top: 'rotate(90deg)',
                right: 'rotate(180deg)',
                bottom: 'rotate(270deg)',
            }[direction],
        },
    ]);
};
export const inner = (tokens) => ({ variant }) => {
    const sides = 16;
    return mergeStyles([
        {
            transform: 'rotate(45deg)',
            backgroundColor: tokens.styles[`floatingCard-tail-${variant}-background`],
            width: `${(Math.sqrt(2) * sides) / 2}px`,
            height: `${(Math.sqrt(2) * sides) / 2}px`,
            boxSizing: 'border-box',
            borderRadius: `0 0 0 ${tokens.radii.xs}`,
            transformOrigin: 'center',
        },
    ]);
};
export const floatingCardArrow = (tokens) => ({ direction, variant, }) => ({
    outer: getResponsiveStyles(direction, (direction) => outer()({ direction })),
    inner: inner(tokens)({ variant }),
});
