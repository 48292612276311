import { iconBase } from '../../../component-icon';
import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const navigationBarActionsMenuButton = (tokens) => ({ alignIcon, size, onBackground, }) => {
    const iconStyles = iconBase(tokens);
    return {
        menuButton: getResponsiveStyles(size, (size) => ({
            border: 'none',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: alignIcon === 'left' ? 'flex-start' : 'flex-end',
            cursor: 'pointer',
            transition: `all ${tokens.animation['duration-quick']} ${tokens.animation['timing-soft']}`,
            background: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-initial-background`],
            borderRadius: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-initial-borderRadius`],
            height: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-initial-height`],
            width: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-initial-width`],
            color: tokens.styles[`navigation-bar-menuButton-${onBackground}-initial-background`],
            opacity: tokens.styles[`navigation-bar-menuButton-${onBackground}-initial-opacity`],
            ':hover': {
                color: tokens.styles[`navigation-bar-menuButton-${onBackground}-hover-background`],
                opacity: tokens.styles[`navigation-bar-menuButton-${onBackground}-hover-opacity`],
                borderRadius: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-hover-borderRadius`],
                background: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-hover-background`],
                height: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-hover-height`],
                width: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-hover-width`],
            },
            ':active': {
                color: tokens.styles[`navigation-bar-menuButton-${onBackground}-pressed-background`],
                opacity: tokens.styles[`navigation-bar-menuButton-${onBackground}-pressed-opacity`],
                borderRadius: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-pressed-borderRadius`],
                background: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-pressed-background`],
                height: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-pressed-height`],
                width: tokens.styles[`navigation-bar-menuButton-base-${onBackground}-${size}-pressed-width`],
            },
        })),
        icon: getResponsiveStyles(size, (size) => mergeStyles([iconStyles({ size })])),
    };
};
