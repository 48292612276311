import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display an action as an indicator in the `Input` component.
 * It is an icon that can be clicked to perform an action. For non clickable icons use `InputIndicatorIcon`.
 */
export const InputIndicatorAction = forwardRef(({ disabled, 
// we need to remove the props from the IndicatorProps type, because they are not used by the component
// otherwise they are spread and lead to a console warning
// eslint-disable-next-line @typescript-eslint/no-unused-vars
focused, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
filled, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
hovered, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
invalid, onClick, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Icon, { size: "sm", onClick: (event) => {
            event.preventDefault();
            if (onClick) {
                onClick(event);
            }
        }, css: [
            {
                height: '100%',
                cursor: 'pointer',
                boxSizing: 'content-box',
                transition: `all ${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
                ':focus': { outline: 'none', border: 'none' },
                '-webkit-tap-highlight-color': 'transparent',
            },
            disabled
                ? {
                    paddingInlineStart: tokens.styles['input-indicator-action-base-disabled-paddingLeft'],
                    paddingInlineEnd: tokens.styles['input-indicator-action-base-disabled-paddingRight'],
                    color: tokens.styles['input-indicator-action-icon-disabled-background'],
                    opacity: tokens.styles['input-indicator-action-icon-disabled-opacity'],
                }
                : {
                    paddingInlineStart: tokens.styles['input-indicator-action-base-initial-paddingLeft'],
                    paddingInlineEnd: tokens.styles['input-indicator-action-base-initial-paddingRight'],
                    color: tokens.styles['input-indicator-action-icon-initial-background'],
                    opacity: tokens.styles['input-indicator-action-icon-initial-opacity'],
                    ':hover': {
                        paddingInlineStart: tokens.styles['input-indicator-action-base-hover-paddingLeft'],
                        paddingInlineEnd: tokens.styles['input-indicator-action-base-hover-paddingRight'],
                        color: tokens.styles['input-indicator-action-icon-hover-background'],
                        opacity: tokens.styles['input-indicator-action-icon-hover-opacity'],
                    },
                    ':focus': {
                        paddingInlineStart: tokens.styles['input-indicator-action-base-focused-paddingLeft'],
                        paddingInlineEnd: tokens.styles['input-indicator-action-base-focused-paddingRight'],
                        color: tokens.styles['input-indicator-action-icon-focused-background'],
                        opacity: tokens.styles['input-indicator-action-icon-focused-opacity'],
                    },
                    ':pressed': {
                        transition: `all ${tokens.styles['interaction-pressed-duration']} ${tokens.styles['interaction-pressed-timing']}`,
                        paddingInlineStart: tokens.styles['input-indicator-action-base-pressed-paddingLeft'],
                        paddingInlineEnd: tokens.styles['input-indicator-action-base-pressed-paddingRight'],
                        color: tokens.styles['input-indicator-action-icon-pressed-background'],
                        opacity: tokens.styles['input-indicator-action-icon-pressed-opacity'],
                    },
                },
        ], ...props, ref: ref }));
});
InputIndicatorAction.displayName = 'InputIndicatorAction';
