import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const navigationBarBase = (tokens) => ({ size, variant, background, }) => mergeStyles([
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: tokens.styles[`navigation-bar-base-${background}-${size}-${variant}-background`],
        paddingBlockStart: tokens.styles[`navigation-bar-base-${background}-${size}-${variant}-paddingTop`],
        paddingBlockEnd: tokens.styles[`navigation-bar-base-${background}-${size}-${variant}-paddingBottom`],
        paddingInlineStart: tokens.styles[`navigation-bar-base-${background}-${size}-${variant}-paddingLeft`],
        paddingInlineEnd: tokens.styles[`navigation-bar-base-${background}-${size}-${variant}-paddingRight`],
        gap: variant === 'default' || variant === 'progress'
            ? tokens.styles[`navigation-bar-base-transparent-${size}-${variant}-gap`]
            : 'unset',
        borderColor: tokens.styles[`navigation-bar-base-${background}-lg-${variant}-borderColor`],
        borderBottomStyle: tokens.styles[`navigation-bar-base-${background}-lg-${variant}-borderStyle`],
        borderBottomWidth: tokens.styles[`navigation-bar-base-${background}-lg-${variant}-borderBottomWidth`],
    },
]);
export const navigationBar = (tokens) => ({ size, variant, background, }) => getResponsiveStyles(size, (size) => navigationBarBase(tokens)({ size, variant, background }));
