import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { LoadingSpinner } from '../../component-loading-spinner';
import { useCurrentResponsiveValue } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { button } from './styles/button';
export const Button = forwardRef(({ as: Component = 'button', icon: ComponentIcon, children, alignIcon = 'left', isLoading = false, variant, size, hovered, active, ...props }, ref) => {
    const tokens = useTokens();
    const styles = button(tokens)({
        isLoading,
        variant,
        size,
        hovered,
        active,
    });
    const buttonSize = useCurrentResponsiveValue(size) || 'sm';
    return (_jsxs(Component, { css: styles.button, ...props, ref: ref, children: [ComponentIcon && alignIcon === 'left' ? (isLoading ? (_jsx(LoadingSpinner, { size: size, color: tokens.styles[`button-icon-${variant}-${buttonSize}-disabled-background`] })) : (_jsx(ComponentIcon, { css: styles.icon }))) : null, _jsx("div", { css: {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }, children: children }), ComponentIcon && alignIcon === 'right' ? (isLoading ? (_jsx(LoadingSpinner, { size: size, color: tokens.styles[`button-icon-${variant}-lg-disabled-background`] })) : (_jsx(ComponentIcon, { css: styles.icon }))) : null] }));
});
Button.displayName = 'Button';
