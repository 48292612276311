import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to visually indicate availability status by color.
 */
export const Status = forwardRef(({ as: Component = 'div', size, variant, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { ...props, css: [
            getResponsiveStyles(size, (size) => size === 'lg'
                ? {
                    width: tokens.size['small-sm'],
                    height: tokens.size['small-sm'],
                }
                : {
                    width: tokens.size['small-xs'],
                    height: tokens.size['small-xs'],
                }),
            {
                borderRadius: tokens.radii.full,
                flexShrink: 0,
            },
            variant === 'limited' && {
                background: tokens.colors['warning-base-900'],
            },
            variant === 'available' && {
                background: tokens.colors['validated-base-900'],
            },
            variant === 'unavailable' && {
                background: tokens.colors['surface-onBase-50'],
                opacity: tokens.emphasis.medium,
            },
        ], ref: ref }));
});
Status.displayName = 'Status';
