import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { ChevronLeft, ChevronRight } from '../../component-icons';
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `GalleryContentNavigation` component is used to display the navigation of the gallery.
 */
export const GalleryContentNavigation = forwardRef(({ buttonLeft, buttonRight, pagination, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { css: { gap: tokens.styles[`gallery-navigation-gap`] }, ...props, ref: ref, children: [buttonLeft?.({ icon: ChevronLeft }), pagination({}), buttonRight?.({ icon: ChevronRight })] }));
});
GalleryContentNavigation.displayName = 'GalleryContentNavigation';
