import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { Children, forwardRef } from 'react';
import { GridDivider } from './grid-divider';
export const Grid = forwardRef(({ as: Component = 'div', size, variant, columns, children, ...props }, ref) => {
    const tokens = useTokens();
    const length = Children.count(children);
    return (_jsx(Component, { ...props, ref: ref, css: [
            {
                display: 'grid',
                gridAutoRows: variant === 'separated'
                    ? `auto ${tokens.styles['grid-divider-minHeight']}`
                    : 'auto',
            },
            getResponsiveStyles(size, (size) => ({
                rowGap: tokens.styles[`grid-row-base-${size}-gap`],
                columnGap: tokens.styles[`grid-base-${size}-gap`],
            })),
            getResponsiveStyles(columns, (columns) => ({
                gridTemplateColumns: variant === 'separated'
                    ? Array(columns)
                        .fill('1fr')
                        .join(` ${tokens.styles['grid-divider-minWidth']} `)
                    : `repeat(${columns}, 1fr)`,
            })),
        ], children: Children.map(children, (child, index) => (_jsxs(_Fragment, { children: [child, index !== length - 1 && variant === 'separated' ? (_jsx(GridDivider, { css: getResponsiveStyles(columns, (columns) => ({
                        gridColumn: (index + 1) % columns === 0
                            ? `span ${columns * 2 - 1}`
                            : 'span 1',
                    })) })) : null] }))) }));
});
Grid.displayName = 'Grid';
