import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `GalleryThumbList` is a list of thumbnails to be rendered below the gallery.
 */
export const GalleryThumbList = forwardRef(({ size, animationGroupId = 'gallery-thumb-list', thumbs, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { justify: "center", direction: "horizontal", css: getResponsiveStyles(size, (size) => ({
            flexShrink: 0,
            gap: tokens.styles[`gallery-thumbList-base-${size}-gap`],
            paddingInlineEnd: tokens.styles[`gallery-thumbList-base-${size}-paddingRight`],
            paddingInlineStart: tokens.styles[`gallery-thumbList-base-${size}-paddingLeft`],
        })), ...props, ref: ref, children: thumbs({
            size,
            animationGroupId,
        }) }));
});
GalleryThumbList.displayName = 'GalleryThumbList';
