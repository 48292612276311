import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Label } from '../../component-label';
import { forwardRef } from 'react';
/**
 * Text to be used in `FormControlLabel`.
 */
export const FormControlLabelText = forwardRef(({ disabled, ...props }, ref) => {
    return (_jsx(Label, { color: "surface-onBase-50", size: "md", emphasis: disabled ? 'low' : 'high', ...props, ref: ref }));
});
FormControlLabelText.displayName = 'FormControlLabelText';
