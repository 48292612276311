import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { featureListItemDescription } from './styles/feature-list-item-description';
/**
 * The `FeatureListItemDescription` should be used to display a description inside of the `FeatureListItem`.
 */
export const FeatureListItemDescription = forwardRef(({ as: Component = 'div', size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = featureListItemDescription(tokens)({
        size,
        variant,
    });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
FeatureListItemDescription.displayName = 'FeatureListItemDescription';
