import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Button } from '../../component-button';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `OverlayCardCloseButton` component is used to display buttons in the `OverlayCardFooter`.
 */
export const OverlayCardFooterButton = forwardRef(({ fullWidth, size, ...rest }, ref) => (_jsx(Button, { css: {
        width: fullWidth ? '100%' : 'initial',
    }, size: mapResponsiveValue(size, (size) => (size === 'sm' ? 'md' : 'lg')), ...rest, ref: ref })));
OverlayCardFooterButton.displayName = 'OverlayCardFooterButton';
