import React, { ReactNode } from 'react';
import { CSSObject } from '@emotion/react';

export type TextStyles = 'paragraph' | 'note';

export interface TextProps {
  /**
   * Text style<br />
   * `paragraph`: Text M Regular (Bosch Styleguide)<br />
   * `note`: Text S Regular (Bosch Styleguide)
   */
  style?: TextStyles;
  /**
   * Text content
   */
  children?: ReactNode;
}

/**
 * The `Text` component displays a text with a specific style.
 */
export function Text({
  style,
  children,
  ...props
}: TextProps & Omit<Partial<React.HTMLAttributes<HTMLParagraphElement>>, keyof TextProps>) {
  return (
    <p
      css={
        {
          fontFamily: 'Bosch Sans, sans-serif',
          fontWeight: 'normal',
          whiteSpace: 'pre-line',
          margin: 0,
          padding: 0,
          fontSize: style === 'note' ? '0.75rem' : '1rem',
          lineHeight: style === 'note' ? '1.125rem' : '1.5rem',
          li: { listStylePosition: 'inside' },
        } as CSSObject
      }
      {...props}
    >
      {children}
    </p>
  );
}
