import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import Color from 'color';
import { Children, forwardRef } from 'react';
import { overlayCardFooter } from './styles/overlayCardFooter';
/**
 * The `OverlayCardFooter` should be used to display the footer within the `OverlayCard`.
 */
export const OverlayCardFooter = forwardRef(({ size, sticky: isSticky, buttons, alignment, ...props }, ref) => {
    const tokens = useTokens();
    const styles = overlayCardFooter(tokens)({ size, isSticky });
    return (_jsxs(Stack, { css: styles, justify: alignment === 'right'
            ? 'flex-end'
            : alignment === 'left'
                ? 'flex-start'
                : 'stretch', direction: "horizontal", align: "center", ...props, ref: ref, children: [_jsx("div", { css: {
                    position: 'absolute',
                    pointerEvents: 'none',
                    height: tokens.size['small-2xl'],
                    insetBlockStart: `-${tokens.size['small-2xl']}`,
                    insetInlineStart: 0,
                    background: isSticky
                        ? `linear-gradient(0deg, ${Color(tokens.colors['dark-900']).alpha(0.03)} 0%, transparent 100%)`
                        : 'none',
                    opacity: isSticky ? 1 : 0,
                    transition: `opacity ${tokens.animation['duration-slow']} ease-in-out`,
                    width: '100%',
                } }), Children.map(buttons?.map((button) => button?.({ size, fullWidth: alignment === 'stretch' })), (child) => child)] }));
});
OverlayCardFooter.displayName = 'OverlayCardFooter';
