import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const priceStrikethroughBase = (tokens) => ({ size }) => {
    return mergeStyles([
        {
            color: tokens.colors['surface-onBase-50'],
            opacity: tokens.emphasis.medium,
            textDecoration: 'line-through',
            fontFamily: tokens.styles[`price-strikePrice-${size}-fontFamily`],
            fontSize: tokens.styles[`price-strikePrice-${size}-fontSize`],
            fontWeight: tokens.styles[`price-strikePrice-${size}-fontWeight`],
            letterSpacing: tokens.styles[`price-strikePrice-${size}-letterSpacing`],
            lineHeight: tokens.styles[`price-strikePrice-${size}-lineHeight`],
        },
    ]);
};
export const priceStrikethrough = (tokens) => ({ size, }) => getResponsiveStyles(size, (size) => priceStrikethroughBase(tokens)({ size }));
