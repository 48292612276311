import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const priceOfferlineBase = (tokens) => ({ size }) => {
    const sizeMap = {
        '2xs': 'xs',
        xs: 'sm',
        sm: 'sm',
        md: 'sm',
        lg: 'sm',
    };
    return mergeStyles([
        {
            color: tokens.styles['price-offerline-color'],
            opacity: tokens.emphasis.full,
            letterSpacing: tokens.styles[`price-offerline-${sizeMap[size]}-letterSpacing`],
            fontSize: tokens.styles[`price-offerline-${sizeMap[size]}-fontSize`],
            lineHeight: tokens.styles[`price-offerline-${sizeMap[size]}-lineHeight`],
            fontFamily: tokens.styles[`price-offerline-${sizeMap[size]}-fontFamily`],
            fontWeight: tokens.styles[`price-offerline-${sizeMap[size]}-fontWeight`],
        },
    ]);
};
export const priceOfferline = (tokens) => ({ size, }) => getResponsiveStyles(size, (size) => priceOfferlineBase(tokens)({ size }));
