import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Badge } from '../../component-badge';
import { forwardRef } from 'react';
/**
 * Should be used within `EditorialTeaserContentFooterTags`.
 */
export const EditorialTeaserContentFooterTag = forwardRef(({ variant, ...props }, ref) => (_jsx(Badge, { variant: variant === 'default' ? 'subtle' : 'secondary', size: "md", css: {
        maxWidth: '100%',
    }, ...props, ref: ref })));
EditorialTeaserContentFooterTag.displayName = 'EditorialTeaserContentFooterTag';
