import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { selectAmountCount } from './styles/select-amount-count';
/**
 * `SelectAmountCount` subcomponent is used to display the amount of the product the user wants to add into his shopping cart.
 */
export const SelectAmountCount = forwardRef(({ size, variant, as: Component = 'input', ...props }, ref) => {
    const tokens = useTokens();
    const styles = selectAmountCount(tokens)({ size, variant });
    return _jsx(Component, { type: "number", css: styles, ...props, ref: ref });
});
SelectAmountCount.displayName = 'SelectAmountCount';
