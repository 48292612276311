import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `OverlayCardHeader` should be used to display the header within the `OverlayCard`.
 */
export const OverlayCardHeader = forwardRef(({ size, closeButton, content, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { justify: content ? 'space-between' : 'end', spacing: mapResponsiveValue(size, (size) => size === 'lg' ? 'small-lg' : 'small-md'), css: [
            getResponsiveStyles(size, (size) => ({
                gap: tokens.styles[`overlayCard-header-base-${size}-gap`],
                paddingBlockStart: tokens.styles[`overlayCard-header-base-${size}-paddingTop`],
                paddingInlineStart: tokens.styles[`overlayCard-header-base-${size}-paddingLeft`],
                paddingInlineEnd: tokens.styles[`overlayCard-header-base-${size}-paddingRight`],
            })),
        ], ...props, ref: ref, children: [_jsx("div", { css: { width: '100%' }, children: content?.({}) }), closeButton?.({})] }));
});
OverlayCardHeader.displayName = 'OverlayCardHeader';
