import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
/**
 * `GalleryContentSliderSlideImage` component is used to display an images within `GalleryContentSliderSlide`.
 */
export const GalleryContentSliderSlideImage = forwardRef(({ as: Component = 'img', ...props }, ref) => {
    return (_jsx(Component, { css: {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            minHeight: 0,
        }, ...props, ref: ref }));
});
GalleryContentSliderSlideImage.displayName = 'GalleryContentSliderSlideImage';
