import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const closeButton = (tokens) => () => {
    return mergeStyles([
        {
            marginInlineEnd: `-${tokens.space['small-xs']}`,
        },
    ]);
};
export const popoverContentBase = (tokens) => ({ size }) => {
    return mergeStyles([
        {
            background: tokens.styles['popover-backgroundColor'],
            borderRadius: tokens.radii.xs,
            filter: tokens.shadow['boxShadow-bottom-lg']['drop-shadow'],
            border: `1px solid ${tokens.styles['popover-borderColor']}`,
            position: 'relative',
        },
        size === 'sm'
            ? {
                paddingBlockStart: tokens.space['small-md'],
                paddingInlineStart: tokens.space['small-md'],
                paddingInlineEnd: tokens.space['small-md'],
                paddingBlockEnd: tokens.space['small-md'],
            }
            : {},
        size === 'lg'
            ? {
                paddingBlockStart: tokens.space['small-lg'],
                paddingInlineStart: tokens.space['small-lg'],
                paddingInlineEnd: tokens.space['small-lg'],
                paddingBlockEnd: tokens.space['small-lg'],
            }
            : {},
    ]);
};
export const popoverContent = (tokens) => ({ size, }) => ({
    closeButton: closeButton(tokens)(),
    popover: getResponsiveStyles(size, (size) => popoverContentBase(tokens)({ size })),
});
