import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `EnergyLabelLabel` is used to display the label text for Energy Label
 */
export const EnergyLabelLabel = forwardRef(({ size, ...props }, ref) => {
    return (_jsx(Text, { size: mapResponsiveValue(size, (size) => (size === 'sm' ? '2xs' : 'xs')), variant: "default", ...props, ref: ref }));
});
EnergyLabelLabel.displayName = 'EnergyLabelLabel';
