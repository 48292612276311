import { combineResponsiveValues, getResponsiveStyles, mergeStyles, } from '../../../component-themes';
export const priceBase = (tokens) => ({ size, variant, alignment, }) => {
    return {
        priceInformation: mergeStyles([
            {
                display: 'flex',
                gap: variant === 'narrow'
                    ? 0
                    : size === 'lg'
                        ? tokens.space['small-sm']
                        : size === '2xs'
                            ? tokens.space['small-xxs']
                            : tokens.space['small-xs'],
            },
            variant === 'wide' && alignment === 'left'
                ? { flexDirection: 'row', alignItems: 'center' }
                : {},
            variant === 'narrow' && alignment === 'left'
                ? { flexDirection: 'column-reverse', alignItems: 'start' }
                : {},
            variant === 'wide' && alignment === 'right'
                ? { flexDirection: 'row-reverse', alignItems: 'center' }
                : {},
            variant === 'narrow' && alignment === 'right'
                ? { flexDirection: 'column-reverse', alignItems: 'end' }
                : {},
        ]),
        discountInformation: mergeStyles([
            {
                display: 'flex',
                flexDirection: alignment === 'left' ? 'row' : 'row-reverse',
                gap: size === 'lg' ? tokens.space['small-sm'] : tokens.space['small-xs'],
                alignItems: 'center',
            },
        ]),
    };
};
export const price = (tokens) => ({ size, variant, alignment, }) => {
    return {
        priceInformation: getResponsiveStyles(combineResponsiveValues(size, variant, alignment), ([size, variant, alignment]) => priceBase(tokens)({ size, variant, alignment }).priceInformation),
        discountInformation: getResponsiveStyles(combineResponsiveValues(size, variant, alignment), ([size, variant, alignment]) => priceBase(tokens)({ size, variant, alignment }).discountInformation),
    };
};
