import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { badge } from './styles/badge';
/**
 * A small label, generally appearing inside or in close proximity to another larger interface component, representing a status, property, or some other metadata
 */
export const Badge = forwardRef(({ as: Component = 'div', size, variant, maxChar, children, ...props }, ref) => {
    const tokens = useTokens();
    const styles = badge(tokens)({ size, variant, maxChar });
    return (_jsx(Component, { css: styles.badge, ...props, ref: ref, children: _jsx("div", { css: styles.text, children: children }) }));
});
Badge.displayName = 'Badge';
