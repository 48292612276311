import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * A text that can be displayed along with the `FormField` component.
 */
export const FormFieldMessage = forwardRef(({ invalid, variant, description, count, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { justify: description ? 'space-between' : 'flex-end', align: "center", css: { gap: tokens.styles[`formField-message-base-gap`] }, ...props, ref: ref, children: [description?.({ variant, invalid }), count?.({ variant, invalid })] }));
});
FormFieldMessage.displayName = 'FormFieldMessage';
