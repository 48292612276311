import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const selectAmountBase = (tokens) => ({ size, fullWidth, variant = 'default', }) => mergeStyles([
    fullWidth ? { width: '100%' } : {},
    {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        borderRadius: tokens.radii.xs,
    },
    variant === 'default'
        ? {
            backgroundColor: tokens.colors['surface-base-100'],
        }
        : {},
    variant === 'prominent'
        ? {
            backgroundColor: tokens.styles['state-action-prominent-background-initial'],
        }
        : {},
    size === 'sm' ? { height: tokens.size['small-xl'] } : {},
    size === 'md' ? { height: tokens.size['small-2xl'] } : {},
    size === 'lg' ? { height: tokens.size['small-3xl'] } : {},
]);
export const selectAmount = (tokens) => ({ size, variant, fullWidth, }) => getResponsiveStyles(size, (size) => selectAmountBase(tokens)({ size, variant, fullWidth }));
