import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Plus } from '../../component-icons';
import { Link } from '../../component-link';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display a link in an `OrderSummaryContentDetailListItem`
 */
export const OrderSummaryContentDetailListItemLink = forwardRef(({ size, ...props }, ref) => (_jsx(Link, { size: mapResponsiveValue(size, (size) => (size === 'lg' ? 'md' : 'sm')), variant: "prominent", alignIcon: "right", icon: Plus, css: { maxWidth: 'fit-content' }, ...props, ref: ref })));
OrderSummaryContentDetailListItemLink.displayName =
    'OrderSummaryContentDetailListItemLink';
