import { iconBase } from '../../../component-icon';
import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const navigationButtonBase = (tokens) => ({ variant, size, active, }) => {
    const iconStyles = iconBase(tokens);
    return {
        base: mergeStyles([
            {
                all: 'unset',
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'space-between',
                gap: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-initial-gap`],
                background: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-initial-background`],
                paddingBlockStart: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-initial-paddingTop`],
                paddingInlineEnd: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-initial-paddingRight`],
                paddingBlockEnd: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-initial-paddingBottom`],
                paddingInlineStart: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-initial-paddingLeft`],
                ':hover': {
                    gap: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-hover-gap`],
                    background: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-hover-background`],
                    paddingBlockStart: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-hover-paddingTop`],
                    paddingInlineEnd: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-hover-paddingRight`],
                    paddingBlockEnd: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-hover-paddingBottom`],
                    paddingInlineStart: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-hover-paddingLeft`],
                },
                ':active': {
                    gap: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-pressed-gap`],
                    background: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-pressed-background`],
                    paddingBlockStart: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-pressed-paddingTop`],
                    paddingInlineEnd: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-pressed-paddingRight`],
                    paddingBlockEnd: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-pressed-paddingBottom`],
                    paddingInlineStart: tokens.styles[`navigationButton-base-${variant}-${size}-${active}-pressed-paddingLeft`],
                },
                ':focus': {
                    outline: 'revert',
                },
            },
        ]),
        label: mergeStyles([
            {
                color: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-color`],
                opacity: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-opacity`],
                fontSize: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-fontSize`],
                fontFamily: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-fontFamily`],
                lineHeight: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-lineHeight`],
                fontWeight: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-fontWeight`],
                letterSpacing: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-initial-letterSpacing`],
            },
            {
                ':hover': {
                    color: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-color`],
                    opacity: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-opacity`],
                    fontSize: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-fontSize`],
                    fontFamily: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-fontFamily`],
                    lineHeight: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-lineHeight`],
                    fontWeight: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-fontWeight`],
                    letterSpacing: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-hover-letterSpacing`],
                },
                ':active': {
                    color: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-color`],
                    opacity: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-opacity`],
                    fontSize: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-fontSize`],
                    fontFamily: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-fontFamily`],
                    lineHeight: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-lineHeight`],
                    fontWeight: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-fontWeight`],
                    letterSpacing: tokens.styles[`navigationButton-label-${variant}-${size}-${active}-pressed-letterSpacing`],
                },
            },
        ]),
        icon: mergeStyles([
            iconStyles({ size: 'md' }),
            {
                color: tokens.styles[`navigationButton-icon-${variant}-${size}-${active}-initial-background`],
            },
            {
                ':hover': {
                    color: tokens.styles[`navigationButton-icon-${variant}-${size}-${active}-hover-background`],
                },
                ':active': {
                    color: tokens.styles[`navigationButton-icon-${variant}-${size}-${active}-pressed-background`],
                },
            },
        ]),
    };
};
export const navigationButton = (tokens) => ({ size, ...rest }) => ({
    base: getResponsiveStyles(size, (size) => navigationButtonBase(tokens)({ size, ...rest }).base),
    label: getResponsiveStyles(size, (size) => navigationButtonBase(tokens)({ size, ...rest }).label),
    icon: getResponsiveStyles(size, (size) => navigationButtonBase(tokens)({ size, ...rest }).icon),
});
