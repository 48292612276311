import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * This component can be used to display an image of a flag.
 */
export const Flag = forwardRef(({ as: Component = 'img', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            objectFit: 'cover',
            display: 'block',
            height: tokens.size['small-sm'],
            width: tokens.size['small-md'],
        }, ...props, ref: ref }));
});
Flag.displayName = 'Flag';
