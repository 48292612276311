import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Heading } from '../../component-heading';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
const headingMap = {
    sm: 'small-5',
    md: 'small-4',
    lg: 'large-5',
};
const styleMap = {
    sm: { textAlign: 'center' },
    md: { textAlign: 'center' },
    lg: { textAlign: 'start' },
};
/**
 * Is used to display the headline within the `FooterNewsletter`.
 */
export const FooterNewsletterHeadline = forwardRef(({ size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Heading, { as: "h3", size: mapResponsiveValue(size, (size) => headingMap[size]), css: [
            getResponsiveStyles(size, (size) => styleMap[size]),
            { color: tokens.styles['footer-social-headline-background'] },
        ], ...props, ref: ref }));
});
FooterNewsletterHeadline.displayName = 'FooterNewsletterHeadline';
