import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { framerDuration } from '../../component-utilities';
import { m as motion } from 'framer-motion';
import { forwardRef } from 'react';
export const NavigationInfoBarContentCountdownItemNumberDigit = forwardRef((props, ref) => {
    const tokens = useTokens();
    return (_jsx(motion.div, { initial: { y: -10, opacity: 0 }, animate: { y: 0, opacity: 1 }, exit: { y: 10, opacity: 0 }, transition: {
            duration: framerDuration(tokens.animation['duration-quick']),
        }, css: {
            position: 'absolute',
            color: tokens.styles['navigation-infobar-content-countdown-item-number-color'],
            fontSize: tokens.styles['navigation-infobar-content-countdown-item-number-fontSize'],
            fontFamily: tokens.styles['navigation-infobar-content-countdown-item-number-fontFamily'],
            lineHeight: tokens.styles['navigation-infobar-content-countdown-item-number-lineHeight'],
            fontWeight: tokens.styles['navigation-infobar-content-countdown-item-number-fontWeight'],
            letterSpacing: tokens.styles['navigation-infobar-content-countdown-item-number-letterSpacing'],
        }, ...props, ref: ref }));
});
NavigationInfoBarContentCountdownItemNumberDigit.displayName =
    'NavigationInfoBarContentCountdownItemNumberDigit';
/**
 * Should be used to display the `firstDigit` of `NavigationInfoBarContentCountdownItemNumber`.
 * Different components for digit1 and digit2 are needed to animate them separately.
 */
export const NavigationInfoBarContentCountdownItemNumberDigit1 = (props) => {
    return (_jsx(NavigationInfoBarContentCountdownItemNumberDigit, { ...props, css: { insetInlineStart: 0 } }));
};
/**
 * Should be used to display the `secondDigit` of `NavigationInfoBarContentCountdownItemNumber`.
 * Different components for digit1 and digit2 are needed to animate them separately.
 */
export const NavigationInfoBarContentCountdownItemNumberDigit2 = (props) => (_jsx(NavigationInfoBarContentCountdownItemNumberDigit, { ...props, css: { insetInlineEnd: 0 } }));
