import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { m as motion } from 'framer-motion';
import { forwardRef } from 'react';
import { tabBarItem } from './styles/tabbar-item';
/**
 * `TabBarItem` is used to render an item inside a `TabBar`
 */
export const TabBarItem = forwardRef(({ variant, animationGroupId, size, active, children, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = tabBarItem(tokens)({ size, variant, active });
    return (_jsxs(Component, { css: styles.wrapper, ...props, ref: ref, children: [_jsx("p", { css: styles.label, children: children }), active && _jsx(motion.div, { css: styles.line, layoutId: animationGroupId })] }));
});
TabBarItem.displayName = 'TabBarItem';
