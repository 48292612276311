import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ChevronDown } from '../../component-icons';
import { Link } from '../../component-link';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used when a link is displayed within `NavigationInfoBarContent`.
 */
export const NavigationInfoBarContentLink = forwardRef((props, ref) => {
    const tokens = useTokens();
    return (_jsx(Link, { size: "md", variant: "prominent", alignIcon: "right", icon: ChevronDown, css: {
            color: tokens.styles[`navigation-infobar-content-link-textColor-default`],
            ':hover': {
                color: tokens.styles[`navigation-infobar-content-link-textColor-hover`],
            },
            ':active': {
                color: tokens.styles[`navigation-infobar-content-link-textColor-pressed`],
            },
            ':disabled': {
                color: tokens.styles[`navigation-infobar-content-link-textColor-disabled`],
            },
        }, ...props, ref: ref }));
});
NavigationInfoBarContentLink.displayName = 'NavigationInfoBarContentLink';
