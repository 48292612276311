import { getResponsiveStyles } from '../../../component-themes';
export const overlayCardHeaderHeadline = (tokens) => ({ size }) => getResponsiveStyles(size, (size) => ({
    margin: 0,
    color: tokens.styles[`overlayCard-header-headline-${size}-color`],
    fontSize: tokens.styles[`overlayCard-header-headline-${size}-fontSize`],
    fontFamily: tokens.styles[`overlayCard-header-headline-${size}-fontFamily`],
    lineHeight: tokens.styles[`overlayCard-header-headline-${size}-lineHeight`],
    fontWeight: tokens.styles[`overlayCard-header-headline-${size}-fontWeight`],
    letterSpacing: tokens.styles[`overlayCard-header-headline-${size}-letterSpacing`],
}));
