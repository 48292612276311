import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be passed as an element of `secondaryActions` slot of `Navigation` component. Component renders `NavigationBarSearchButton`
 * or `NavigationBarSearchInput` based on the `isSearchActive` flag. `NavigationBarSearchInput` will no te rendered for the `sm` viewport size.
 */
export const NavigationBarActionsSecondarySearch = forwardRef(({ as: Component = 'div', button, onBackground, isSearchActive, size, input, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Component, { ref: ref, ...props, children: [isSearchActive && (_jsx("div", { css: [
                    getResponsiveStyles(size, (size) => isSearchActive && size !== 'sm'
                        ? { display: 'block' }
                        : { display: 'none' }),
                    { width: tokens.wrapper['width-3xs'] },
                ], children: input?.({}) })), _jsx("div", { css: getResponsiveStyles(size, (size) => (isSearchActive && size === 'sm') || !isSearchActive
                    ? { display: 'block' }
                    : { display: 'none' }), children: button?.({ size, onBackground }) })] }));
});
NavigationBarActionsSecondarySearch.displayName =
    'NavigationBarActionsSecondarySearch';
