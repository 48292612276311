export const breadcrumbText = (tokens) => () => {
    return {
        color: tokens.styles['breadcrumb-currentPage-color'],
        fontSize: tokens.styles['breadcrumb-currentPage-fontSize'],
        fontWeight: tokens.styles['breadcrumb-currentPage-fontWeight'],
        fontFamily: tokens.styles['breadcrumb-currentPage-fontFamily'],
        lineHeight: tokens.styles['breadcrumb-currentPage-lineHeight'],
        letterSpacing: tokens.styles['breadcrumb-currentPage-letterSpacing'],
        opacity: tokens.styles['breadcrumb-currentPage-opacity'],
        margin: 0,
    };
};
