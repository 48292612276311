import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Flag } from '../../component-flag';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display the flag of the `InputIndicatorCountrySelector` component.
 */
export const InputIndicatorCountrySelectorFlag = forwardRef(({ state, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Flag, { css: {
            opacity: tokens.styles[`input-indicator-countrySelector-flag-${state}-opacity`],
            transition: state === 'pressed'
                ? `all ${tokens.styles['interaction-pressed-duration']} ${tokens.styles['interaction-pressed-timing']}`
                : `all ${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
        }, ...props, ref: ref }));
});
InputIndicatorCountrySelectorFlag.displayName =
    'InputIndicatorCountrySelectorFlag';
