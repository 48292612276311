import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Motion } from '../../component-motion';
import { getResponsiveStyles, useCurrentResponsiveValue, useTokens, } from '../../component-themes';
import { framerDuration, framerEase } from '../../component-utilities';
import { forwardRef } from 'react';
/**
 * The `ExpandArea` element is used as a container element for long content to display it in a compact form and make it available to the user when needed
 */
export const ExpandArea = forwardRef(({ size, variant, expanded, content, button, ...props }, ref) => {
    const currentSize = useCurrentResponsiveValue(size) || 'xs';
    const tokens = useTokens();
    const duration = framerDuration(tokens.animation['duration-slow']);
    return (
    /* @ts-expect-error ignore onAnimationStart */
    _jsxs(Motion, { initial: false, animate: {
            height: tokens.styles[`expandArea-${currentSize}-${expanded ? 'expanded' : 'default'}-height`],
        }, transition: expanded
            ? {
                type: 'spring',
                stiffness: 120,
                damping: 15,
                mass: 1,
            }
            : {
                duration,
                ease: framerEase(tokens.animation['timing-soft']),
                delay: expanded ? duration * 0.9 : 0,
            }, css: [
            {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            },
            getResponsiveStyles(size, (size) => ({
                gap: tokens.styles[`expandArea-${size}-${expanded ? 'expanded' : 'default'}-gap`],
            })),
        ], ...props, ref: ref, children: [_jsxs("div", { css: {
                    position: 'relative',
                    height: '100%',
                    overflow: 'hidden',
                }, children: [content({}), _jsx(Motion, { initial: false, animate: {
                            opacity: expanded ? 0 : tokens.emphasis.full,
                            height: expanded ? 0 : tokens.size['large-xs'],
                        }, transition: {
                            duration,
                            ease: framerEase(tokens.animation['timing-soft']),
                            delay: expanded ? duration * 0.5 : 0,
                        }, css: {
                            position: 'absolute',
                            insetBlockEnd: 0,
                            insetInlineStart: 0,
                            insetInlineEnd: 0,
                            zIndex: 1,
                            background: tokens.styles[`expandArea-gradient-${variant}`],
                        } })] }), button({ expanded })] }));
});
ExpandArea.displayName = 'ExpandArea';
