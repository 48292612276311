import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { forwardRef } from 'react';
/**
 * The `Rating` component can be used to visually display the average rating of a product. The rating can be between 0 and 5 stars (in 0.1 steps, the value will be rounded up).
 */
export const Rating = forwardRef(({ size, info, icons, ...props }, ref) => {
    return (_jsxs(Stack, { direction: "horizontal", spacing: "small-xs", align: "center", ...props, ref: ref, children: [icons({ size }), info({})] }));
});
Rating.displayName = 'Rating';
