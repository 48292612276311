import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const sublineSizeMap = {
    xs: 'sm',
    sm: 'md',
    md: 'md',
    lg: 'md',
    xl: 'lg',
};
/**
 * Is used to set the title of accordion items button.
 */
export const AccordionItemButtonTitle = forwardRef(({ size, state, headline, subline, ...props }, ref) => (_jsxs(Stack, { align: "flex-start", direction: "vertical", ...props, ref: ref, children: [headline({ size, state }), subline?.({
            size: mapResponsiveValue(size, (size) => sublineSizeMap[size]),
            state,
        })] })));
AccordionItemButtonTitle.displayName = 'AccordionItemButtonTitle';
