import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { highlightWrapper } from './styles/highlight-wrapper';
/**
 * The Highlight wrapper is a wrapper to highlight content.
 */
export const HighlightWrapper = forwardRef(({ size, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = highlightWrapper(tokens)({ size });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
HighlightWrapper.displayName = 'HighlightWrapper';
