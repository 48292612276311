import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { toggle } from './styles/toggle';
/*
 * The `Toggle` is used to quickly switch between two possible states. They are commonly used for “on/off” switches.
 */
export const Toggle = forwardRef(({ size, selected = false, as: Component = 'input', ...props }, ref) => {
    const tokens = useTokens();
    const styles = toggle(tokens)({
        size,
        variant: selected ? 'active' : 'inactive',
    });
    return _jsx(Component, { type: "checkbox", css: styles, ref: ref, ...props });
});
Toggle.displayName = 'Toggle';
