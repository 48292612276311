import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `FormField` can be used to add a control and text.
 */
export const FormField = forwardRef(({ control, message, disabled = false, invalid = false, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { as: "label", direction: "vertical", css: { gap: tokens.styles[`formField-base-lg-gap`] }, ...props, ref: ref, children: [control?.({
                disabled,
                invalid,
            }), message?.({ invalid })] }));
});
FormField.displayName = 'FormField';
