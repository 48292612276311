import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const priceMainBase = (tokens) => ({ size }) => {
    return mergeStyles([
        {
            color: tokens.colors['surface-onBase-50'],
            opacity: tokens.emphasis.full,
            fontFamily: tokens.styles[`price-mainPrice-${size}-fontFamily`],
            fontSize: tokens.styles[`price-mainPrice-${size}-fontSize`],
            fontWeight: tokens.styles[`price-mainPrice-${size}-fontWeight`],
            letterSpacing: tokens.styles[`price-mainPrice-${size}-letterSpacing`],
            lineHeight: tokens.styles[`price-mainPrice-${size}-lineHeight`],
        },
    ]);
};
export const priceMain = (tokens) => ({ size, }) => getResponsiveStyles(size, (size) => priceMainBase(tokens)({ size }));
