import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used display the content of `NavigationInfoBar`.
 */
export const NavigationInfoBarContent = forwardRef(({ size, countdown, label, link, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { align: "center", css: getResponsiveStyles(size, (size) => ({
            width: '100%',
            gap: tokens.styles['navigation-infobar-content-base-gap'],
            justifyContent: size === 'sm' ? 'space-between' : 'center',
            alignItems: 'center',
        })), ...props, ref: ref, children: [countdown ? (_jsxs(Stack, { css: getResponsiveStyles(size, (size) => ({
                    width: size === 'sm' ? '100%' : 'auto',
                    gap: tokens.styles['navigation-infobar-content-base-gap'],
                    alignItems: 'center',
                })), children: [countdown?.({}), label({})] })) : (label({})), link({})] }));
});
NavigationInfoBarContent.displayName = 'NavigationInfoBarContent';
