import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { featureListItemIcon } from './styles/feature-list-item-icon';
/**
 * The `FeatureListItemIcon` should be used to display an icon inside of `FeatureListItem`.
 */
export const FeatureListItemIcon = forwardRef(({ size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = featureListItemIcon(tokens)({
        variant,
    });
    return _jsx(Icon, { size: size, css: styles, ...props, ref: ref });
});
FeatureListItemIcon.displayName = 'FeatureListItemIcon';
