import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { Children, forwardRef, useMemo } from 'react';
import { stack } from './styles/stack';
/**
 * Use when you want to stack elements vertically or horizontally.
 * The layouting props are responsive.
 * You can pass a divider element to be rendered between each child.
 */
export const Stack = forwardRef(({ as: Component = 'div', align, justify, spacing, direction, children, divider, ...props }, ref) => {
    const tokens = useTokens();
    const styles = stack(tokens)({ align, justify, spacing, direction });
    const elements = useMemo(() => Children.toArray(children), [children]);
    const mapped = useMemo(() => elements.flatMap((element, index) => index !== elements.length - 1 ? [element, divider] : element), [elements, divider]);
    return (_jsx(Component, { css: styles, ...props, ref: ref, children: Children.map(mapped, (child) => child) }));
});
Stack.displayName = 'Stack';
