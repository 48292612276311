import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { tabBar } from './styles/tabbar';
/**
 * The `TabBar` component is used to display a tab bar.
 */
export const TabBar = forwardRef(({ size, variant, leftArrow, rightArrow, items, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = tabBar(tokens)({ size, variant });
    return (_jsxs(Component, { css: styles.bar, ...props, ref: ref, children: [leftArrow && (_jsx("div", { css: styles.leftArrow, children: leftArrow({ size, direction: 'left', variant }) })), _jsx("div", { css: styles.line }), items({ size, variant }), rightArrow && (_jsx("div", { css: styles.rightArrow, children: rightArrow({ size, direction: 'right', variant }) }))] }));
});
TabBar.displayName = 'TabBar';
