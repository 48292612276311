import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { ChevronLeft, ChevronRight } from '../../component-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { tabBarArrowItem } from './styles/tabbar-arrow-item';
/**
 * The `TabBarArrowItem` component is used to render left or right arrows at the end or beginning of a `TabBar`.
 */
export const TabBarArrowItem = forwardRef(({ direction, size, variant, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = tabBarArrowItem(tokens)({ size, variant });
    return (_jsx(Component, { css: styles.wrapper, ref: ref, ...props, children: _jsx(Icon, { size: "md", as: direction === 'left' ? ChevronLeft : ChevronRight, css: styles.icon }) }));
});
TabBarArrowItem.displayName = 'TabBarArrowItem';
