import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const GridDivider = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { ...props, ref: ref, css: {
            minWidth: tokens.styles['grid-divider-minWidth'],
            minHeight: tokens.styles['grid-divider-minHeight'],
            background: tokens.styles['grid-divider-background'],
            borderRadius: tokens.styles['grid-divider-borderRadius'],
        } }));
});
GridDivider.displayName = 'GridDivider';
