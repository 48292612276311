import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const footerWrapper = (tokens) => ({ size }) => {
    return mergeStyles([
        {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: tokens.styles['footer-base-background'],
        },
        size === 'sm'
            ? {
                paddingBlock: tokens.space['small-2xl'],
                paddingInline: tokens.space['small-md'],
                gap: tokens.space['small-2xl'],
            }
            : {},
        size === 'md'
            ? {
                padding: tokens.space['small-3xl'],
                gap: tokens.space['small-3xl'],
            }
            : {},
        size === 'lg'
            ? {
                paddingBlock: tokens.space['large-xs'],
                paddingInline: tokens.space['small-2xl'],
                gap: tokens.space['large-xs'],
            }
            : {},
    ]);
};
export const footer = (tokens) => ({ size }) => getResponsiveStyles(size, (size) => footerWrapper(tokens)({ size }));
