import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { radioButton } from './styles/radio-button';
export const RadioButton = forwardRef(({ as: Component = 'input', ...props }, ref) => {
    const tokens = useTokens();
    const styles = radioButton(tokens)();
    return _jsx(Component, { type: "radio", css: styles, ...props, ref: ref });
});
RadioButton.displayName = 'RadioButton';
