import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
/**
 * The `NotificationCounter` is used to display the number of items assigned to an element
 */
export const NotificationCounter = forwardRef(({ element, variant, counter, as: Component = 'div', ...props }, ref) => {
    return (_jsxs(Component, { css: {
            position: 'relative',
            display: 'inline-block',
            width: 'fit-content',
        }, ...props, ref: ref, children: [_jsx("div", { css: {
                    position: 'absolute',
                    insetBlockStart: 0,
                    insetInlineEnd: 0,
                    zIndex: 1,
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'translateY(-33%) translateX(33%)',
                }, children: counter({ variant }) }), element({})] }));
});
NotificationCounter.displayName = 'NotificationCounter';
