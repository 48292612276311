import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniContentInformation = forwardRef(({ as: Component = 'div', size, variant, productTitle, price, ...props }, ref) => {
    const tokens = useTokens();
    return variant !== 'reduced' ? (_jsxs(Component, { css: getResponsiveStyles(size, (size) => ({
            display: 'flex',
            flexDirection: variant === 'vertical' ? 'column' : 'row',
            alignItems: variant === 'horizontal' ? 'center' : 'stretch',
            gap: tokens.styles[`productTeaserMini-content-product-base-${size}-gap`],
        })), ...props, ref: ref, children: [productTitle({ size }), price?.({ size })] })) : (_jsx(Component, { ...props, ref: ref, children: productTitle({ size }) }));
});
ProductTeaserMiniContentInformation.displayName =
    'ProductTeaserMiniContentInformation';
