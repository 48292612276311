import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const tabBarArrowItemBase = (tokens) => ({ size, variant, }) => {
    return {
        wrapper: mergeStyles([
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                background: tokens.styles[`tabBar-button-base-${variant}-${size}-initial-background`],
                height: tokens.styles[`tabBar-button-base-${variant}-${size}-initial-height`],
                paddingInlineStart: tokens.styles[`tabBar-button-base-${variant}-${size}-initial-paddingLeft`],
                paddingInlineEnd: tokens.styles[`tabBar-button-base-${variant}-${size}-initial-paddingRight`],
                transitionProperty: 'filter',
                transitionDuration: tokens.styles['interaction-hover-duration'],
                transitionTimingFunction: tokens.styles['interaction-hover-timing'],
                ':hover': {
                    filter: [
                        tokens.styles['interaction-hover-brightness'],
                        tokens.styles['interaction-hover-contrast'],
                    ].join(' '),
                    background: tokens.styles[`tabBar-button-base-${variant}-${size}-hover-background`],
                    height: tokens.styles[`tabBar-button-base-${variant}-${size}-hover-height`],
                    paddingInlineStart: tokens.styles[`tabBar-button-base-${variant}-${size}-hover-paddingLeft`],
                    paddingInlineEnd: tokens.styles[`tabBar-button-base-${variant}-${size}-hover-paddingRight`],
                },
                ':active': {
                    filter: [
                        tokens.styles['interaction-pressed-brightness'],
                        tokens.styles['interaction-pressed-contrast'],
                    ].join(' '),
                    background: tokens.styles[`tabBar-button-base-${variant}-${size}-pressed-background`],
                    height: tokens.styles[`tabBar-button-base-${variant}-${size}-pressed-height`],
                    paddingInlineStart: tokens.styles[`tabBar-button-base-${variant}-${size}-pressed-paddingLeft`],
                    paddingInlineEnd: tokens.styles[`tabBar-button-base-${variant}-${size}-pressed-paddingRight`],
                },
            },
        ]),
        icon: {
            color: tokens.styles[`tabBar-button-icon-${variant}-initial-background`],
            opacity: tokens.styles[`tabBar-button-icon-${variant}-initial-opacity`],
            ':hover': {
                color: tokens.styles[`tabBar-button-icon-${variant}-hover-background`],
                opacity: tokens.styles[`tabBar-button-icon-${variant}-hover-opacity`],
            },
            ':active': {
                color: tokens.styles[`tabBar-button-icon-${variant}-pressed-background`],
                opacity: tokens.styles[`tabBar-button-icon-${variant}-pressed-opacity`],
            },
        },
    };
};
export const tabBarArrowItem = (tokens) => ({ size, variant, }) => {
    return {
        wrapper: getResponsiveStyles(size, (size) => tabBarArrowItemBase(tokens)({ size, variant }).wrapper),
        icon: getResponsiveStyles(size, (size) => tabBarArrowItemBase(tokens)({ size, variant }).icon),
    };
};
