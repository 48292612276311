import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { OverlayCardHeader } from './overlay-card-header';
/**
 * Header with a back button as content.
 */
export const OverlayCardHeaderWithBackButton = forwardRef(({ size, backButton, ...props }, ref) => {
    return (_jsx(OverlayCardHeader, { content: backButton ? () => backButton({ size }) : undefined, size: size, ...props, ref: ref }));
});
OverlayCardHeaderWithBackButton.displayName = 'OverlayCardHeaderWithBackButton';
