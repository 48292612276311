import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { getResponsiveStyles } from '../../component-themes';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const spacingMap = {
    '2xs': 'sm',
    xs: 'sm',
    sm: 'sm',
    md: 'lg',
    lg: 'lg',
};
/**
 * Can be used to display classification of EnergyLabel. It is used to show an image of the energy label together with a link.
 */
export const EnergyLabelClassification = forwardRef(({ alignment, size, image, link, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: mapResponsiveValue(alignment, (alignment) => alignment === 'left' ? 'horizontal' : 'horizontal-reverse'), css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`energylabel-classification-${spacingMap[size]}-gap`],
        })), ...props, ref: ref, children: [image({ size }), link?.({
                size: mapResponsiveValue(size, (size) => ({ '2xs': 'sm', xs: 'sm', sm: 'lg', md: 'lg', lg: 'lg' }[size])),
            })] }));
});
EnergyLabelClassification.displayName = 'EnergyLabelClassification';
