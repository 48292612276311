import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { SocialMediaIcons } from '../../component-social-media-icons';
import { getResponsiveStyles } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * A component to show a horizontal list of `SocialMediaIcon` components in the 'Footer'. The component will align its contents to the center for sizes `sm` and `md` and to the left for size `lg`.
 */
export const FooterSocialMediaIcons = forwardRef(({ size, ...props }, ref) => {
    return (_jsx(SocialMediaIcons, { css: getResponsiveStyles(size, (size) => size === 'lg'
            ? { justifyContent: 'start' }
            : { justifyContent: 'center' }), ...props, ref: ref }));
});
FooterSocialMediaIcons.displayName = 'FooterSocialMediaIcons';
