import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Edit } from '../../component-icons';
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the header in the `OrderSummaryContent`
 */
export const OrderSummaryContentHeader = forwardRef(({ size, headline, link, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { justify: "space-between", css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`orderSummary-content-header-base-${size}-gap`],
        })), ...props, ref: ref, children: [headline({ size }), link?.({
                size: mapResponsiveValue(size, (size) => size === 'lg' ? 'md' : 'sm'),
                variant: 'prominent',
                alignIcon: 'left',
                icon: Edit,
            })] }));
});
OrderSummaryContentHeader.displayName = 'OrderSummaryContentHeader';
