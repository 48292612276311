import { combineResponsiveValues, getResponsiveStyles, mergeStyles, } from '../../../component-themes';
export const contentTeaserBase = (tokens) => ({ alignment, size, orientation, }) => {
    const paddingBlockMap = {
        sm: 'small-xl',
        md: 'small-2xl',
        lg: 'small-3xl',
        xl: 'small-3xl',
    };
    const paddingInlineMap = {
        sm: 'small-md',
        md: 'small-lg',
        lg: 'small-lg',
        xl: 'small-lg',
    };
    return mergeStyles([
        {
            zIndex: 1,
            position: 'relative',
            width: '100%',
            paddingBlock: orientation === 'landscape' ? tokens.space[paddingBlockMap[size]] : 0,
            paddingInlineStart: orientation === 'landscape'
                ? alignment === 'start'
                    ? tokens.space[paddingInlineMap[size]]
                    : '50%'
                : 0,
            paddingInlineEnd: orientation === 'landscape'
                ? alignment === 'finish'
                    ? tokens.space[paddingInlineMap[size]]
                    : '50%'
                : 0,
        },
    ]);
};
export const contentTeaser = (tokens) => ({ alignment, size, orientation, }) => getResponsiveStyles(combineResponsiveValues(alignment, size, orientation), ([alignment, size, orientation]) => contentTeaserBase(tokens)({ alignment, size, orientation }));
