import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { framerDuration } from '../../component-utilities';
import { AnimatePresence, m as motion } from 'framer-motion';
import { forwardRef } from 'react';
import { modal } from './styles/modal';
/**
 * The `Modal` component can be used to show a modal dialog overlaid on the primary window.
 * The Content behind the `Modal` is inert, meaning that users cannot interact with it.
 * The modal should hand the config to its sub-components in order to configure them correctly.
 */
export const Modal = forwardRef(({ size, isOpen, content, fullscreen, style: { zIndex, ...style } = {}, ...props }, ref) => {
    const tokens = useTokens();
    const styles = modal(tokens)({
        size,
        fullscreen,
    });
    return (_jsx(AnimatePresence, { children: isOpen && (_jsx("div", { css: styles.container, style: {
                zIndex,
            }, children: _jsx(motion.div, { css: styles.modal, ...props, ref: ref, initial: "closed", animate: "open", exit: "closed", style: style, variants: fullscreen
                    ? {
                        open: {
                            opacity: 1,
                            scale: 1,
                            y: 0,
                        },
                        closed: {
                            opacity: 0,
                            scale: 0.75,
                            y: 0,
                        },
                    }
                    : {
                        open: {
                            opacity: 1,
                            scale: 1,
                            y: 0,
                        },
                        closed: {
                            opacity: 0,
                            scale: 1,
                            y: '50%',
                        },
                    }, transition: {
                    ease: 'easeInOut',
                    duration: framerDuration(tokens.animation['duration-slow']),
                }, children: content({}) }) })) }));
});
Modal.displayName = 'Modal';
