import React, { ReactNode } from 'react';
import { domAnimation, LazyMotion } from 'framer-motion';
import { Modal as SherpaModal } from '../../../../deps/@bshgroup/component-modal';
import {
  OverlayCard as SherpaOverlayCard,
  OverlayCardContent as SherpaOverlayCardContent,
  OverlayCardHeader as SherpaOverlayCardHeader,
  OverlayCardHeaderHeadline as SherpaOverlayCardHeaderHeadline,
} from '../../../../deps/@bshgroup/component-overlay-card';
import { ButtonAction as SherpaButtonAction } from '../../../../deps/@bshgroup/component-button-action';
import { Close as SherpaClose } from '../../../../deps/@bshgroup/component-icons';
import { LinearLayout } from '../LinearLayout/LinearLayout';
import { Divider } from '../Divider/Divider';
import { CSSObject } from '@emotion/styled';

export interface DialogProps {
  /**
   * Dialog title
   */
  title?: ReactNode;
  /**
   * Show title divider
   */
  titleDivider?: boolean;
  /**
   * On close handler, when omitted no close button is shown
   */
  onClose?: () => void;
  /**
   * Show title ellipsis
   */
  titleEllipsis?: boolean;
  /**
   * Dialog content
   */
  children?: ReactNode;
  /**
   * Whether to show the dialog boxless
   */
  boxless?: boolean;
  /**
   * Size of dialog
   */
  size?: 'md' | 'lg';
  /**
   * Background of the dialog
   */
  background?: string;
  /**
   * ID selector for the dialog content
   */
  contentId?: string;
  /**
   * Additional CSS for the dialog box
   */
  boxCss?: CSSObject;
}

/**
 * The `Dialog` component is used to render a pop up dialog
 */
export function Dialog({
  title,
  titleDivider = false,
  onClose,
  titleEllipsis = false,
  children,
  boxless = false,
  size = 'md',
  background,
  contentId,
  boxCss = {} as CSSObject,
  ...props
}: DialogProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof DialogProps>) {
  return (
    <div
      css={{
        '& > div': { padding: '0.2rem', backdropFilter: 'blur(16px)', overflow: 'hidden' },
      }}
      {...props}
    >
      <LazyMotion features={domAnimation}>
        <SherpaModal
          id={contentId}
          size="md"
          isOpen={true}
          style={{
            zIndex: 100,
            maxWidth: size === 'md' ? '40rem' : undefined,
            width: size === 'lg' ? '90vw' : undefined,
            height: size === 'lg' ? '90vh' : undefined,
            ...(boxless ? { background: 'none', border: 'none', outline: 'none', boxShadow: 'none' } : {}),
          }}
          content={config =>
            boxless ? (
              (children as React.ReactElement)
            ) : (
              <SherpaOverlayCard
                {...config}
                size="sm"
                css={{
                  '& > div': {
                    background: background,
                    overflow: 'hidden',
                  },
                }}
                header={config => (
                  <SherpaOverlayCardHeader
                    {...config}
                    content={config => (
                      <SherpaOverlayCardHeaderHeadline
                        size="sm"
                        {...config}
                        css={{
                          marginLeft: '1rem',
                          width: '100%',
                          ...(titleEllipsis
                            ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                            : {}),
                        }}
                      >
                        {title}
                      </SherpaOverlayCardHeaderHeadline>
                    )}
                    closeButton={config =>
                      onClose ? (
                        <SherpaButtonAction
                          {...config}
                          size="md"
                          variant="subtle"
                          icon={SherpaClose}
                          onClick={onClose}
                          css={{ marginRight: '1rem' }}
                        />
                      ) : (
                        <></>
                      )
                    }
                    css={{
                      paddingTop: '1.5rem',
                      '& > div': {
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'auto',
                      },
                    }}
                  />
                )}
                content={config => (
                  <SherpaOverlayCardContent
                    css={{
                      maxHeight: size === 'md' ? '75vh' : undefined,
                      height: size === 'lg' ? '100%' : undefined,
                      overflow: 'auto',
                    }}
                    {...config}
                  >
                    <LinearLayout spacing="small" css={{ height: size === 'lg' ? '100%' : undefined }}>
                      {titleDivider ? <Divider /> : <div />}
                      <div
                        css={{
                          height: size === 'lg' ? '100%' : undefined,
                        }}
                      >
                        {children}
                      </div>
                    </LinearLayout>
                  </SherpaOverlayCardContent>
                )}
                footer={() => <></>}
              />
            )
          }
          css={boxCss}
        />
      </LazyMotion>
    </div>
  );
}
