import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { Children, forwardRef } from 'react';
/**
 * Is used to display a list of links within the footer. Should be used in the `link` and within the `Accordion` used in the `accordion` slot.
 */
export const FooterLinkList = forwardRef(({ headline, items, ...props }, ref) => {
    return (_jsxs(Stack, { direction: "vertical", spacing: "small-md", css: { maxWidth: '320px' }, ...props, ref: ref, children: [headline?.({}), Children.map(items.map((item) => item({})), (item) => item)] }));
});
FooterLinkList.displayName = 'FooterLinkList';
