import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { ChevronDown } from '../../component-icons';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
const iconSizeMap = {
    xs: 'sm',
    sm: 'md',
    md: 'lg',
    lg: 'lg',
    xl: 'lg',
};
export const AccordionItemButtonIcon = forwardRef(({ size, extended, active, state, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Icon, { as: ChevronDown, css: [
            {
                transition: `all ${tokens.animation['duration-slow']} ${tokens.animation['timing-soft']}`,
                opacity: tokens.styles[`accordion-button-icon-${state}-opacity`],
                color: tokens.styles[`accordion-button-icon-${state}-background`],
            },
            extended && !active
                ? {
                    transform: 'rotateZ(-180deg)',
                }
                : {},
            extended && active
                ? {
                    transform: `rotateZ(-180deg) ${tokens.transform['scale-down-lg']}`,
                }
                : {},
            !extended && active
                ? {
                    transform: tokens.transform['scale-down-lg'],
                }
                : {},
        ], size: mapResponsiveValue(size, (size) => iconSizeMap[size]), ...props, ref: ref }));
});
AccordionItemButtonIcon.displayName = 'AccordionItemButtonIcon';
