import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { selectAmountCountSuffix } from './styles/select-amount-count-suffix';
/**
 * The `SelectAmountCountSuffix` component is used to render an amount with a text suffix
 */
export const SelectAmountCountSuffix = forwardRef(({ size, variant, as: Component = 'input', ...props }, ref) => {
    const tokens = useTokens();
    const styles = selectAmountCountSuffix(tokens)({ size, variant });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
SelectAmountCountSuffix.displayName = 'SelectAmountCountSuffix';
