import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display an icon as an indicator in the `Input` component.
 * It should be used only for icons that are not clickable. For clickable icons use `InputIndicatorAction`.
 */
export const InputIndicatorIcon = forwardRef(({ disabled, invalid, focused, filled, hovered, ...props }, ref) => {
    const tokens = useTokens();
    const errorState = invalid ? 'error' : 'idle';
    const state = disabled
        ? filled
            ? 'filled-disabled'
            : 'initial-disabled'
        : hovered
            ? 'hover'
            : focused
                ? 'focused'
                : filled
                    ? 'filled'
                    : 'initial';
    return (_jsx(Icon, { size: "sm", css: [
            {
                boxSizing: 'content-box',
                paddingInlineStart: tokens.styles['input-indicator-helper-base-paddingLeft'],
                paddingInlineEnd: tokens.styles['input-indicator-helper-base-paddingRight'],
                transition: `all ${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
                opacity: tokens.styles[`input-indicator-helper-icon-${errorState}-${state}-opacity`],
                color: tokens.styles[`input-indicator-helper-icon-${errorState}-${state}-background`],
            },
        ], ...props, ref: ref }));
});
InputIndicatorIcon.displayName = 'InputIndicatorIcon';
