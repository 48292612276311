import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Is used to display text within the `FooterLegalInformation` component.
 */
export const FooterLegalInformationText = forwardRef(({ ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Text, { emphasis: "medium", size: "xs", variant: "default", css: { color: tokens.styles['footer-legalText-background'] }, ...props, ref: ref }));
});
FooterLegalInformationText.displayName = 'FooterLegalInformationText';
