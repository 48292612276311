import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { FloatingCard } from '../../component-floating-card';
import { combineResponsiveValues, getResponsiveStyles, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to show an order summary in the checkout process.
 */
export const OrderSummary = forwardRef(({ as: Component = 'div', size, expanded, variant, expandButton, content, footer, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(_Fragment, { children: [_jsx(FloatingCard, { elevation: "high", variant: "default", edge: "bottom", css: getResponsiveStyles(variant, (variant) => ({
                    position: 'relative',
                    overflow: 'hidden',
                    display: variant === 'floating' ? 'flex' : 'none',
                    flexDirection: 'column',
                    width: '100%',
                })), content: () => (_jsxs(_Fragment, { children: [expandButton?.({
                            expanded: expanded ?? false,
                        }), content({
                            size,
                            variant,
                            expanded,
                        }), footer({
                            size,
                        })] })), ...props, ref: ref }), _jsxs(Component, { css: getResponsiveStyles(combineResponsiveValues(size, variant), ([size, variant]) => ({
                    display: variant === 'default' ? 'block' : 'none',
                    background: tokens.styles[`orderSummary-base-${size}-default-background`],
                    borderColor: tokens.styles[`orderSummary-base-${size}-default-borderColor`],
                    borderRadius: tokens.styles[`orderSummary-base-${size}-default-borderRadius`],
                    borderStyle: tokens.styles[`orderSummary-base-${size}-default-borderStyle`],
                    borderWidth: tokens.styles[`orderSummary-base-${size}-default-borderWidth`],
                    paddingBlockStart: tokens.styles[`orderSummary-base-${size}-default-paddingTop`],
                })), ...props, ref: ref, children: [content({
                        size,
                        variant,
                    }), footer({
                        size,
                    })] })] }));
});
OrderSummary.displayName = 'OrderSummary';
