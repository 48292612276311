import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { forwardRef } from 'react';
export const ContentTeaserImage = forwardRef(({ as: Component = 'img', orientation, ...props }, ref) => {
    return (_jsx(Component, { css: [
            {
                aspectRatio: '4 / 3',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
            },
            getResponsiveStyles(orientation, (orientation) => ({
                position: orientation === 'landscape' ? 'absolute' : 'relative',
            })),
        ], ...props, ref: ref }));
});
ContentTeaserImage.displayName = 'ContentTeaserImage';
