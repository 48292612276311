import { getResponsiveStyles } from '../../../component-themes';
const accordionItemButtonTitleSublineBase = (tokens) => ({ size, state, }) => ({
    fontSize: tokens.styles[`accordion-button-title-subline-${size}-${state}-fontSize`],
    fontWeight: tokens.styles[`accordion-button-title-subline-${size}-${state}-fontWeight`],
    fontFamily: tokens.styles[`accordion-button-title-subline-${size}-${state}-fontFamily`],
    lineHeight: tokens.styles[`accordion-button-title-subline-${size}-${state}-lineHeight`],
    letterSpacing: tokens.styles[`accordion-button-title-subline-${size}-${state}-letterSpacing`],
    opacity: tokens.styles[`accordion-button-title-subline-${size}-${state}-opacity`],
    color: tokens.styles[`accordion-button-title-subline-${size}-${state}-color`],
    lineClamp: 2,
    webkitLineClamp: 2,
    webkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
});
export const accordionItemButtonTitleSubline = (tokens) => ({ size, state, }) => getResponsiveStyles(size, (size) => accordionItemButtonTitleSublineBase(tokens)({ size, state }));
