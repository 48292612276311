import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { heading } from './styles/heading';
export const Heading = forwardRef(({ size, color, emphasis, as: Component = 'h1', ...props }, ref) => {
    const tokens = useTokens();
    const styles = heading(tokens)({ size, color, emphasis });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
Heading.displayName = 'Heading';
