import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { energyLabelClassificationImage } from './styles/energy-label-classification-image';
/**
 * Can be used to display the image of the `EnergyLabelClassification.
 */
export const EnergyLabelClassificationImage = forwardRef(({ size, as: Component = 'img', ...props }, ref) => {
    const tokens = useTokens();
    const styles = energyLabelClassificationImage(tokens)({ size });
    return _jsx(Component, { css: styles, ref: ref, ...props });
});
EnergyLabelClassificationImage.displayName = 'EnergyLabelClassificationImage';
