import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used when a label is displayed within `NavigationInfoBarContent`.
 */
export const NavigationInfoBarContentLabel = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            color: tokens.styles['navigation-infobar-content-label-color'],
            fontSize: tokens.styles['navigation-infobar-content-label-fontSize'],
            fontFamily: tokens.styles['navigation-infobar-content-label-fontFamily'],
            lineHeight: tokens.styles['navigation-infobar-content-label-lineHeight'],
            fontWeight: tokens.styles['navigation-infobar-content-label-fontWeight'],
            letterSpacing: tokens.styles['navigation-infobar-content-label-letterSpacing'],
        }, ...props, ref: ref }));
});
NavigationInfoBarContentLabel.displayName = 'NavigationInfoBarContentLabel';
