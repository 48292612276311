const getIcon = (color, size) => encodeURIComponent(`<svg viewBox="0 0 ${size * 2} ${size * 2}" xmlns="http://www.w3.org/2000/svg">
      <circle cx="${size}" cy="${size}" r="${size / 2}" fill="${color}" />
    </svg>`);
export const radioButton = (tokens) => () => {
    return [
        {
            appearance: 'none',
            flexShrink: '0',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            width: tokens.styles['radio-base-unselected-initial-width'],
            height: tokens.styles['radio-base-unselected-initial-height'],
            borderStyle: 'solid',
            borderRadius: tokens.styles['radio-base-unselected-initial-borderRadius'],
            borderWidth: tokens.styles['radio-base-unselected-initial-borderWidth'],
            borderColor: tokens.styles['radio-base-unselected-initial-borderColor'],
            backgroundColor: tokens.styles['radio-base-unselected-initial-background'],
            cursor: 'pointer',
            margin: 0,
            transitionDuration: tokens.styles['interaction-hover-duration'],
            transitionTimingFunction: tokens.styles['interaction-hover-timing'],
            transitionProperty: 'background-color, border-color',
            opacity: tokens.styles['radio-base-unselected-initial-opacity'],
            ':hover': {
                borderColor: tokens.styles['radio-base-unselected-hover-borderColor'],
                backgroundColor: tokens.styles['radio-base-unselected-hover-background'],
                borderRadius: tokens.styles['radio-base-unselected-hover-borderRadius'],
                borderWidth: tokens.styles['radio-base-unselected-hover-borderWidth'],
                width: tokens.styles['radio-base-unselected-hover-width'],
                height: tokens.styles['radio-base-unselected-hover-height'],
                opacity: tokens.styles['radio-base-unselected-hover-opacity'],
            },
            ':checked': {
                width: tokens.styles['radio-base-selected-initial-width'],
                height: tokens.styles['radio-base-selected-initial-height'],
                borderColor: tokens.styles['radio-base-selected-initial-borderColor'],
                borderRadius: tokens.styles['radio-base-selected-initial-borderRadius'],
                borderWidth: tokens.styles['radio-base-selected-initial-borderWidth'],
                backgroundColor: tokens.styles['radio-base-selected-initial-background'],
                backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['radio-icon-selected-initial-background'], parseInt(tokens.fontSize.lg))}')`,
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                opacity: tokens.styles['radio-base-selected-initial-opacity'],
                ':hover': {
                    borderColor: tokens.styles['radio-base-selected-hover-borderColor'],
                    backgroundColor: tokens.styles['radio-base-selected-hover-background'],
                    borderRadius: tokens.styles['radio-base-selected-hover-borderRadius'],
                    borderWidth: tokens.styles['radio-base-selected-hover-borderWidth'],
                    width: tokens.styles['radio-base-selected-hover-width'],
                    height: tokens.styles['radio-base-selected-hover-height'],
                    opacity: tokens.styles['radio-base-selected-hover-opacity'],
                    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['radio-icon-selected-hover-background'], parseInt(tokens.fontSize.lg))}')`,
                },
                ':active': {
                    borderColor: tokens.styles['radio-base-selected-pressed-borderColor'],
                    backgroundColor: tokens.styles['radio-base-selected-pressed-background'],
                    borderRadius: tokens.styles['radio-base-selected-pressed-borderRadius'],
                    borderWidth: tokens.styles['radio-base-selected-pressed-borderWidth'],
                    width: tokens.styles['radio-base-selected-pressed-width'],
                    height: tokens.styles['radio-base-selected-pressed-height'],
                    opacity: tokens.styles['radio-base-selected-pressed-opacity'],
                    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['radio-icon-selected-pressed-background'], parseInt(tokens.fontSize.lg))}')`,
                },
                ':disabled': {
                    borderColor: tokens.styles['radio-base-selected-disabled-borderColor'],
                    backgroundColor: tokens.styles['radio-base-selected-disabled-background'],
                    borderRadius: tokens.styles['radio-base-selected-disabled-borderRadius'],
                    borderWidth: tokens.styles['radio-base-selected-disabled-borderWidth'],
                    width: tokens.styles['radio-base-selected-disabled-width'],
                    height: tokens.styles['radio-base-selected-disabled-height'],
                    opacity: tokens.styles['radio-base-selected-disabled-opacity'],
                    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['radio-icon-selected-disabled-background'], parseInt(tokens.fontSize.lg))}')`,
                },
            },
            ':active': {
                borderColor: tokens.styles['radio-base-unselected-pressed-borderColor'],
                backgroundColor: tokens.styles['radio-base-unselected-pressed-background'],
                borderRadius: tokens.styles['radio-base-unselected-pressed-borderRadius'],
                borderWidth: tokens.styles['radio-base-unselected-pressed-borderWidth'],
                width: tokens.styles['radio-base-unselected-pressed-width'],
                height: tokens.styles['radio-base-unselected-pressed-height'],
                opacity: tokens.styles['radio-base-unselected-pressed-opacity'],
                transitionDuration: tokens.styles['interaction-pressed-duration'],
                transitionTimingFunction: tokens.styles['interaction-pressed-timing'],
            },
            ':disabled': {
                borderColor: tokens.styles['radio-base-unselected-disabled-borderColor'],
                backgroundColor: tokens.styles['radio-base-unselected-disabled-background'],
                borderRadius: tokens.styles['radio-base-unselected-disabled-borderRadius'],
                borderWidth: tokens.styles['radio-base-unselected-disabled-borderWidth'],
                width: tokens.styles['radio-base-unselected-disabled-width'],
                height: tokens.styles['radio-base-unselected-disabled-height'],
                opacity: tokens.styles['radio-base-unselected-disabled-opacity'],
            },
        },
    ];
};
