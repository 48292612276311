import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { combineResponsiveValues } from '../../component-themes';
import { getResponsiveStyles } from '../../component-themes';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { price } from './styles/price';
const energyLabelSpaceMap = {
    '2xs': 'small-sm',
    xs: 'small-sm',
    sm: 'small-sm',
    md: 'small-md',
    lg: 'small-md',
};
/**
 * Can be used to display all price related information.
 */
export const Price = forwardRef(({ size, variant, alignment, badge, energyLabel, main, offerline, strikethrough, subline, orientation = 'vertical', ...props }, ref) => {
    const tokens = useTokens();
    const styles = price(tokens)({ size, variant, alignment });
    return (_jsxs(Stack, { align: alignment, direction: mapResponsiveValue(combineResponsiveValues(alignment, orientation), ([alignment, orientation]) => orientation === 'vertical'
            ? 'vertical'
            : alignment === 'left'
                ? 'horizontal'
                : 'horizontal-reverse'), justify: mapResponsiveValue(orientation, (orientation) => orientation === 'horizontal'
            ? energyLabel
                ? 'space-between'
                : alignment === 'left'
                    ? 'start'
                    : 'end'
            : 'flex-start'), spacing: mapResponsiveValue(size, (size) => energyLabelSpaceMap[size]), ...props, ref: ref, children: [_jsxs(Stack, { direction: "vertical", spacing: "small-xxs", css: getResponsiveStyles(alignment, (alignment) => ({
                    textAlign: alignment,
                })), children: [_jsxs("div", { css: styles.priceInformation, children: [main?.({ size }), (strikethrough || badge) && (_jsxs("div", { css: styles.discountInformation, children: [strikethrough?.({ size }), badge?.({})] }))] }), (offerline || subline) && (_jsxs(Stack, { align: mapResponsiveValue(alignment, (alignment) => alignment === 'left' ? 'start' : 'end'), direction: "vertical", children: [offerline?.({ size }), subline?.({ size })] }))] }), energyLabel?.({
                size,
                alignment: mapResponsiveValue(combineResponsiveValues(alignment, orientation), ([alignment, orientation]) => orientation === 'vertical'
                    ? alignment
                    : alignment === 'left'
                        ? 'right'
                        : 'left'),
            })] }));
});
Price.displayName = 'Price';
