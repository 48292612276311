import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBarBrandAreaTagline } from './styles/navigation-bar-brand-area-tagline';
/**
 * Should be used to display a brand's tagline within the navigation.
 */
export const NavigationBarBrandAreaTagline = forwardRef(({ size, onBackground, as: Component = 'span', ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarBrandAreaTagline(tokens)({ size, onBackground });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
NavigationBarBrandAreaTagline.displayName = 'NavigationBarBrandAreaTagline';
