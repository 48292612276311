import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useActiveState, useHoveredState } from '../../component-hooks';
import { Stack } from '../../component-stack';
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display the individual steps within `ProgressStepsStepList`.
 */
export const ProgressStepsStepListStep = forwardRef(({ active, completed, size, variant, indicator, label, ...props }, ref) => {
    const { onPointerUp, onPointerDown, onPointerEnter, onPointerLeave } = props;
    const { active: pressed, ...activeProps } = useActiveState({
        onPointerUp,
        onPointerDown,
    });
    const { hovered, ...hoveredProps } = useHoveredState({
        onPointerEnter,
        onPointerLeave,
    });
    const state = pressed ? 'pressed' : hovered ? 'hover' : 'initial';
    const tokens = useTokens();
    return (_jsxs(Stack, { align: "center", css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles['progressSteps-stepList-step-base-gap'],
            flex: size === 'sm' && active ? 1 : 'none',
            justifySelf: size === 'sm' && !active ? 'flex-end' : 'auto',
            ':hover': {
                cursor: 'pointer',
            },
        })), ...activeProps, ...hoveredProps, ...props, ref: ref, children: [indicator?.({ state, active: active || completed }), _jsx("div", { css: getResponsiveStyles(size, (size) => ({
                    display: size === 'lg' || (size === 'sm' && active && !completed)
                        ? 'block'
                        : 'none',
                })), children: label?.({ state, active, variant }) })] }));
});
ProgressStepsStepListStep.displayName = 'ProgressStepsStepListStep';
