import { mergeStyles } from '../../../component-themes';
export const paginationBullet = (tokens) => ({ state, variant, visible, }) => {
    const width = parseFloat(tokens.styles[`pagination-${variant}-${state}-width`]) * 16;
    const height = parseFloat(tokens.styles[`pagination-${variant}-${state}-height`]) * 16;
    const gap = parseFloat(tokens.styles['pagination-base-gap']) * 16;
    return mergeStyles([
        {
            backgroundColor: tokens.styles[`pagination-${variant}-${state}-background`],
            borderRadius: height === width
                ? '100%'
                : tokens.styles[`pagination-${variant}-${state}-borderRadius`],
            height,
            width: width + gap,
            opacity: tokens.styles[`pagination-${variant}-${state}-opacity`],
            cursor: 'pointer',
            pointerEvents: 'auto',
            boxSizing: 'border-box',
            backgroundClip: 'content-box',
            flexBasis: visible ? 'auto' : 0,
            paddingInline: visible ? `${gap / 2}px` : 0,
        },
    ]);
};
