import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { combineResponsiveValues, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
import { contentTeaser } from './styles/content-teaser';
export const ContentTeaser = forwardRef(({ as: Component = 'div', alignment, size, variant, orientation, content, image, ...props }, ref) => {
    const tokens = useTokens();
    const styles = contentTeaser(tokens)({
        size,
        alignment,
        orientation,
    });
    const wrapperSizeMap = {
        sm: 'small-md',
        md: 'small-lg',
        lg: 'small-lg',
        xl: 'small-xl',
    };
    const contentSizeMap = {
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'lg',
    };
    return (_jsx(Component, { css: { position: 'relative' }, ...props, ref: ref, children: _jsxs(Stack, { spacing: mapResponsiveValue(combineResponsiveValues(size, orientation), ([size, orientation]) => orientation === 'landscape' ? wrapperSizeMap[size] : undefined), direction: mapResponsiveValue(combineResponsiveValues(alignment, orientation), ([alignment, orientation]) => orientation === 'portrait'
                ? alignment === 'finish'
                    ? 'vertical-reverse'
                    : 'vertical'
                : 'horizontal'), children: [image({ orientation }), _jsx("div", { css: styles, children: content({
                        size: mapResponsiveValue(combineResponsiveValues(size, orientation), ([size, orientation]) => orientation === 'landscape' ? size : contentSizeMap[size]),
                        variant,
                    }) })] }) }));
});
ContentTeaser.displayName = 'ContentTeaser';
