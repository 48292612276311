import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `OverlayCardContent` should be used to display the content within the `OverlayCard`.
 */
export const OverlayCardContent = forwardRef(({ as: Component = 'div', size, fullwidth = false, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            paddingInline: fullwidth
                ? undefined
                : size === 'sm'
                    ? tokens.space['small-xl']
                    : tokens.space['small-2xl'],
            paddingBlockEnd: size === 'sm'
                ? tokens.space['small-xl']
                : tokens.space['small-2xl'],
        })), ...props, ref: ref }));
});
OverlayCardContent.displayName = 'OverlayCardContent';
