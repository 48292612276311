import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const FormFieldMessageCount = forwardRef(({ as: Component = 'div', variant, ...props }, ref) => {
    const errorState = props.invalid ? 'error' : 'resting';
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            color: tokens.styles[`formField-message-count-${variant}-${errorState}-color`],
            fontFamily: tokens.styles[`formField-message-count-${variant}-${errorState}-fontFamily`],
            fontSize: tokens.styles[`formField-message-count-${variant}-${errorState}-fontSize`],
            fontWeight: tokens.styles[`formField-message-count-${variant}-${errorState}-fontWeight`],
            letterSpacing: tokens.styles[`formField-message-count-${variant}-${errorState}-letterSpacing`],
            lineHeight: tokens.styles[`formField-message-count-${variant}-${errorState}-lineHeight`],
            opacity: tokens.styles[`formField-message-count-${variant}-${errorState}-opacity`],
        }, ...props, ref: ref }));
});
FormFieldMessageCount.displayName = 'FormFieldMessageCount';
