import { getResponsiveStyles } from '../../../component-themes';
export const logoBase = (tokens) => ({ size }) => {
    const widthMap = {
        sm: tokens.styles['logo-width-sm'],
        md: tokens.styles['logo-width-md'],
        lg: tokens.styles['logo-width-lg'],
    };
    return {
        height: 'auto',
        width: widthMap[size],
    };
};
export const logo = (tokens) => ({ size }) => getResponsiveStyles(size, (size) => logoBase(tokens)({ size }));
