import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { ChevronLeft } from '../../component-icons';
import { Link } from '../../component-link';
import { Stack } from '../../component-stack';
import { forwardRef } from 'react';
/**
 * Should be used to render the back link, for example on mobile devices. It supports all `Link` props.
 */
export const BreadcrumbBackLink = forwardRef(({ children, ...props }, ref) => {
    return (_jsx(Link, { size: "md", variant: "neutral", ref: ref, ...props, children: _jsxs(Stack, { align: "center", spacing: "small-xs", children: [_jsx(Icon, { as: ChevronLeft, size: "sm" }), children] }) }));
});
BreadcrumbBackLink.displayName = 'BreadcrumbBackLink';
