import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `GalleryContentSliderSlide` component is used to display an individual slide within the slider.
 */
export const GalleryContentSliderSlide = forwardRef(({ media, caption, size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: "vertical", css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`gallery-content-slider-slide-base-${size}-gap`],
            boxSizing: 'border-box',
            width: '100%',
            height: 'auto',
            minHeight: 0,
        })), ...props, ref: ref, children: [media({}), caption?.({})] }));
});
GalleryContentSliderSlide.displayName = 'GalleryContentSliderSlide';
