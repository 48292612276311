import { createContext } from 'react';
const outsideOfContextErrorMessage = 'Rendered outside of a InputContext.Provider';
/**
 * The `InputContext` can be used to provide the state of an input field to its children.
 * It also provides the nested input element to the parent `Input` component for focus handling.
 */
export const InputContext = createContext({
    setFocused: () => {
        throw new Error(outsideOfContextErrorMessage);
    },
    setFilled: () => {
        throw new Error(outsideOfContextErrorMessage);
    },
    setHovered: () => {
        throw new Error(outsideOfContextErrorMessage);
    },
});
