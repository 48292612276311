import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Is used to display a list of legal links within the `Footer`.
 */
export const FooterLegalLinkList = forwardRef(({ links, size, ...props }, ref) => {
    return (_jsx(Stack, { spacing: "small-lg", direction: mapResponsiveValue(size, (size) => size === 'sm' ? 'vertical' : 'horizontal'), align: mapResponsiveValue(size, (size) => size === 'lg' ? 'flex-start' : 'center'), justify: mapResponsiveValue(size, (size) => size === 'lg' ? 'flex-start' : 'center'), style: { textAlign: 'center' }, ...props, ref: ref, children: links.map((link) => link({})) }));
});
FooterLegalLinkList.displayName = 'FooterLegalLinkList';
