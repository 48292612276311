import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { badgeIcon } from './styles/badge-icon';
/**
 * A small label, generally appearing inside or in close proximity to another larger interface component, representing a status, property, or some other metadata
 */
export const BadgeIcon = forwardRef(({ as: Component = 'div', size, variant, icon, ...props }, ref) => {
    const tokens = useTokens();
    const styles = badgeIcon(tokens)({ size, variant });
    return (_jsx(Component, { css: styles, ...props, ref: ref, children: icon({ variant, size }) }));
});
BadgeIcon.displayName = 'BadgeIcon';
