import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { checkbox } from './styles/checkbox';
export const Checkbox = forwardRef(({ as: Component = 'input', ...props }, ref) => {
    const tokens = useTokens();
    const styles = checkbox(tokens)();
    return _jsx(Component, { type: "checkbox", css: styles, ...props, ref: ref });
});
Checkbox.displayName = 'Checkbox';
