import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Global } from '@emotion/react';
import balayFonts from './balay';
import boschFonts from './bosch';
import gaggenauFonts from './gaggenau';
import neffFonts from './neff';
import siemensFonts from './siemens';
export { balayFonts, boschFonts, gaggenauFonts, neffFonts, siemensFonts };
// Global Font Face Styles
export const BalayFonts = () => _jsx(GlobalFontFaceStyles, { fonts: balayFonts });
export const BoschFonts = () => _jsx(GlobalFontFaceStyles, { fonts: boschFonts });
export const GaggenauFonts = () => (_jsx(GlobalFontFaceStyles, { fonts: gaggenauFonts }));
export const NeffFonts = () => _jsx(GlobalFontFaceStyles, { fonts: neffFonts });
export const SiemensFonts = () => _jsx(GlobalFontFaceStyles, { fonts: siemensFonts });
// Woff2 Font Preloads
export const PreloadBalayFonts = () => _jsx(Woff2FontPreloads, { fonts: balayFonts });
export const PreloadBoschFonts = () => _jsx(Woff2FontPreloads, { fonts: boschFonts });
export const PreloadGaggenauFonts = () => (_jsx(Woff2FontPreloads, { fonts: gaggenauFonts }));
export const PreloadNeffFonts = () => _jsx(Woff2FontPreloads, { fonts: neffFonts });
export const PreloadSiemensFonts = () => (_jsx(Woff2FontPreloads, { fonts: siemensFonts }));
// Helpers
function Woff2FontPreloads({ fonts }) {
    return (_jsx(_Fragment, { children: fonts.map((font) => (_jsx("link", { rel: "preload", href: font.woff2, as: "font", type: "font/woff2", crossOrigin: "anonymous" }, font.woff2))) }));
}
function GlobalFontFaceStyles({ fonts }) {
    return (_jsx(Global, { styles: fonts
            .map((font) => `
            @font-face {
              font-family: '${font.family}';
              src: url(${font.woff2}) format('woff2'),
                   url(${font.woff}) format('woff'),
                   url(${font.ttf}) format('truetype');
              font-weight: ${font.weight};
              font-style: ${font.style};
              font-display: swap;
            }
          `)
            .join('') }));
}
