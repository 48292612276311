import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { floatingCardArrow } from './styles/floating-card-arrow';
/**
 * Can be used to add an arrow pointing to a target element.
 */
export const FloatingCardArrow = forwardRef(({ direction, variant, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = floatingCardArrow(tokens)({
        direction,
        variant,
    });
    return (_jsx(Component, { css: styles.outer, ...props, ref: ref, children: _jsx("div", { css: styles.inner }) }));
});
FloatingCardArrow.displayName = 'FloatingCardArrow';
