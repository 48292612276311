import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const toggleBase = (tokens) => ({ size, variant, }) => mergeStyles([
    {
        appearance: 'none',
        position: 'relative',
        height: `calc(${tokens.styles[`toggle-base-${size}-${variant}-default-paddingTop`]} + ${tokens.styles[`toggle-knob-${size}-${variant}-default-height`]} + ${tokens.styles[`toggle-base-${size}-${variant}-default-paddingBottom`]})`,
        width: tokens.styles[`toggle-base-${size}-${variant}-default-width`],
        visibility: 'hidden',
        '::before': {
            content: '""',
            position: 'absolute',
            display: 'block',
            visibility: 'visible',
            cursor: 'pointer',
            backgroundColor: tokens.styles[`toggle-base-${size}-${variant}-default-background`],
            width: tokens.styles[`toggle-base-${size}-${variant}-default-width`],
            height: `calc(${tokens.styles[`toggle-knob-${size}-${variant}-default-height`]}
                + ${tokens.styles[`toggle-base-${size}-${variant}-default-paddingTop`]}
                + ${tokens.styles[`toggle-base-${size}-${variant}-default-paddingBottom`]})`,
            borderRadius: tokens.styles[`toggle-base-${size}-${variant}-default-borderRadius`],
            transition: `all 2400ms ${tokens.animation['timing-soft']}`,
            ':hover': {
                backgroundColor: tokens.styles[`toggle-base-${size}-${variant}-hover-background`],
            },
            ':active': {
                backgroundColor: tokens.styles[`toggle-base-${size}-${variant}-pressed-background`],
            },
        },
        '::after': {
            cursor: 'pointer',
            content: '""',
            position: 'absolute',
            visibility: 'visible',
            borderRadius: tokens.styles[`toggle-knob-${size}-${variant}-default-borderRadius`],
            width: tokens.styles[`toggle-knob-${size}-${variant}-default-width`],
            height: tokens.styles[`toggle-knob-${size}-${variant}-default-height`],
            opacity: variant === 'inactive'
                ? tokens.styles[`toggle-knob-${size}-${variant}-default-opacity`]
                : 1,
            backgroundColor: tokens.styles[`toggle-knob-${size}-${variant}-default-background`],
            insetBlockStart: tokens.styles[`toggle-base-${size}-${variant}-default-paddingTop`],
            insetInlineEnd: variant === 'active'
                ? tokens.styles[`toggle-base-${size}-${variant}-default-paddingLeft`]
                : `calc(${tokens.styles[`toggle-base-${size}-${variant}-default-width`]}
                    - ${tokens.styles[`toggle-knob-${size}-${variant}-default-width`]}
                    - ${tokens.styles[`toggle-base-${size}-${variant}-default-paddingLeft`]})`,
            transition: `all ${tokens.animation['duration-slow']} ${tokens.animation['timing-soft']}`,
        },
    },
]);
export const toggle = (tokens) => ({ size, variant, }) => getResponsiveStyles(size, (size) => toggleBase(tokens)({ size, variant }));
