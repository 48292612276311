import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { overlayCardHeaderHeadline } from './styles/overlayCardHeaderHeadline';
/**
 * The `OverlayCardHeaderHeadline` component should be used to display the headline within a `OverlayCardHeaderWithHeadline`.
 */
export const OverlayCardHeaderHeadline = forwardRef(({ as: Component = 'h1', size, ...props }, ref) => {
    const tokens = useTokens();
    const styles = overlayCardHeaderHeadline(tokens)({ size });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
OverlayCardHeaderHeadline.displayName = 'OverlayCardHeaderHeadline';
