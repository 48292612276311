import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const badgeBase = (tokens) => ({ variant, size, maxChar, }) => {
    return {
        badge: mergeStyles([
            {
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: tokens.styles[`badge-${variant}-base-${size}-background`],
                height: tokens.styles[`badge-${variant}-base-${size}-height`],
                paddingInlineStart: tokens.styles[`badge-${variant}-base-${size}-paddingLeft`],
                paddingInlineEnd: tokens.styles[`badge-${variant}-base-${size}-paddingRight`],
                borderRadius: tokens.styles[`badge-${variant}-base-${size}-borderRadius`],
            },
        ]),
        text: mergeStyles([
            {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: maxChar ? `${maxChar}ch` : 'none',
                color: tokens.styles[`badge-${variant}-text-${size}-color`],
                fontSize: tokens.styles[`badge-${variant}-text-${size}-fontSize`],
                fontWeight: tokens.styles[`badge-${variant}-text-${size}-fontWeight`],
                fontFamily: tokens.styles[`badge-${variant}-text-${size}-fontFamily`],
                lineHeight: tokens.styles[`badge-${variant}-text-${size}-lineHeight`],
                letterSpacing: tokens.styles[`badge-${variant}-text-${size}-letterSpacing`],
            },
            variant === 'subtle'
                ? {
                    opacity: tokens.styles[`badge-subtle-text-${size}-opacity`],
                }
                : {},
        ]),
    };
};
export const badge = (tokens) => ({ variant, size, maxChar, }) => ({
    badge: getResponsiveStyles(size, (size) => badgeBase(tokens)({ size, variant, maxChar }).badge),
    text: getResponsiveStyles(size, (size) => badgeBase(tokens)({ size, variant, maxChar }).text),
});
