import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const tabBarBase = (tokens) => ({ size, variant, }) => {
    return {
        bar: mergeStyles([
            {
                display: 'flex',
                position: 'relative',
                backgroundColor: variant !== 'image'
                    ? tokens.styles[`tabBar-base-${variant}-${size}-background`]
                    : 'inherit',
                height: tokens.styles[`tabBar-base-${variant}-${size}-height`],
                borderRadius: tokens.radii.xs,
            },
        ]),
        line: mergeStyles([
            {
                display: 'block',
                position: 'absolute',
                insetInlineStart: 0,
                insetInlineEnd: 0,
                insetBlockEnd: 0,
                background: tokens.styles[`tabBar-base-${variant}-${size}-borderColor`],
                height: tokens.styles[`tabBar-base-${variant}-${size}-borderBottomWidth`],
            },
        ]),
        leftArrow: mergeStyles([
            {
                position: 'absolute',
                insetBlockStart: 0,
                insetInlineStart: 0,
                insetBlockEnd: 0,
                zIndex: 1,
                display: 'flex',
            },
        ]),
        rightArrow: mergeStyles([
            {
                position: 'absolute',
                insetBlockStart: 0,
                insetInlineEnd: 0,
                insetBlockEnd: 0,
                zIndex: 1,
                display: 'flex',
            },
        ]),
    };
};
export const tabBar = (tokens) => ({ size, variant, }) => {
    return {
        bar: getResponsiveStyles(size, (size) => tabBarBase(tokens)({ size, variant }).bar),
        line: getResponsiveStyles(size, (size) => tabBarBase(tokens)({ size, variant }).line),
        leftArrow: getResponsiveStyles(size, (size) => tabBarBase(tokens)({ size, variant }).leftArrow),
        rightArrow: getResponsiveStyles(size, (size) => tabBarBase(tokens)({ size, variant }).rightArrow),
    };
};
