import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Motion } from '../../component-motion';
import { forwardRef } from 'react';
/**
 * Component to display the content of the `OrderSummary` in a scrollable wrapper. Should be wrapped about the `OrderSummaryContent` in the floating variant.
 */
export const OrderSummaryScrollableContentWrapper = forwardRef(({ size, variant, expanded, content, ...props }, ref) => {
    return (
    /* @ts-expect-error ignore onAnimationStart */
    _jsx(Motion, { css: {
            overflowY: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
                display: 'none',
            },
        }, initial: false, animate: {
            height: expanded ? 'auto' : 0,
        }, transition: {
            type: 'spring',
            stiffness: 160,
            damping: 20,
            mass: 1,
        }, ...props, ref: ref, children: content({
            size,
            variant,
        }) }));
});
OrderSummaryScrollableContentWrapper.displayName =
    'OrderSummaryScrollableContentWrapper';
