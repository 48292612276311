import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display the progress steps, for example in a checkout process.
 */
export const ProgressSteps = forwardRef(({ size, variant, stepsList, progressBar, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: "vertical", css: { gap: tokens.styles['progressSteps-base-gap'] }, ...props, ref: ref, children: [stepsList?.({ size, variant }), _jsx("div", { css: getResponsiveStyles(size, (size) => ({
                    display: size === 'sm' ? 'none' : 'block',
                })), children: progressBar({}) })] }));
});
ProgressSteps.displayName = 'ProgressSteps';
