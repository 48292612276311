import { CSSObject } from '@emotion/styled';
import { getResponsiveStyles as sherpaGetResponsiveStyles } from '../../deps/@bshgroup/component-themes';
import { breakpoints } from '../global/global-config';

export type Orientation = 'auto' | 'vertical' | 'horizontal';
export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const breakpointKeys: Breakpoint[] = ['sm', 'md', 'lg', 'xl', 'xxl'];

export default class AutoOrientationService {
  static getStyles(
    verticalStyles: CSSObject,
    horizontalStyles: CSSObject,
    orientation: Orientation = 'auto',
  ): CSSObject {
    return orientation === 'auto'
      ? sherpaGetResponsiveStyles(
          {
            [breakpoints.sm]: 'sm',
            [breakpoints.md]: 'lg',
          },
          size => (size === 'sm' ? verticalStyles : horizontalStyles),
        )
      : orientation === 'vertical'
        ? verticalStyles
        : horizontalStyles;
  }

  static breakpointStyles(breakpoint: Breakpoint, smallStyles: CSSObject, largeStyles: CSSObject): CSSObject {
    return sherpaGetResponsiveStyles(
      {
        [breakpoints.sm]: 'sm',
        [breakpoints[breakpoint]]: 'lg',
      },
      size => (size === 'sm' ? smallStyles : largeStyles),
    );
  }

  static multiBreakpointStyles(styles: Partial<{ [breakpoint in Breakpoint]: CSSObject }>): CSSObject {
    return sherpaGetResponsiveStyles(
      Object.fromEntries(breakpointKeys.map(e => [breakpoints[e], e])),
      size =>
        breakpointKeys
          .slice(0, breakpointKeys.indexOf(size) + 1)
          .map(e => styles[e])
          .at(-1) ?? {},
    );
  }
}
