import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { forwardRef } from 'react';
/**
 * Should be used in the details slot of `SelectListItem` when a label should be displayed.
 */
export const FormControlLabel = forwardRef(({ text, icon, disabled, ...props }, ref) => {
    return (_jsxs(Stack, { spacing: "small-xxs", align: "center", ...props, ref: ref, children: [text({ disabled }), icon?.({ disabled })] }));
});
FormControlLabel.displayName = 'FormControlLabel';
