import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display the icon of the `InputIndicatorCountrySelector` component.
 */
export const InputIndicatorCountrySelectorIcon = forwardRef(({ state, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Icon, { size: "sm", css: {
            color: tokens.styles[`input-indicator-countrySelector-icon-${state}-background`],
            opacity: tokens.styles[`input-indicator-countrySelector-icon-${state}-opacity`],
            transition: state === 'pressed'
                ? `all ${tokens.styles[`interaction-pressed-duration`]} ${tokens.styles[`interaction-pressed-timing`]}`
                : `all ${tokens.styles[`interaction-hover-duration`]} ${tokens.styles[`interaction-hover-timing`]}`,
        }, ...props, ref: ref }));
});
InputIndicatorCountrySelectorIcon.displayName =
    'InputIndicatorCountrySelectorIcon';
