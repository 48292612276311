import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const sizeMap = {
    '2xs': 'sm',
    xs: 'sm',
    sm: 'lg',
    md: 'lg',
    lg: 'lg',
};
/**
 * Can be used to display the energy label of a product. It shows an image of the energy label together with a link and description.
 */
export const EnergyLabel = forwardRef(({ size, alignment, description, classification, label, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: "vertical", css: getResponsiveStyles(alignment, (alignment) => ({
            alignItems: alignment === 'left' ? 'flex-start' : 'flex-end',
            gap: tokens.styles['energylabel-base-gap'],
        })), ...props, ref: ref, children: [description?.({
                size: mapResponsiveValue(size, (size) => sizeMap[size]),
            }), classification({ size, alignment }), label?.({
                size: mapResponsiveValue(size, (size) => sizeMap[size]),
            })] }));
});
EnergyLabel.displayName = 'EnergyLabel';
