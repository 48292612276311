import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used when a countdown is displayed within `NavigationInfoBarContent`.
 */
export const NavigationInfoBarContentCountdown = forwardRef(({ items, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { css: {
            gap: tokens.styles['navigation-infobar-content-countdown-base-gap'],
        }, ...props, ref: ref, children: items({}) }));
});
NavigationInfoBarContentCountdown.displayName =
    'NavigationInfoBarContentCountdown';
