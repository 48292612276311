import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * `GalleryContentSliderSlideCaption` component is used to display a caption within `GalleryContentSliderSlide`.
 */
export const GalleryContentSliderSlideCaption = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            color: tokens.styles['gallery-content-slider-slide-caption-color'],
            fontSize: tokens.styles['gallery-content-slider-slide-caption-fontSize'],
            fontFamily: tokens.styles['gallery-content-slider-slide-caption-fontFamily'],
            lineHeight: tokens.styles['gallery-content-slider-slide-caption-lineHeight'],
            fontWeight: tokens.styles['gallery-content-slider-slide-caption-fontWeight'],
            letterSpacing: tokens.styles['gallery-content-slider-slide-caption-letterSpacing'],
            textAlign: 'center',
        }, ...props, ref: ref }));
});
GalleryContentSliderSlideCaption.displayName =
    'GalleryContentSliderSlideCaption';
