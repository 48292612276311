import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display an item containing a number and a label within `NavigationInfoBarContentCountdown`.
 */
export const NavigationInfoBarContentCountdownItem = forwardRef(({ number, label, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { css: {
            gap: tokens.styles['navigation-infobar-content-countdown-item-base-gap'],
        }, ...props, ref: ref, children: [number({}), label?.({})] }));
});
NavigationInfoBarContentCountdownItem.displayName =
    'NavigationInfoBarContentCountdownItem';
