import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Heading } from '../../component-heading';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
const headingMap = {
    sm: 'small-4',
    md: 'small-3',
    lg: 'large-4',
};
const styleMap = {
    sm: { textAlign: 'center' },
    md: { textAlign: 'center' },
    lg: { textAlign: 'start' },
};
/**
 * Used to display the headline on top of the `Footer`.
 */
export const FooterHeadline = forwardRef(({ size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Heading, { as: "h3", size: mapResponsiveValue(size, (size) => headingMap[size]), css: [
            getResponsiveStyles(size, (size) => styleMap[size]),
            { color: tokens.styles['footer-tagline-background'] },
        ], ...props, ref: ref }));
});
FooterHeadline.displayName = 'FooterHeadline';
