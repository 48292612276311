import { combineResponsiveValues, getResponsiveStyles, mergeStyles, } from '../../../component-themes';
export const featureListBase = (tokens) => ({ size, variant, gridSize, noOfItems, }) => {
    return {
        item: mergeStyles([
            {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            },
        ]),
        divider: (index) => {
            return index >=
                noOfItems - (noOfItems % Number(gridSize) || Number(gridSize))
                ? {
                    display: 'none',
                }
                : {
                    color: tokens.styles[`featureList-feature-base-${variant}-divided-${size}-borderColor`],
                    marginBlockStart: size === 'sm'
                        ? tokens.space['small-sm']
                        : tokens.space['small-md'],
                };
        },
        list: mergeStyles([
            {
                display: 'grid',
            },
            gridSize
                ? {
                    gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
                }
                : {},
            size === 'sm'
                ? { gap: tokens.space['small-md'] }
                : { gap: tokens.space['small-lg'] },
        ]),
    };
};
export const featureList = (tokens) => ({ size, variant, gridSize, noOfItems, }) => {
    return {
        list: getResponsiveStyles(combineResponsiveValues(size, gridSize), ([size, gridSize]) => featureListBase(tokens)({ size, gridSize, noOfItems, variant }).list),
        item: getResponsiveStyles(combineResponsiveValues(size, gridSize), ([size, gridSize]) => featureListBase(tokens)({ size, gridSize, noOfItems, variant }).item),
        divider: (index) => getResponsiveStyles(combineResponsiveValues(size, gridSize), ([size, gridSize]) => featureListBase(tokens)({
            size,
            gridSize,
            noOfItems,
            variant,
        }).divider(index)),
    };
};
