import React, { ReactNode } from 'react';
import states from './states.json';
import { B2CUserRead } from '../../../types/fromOpenApi/B2CUserRead';
import { Text } from '../Text/Text';

const statesMap: { [id: string]: string } = Object.fromEntries(states.map(e => [e.id, e.displayName]));

export interface AddressProps {
  /**
   * DCS B2C user object
   */
  user?: B2CUserRead;
  /**
   * First name
   */
  firstName?: ReactNode;
  /**
   * Last name
   */
  lastName?: ReactNode;
  /**
   * Street
   */
  street?: ReactNode;
  /**
   * Second street line
   */
  additionalStreetInfo?: ReactNode;
  /**
   * House number if not included in street
   */
  houseNumber?: ReactNode;
  /**
   * Postal code
   */
  postalCode?: ReactNode;
  /**
   * city
   */
  city?: ReactNode;
  /**
   * region
   */
  region?: ReactNode;
  /**
   * state, codes like US-NY will be mapped automatically
   */
  state?: ReactNode;
  /**
   * country
   */
  country?: ReactNode;
}

/**
 * The `Address` displays a postal address.
 */
export function Address({
  user,
  firstName,
  lastName,
  street,
  additionalStreetInfo,
  houseNumber,
  postalCode,
  city,
  region,
  state,
  country,
  ...props
}: AddressProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof AddressProps>) {
  firstName = firstName || user?.firstName;
  lastName = lastName || user?.lastName;
  street = street || user?.address?.street;
  additionalStreetInfo = additionalStreetInfo || user?.address?.additionalStreetInfo;
  houseNumber = houseNumber || user?.address?.houseNumber;
  postalCode = postalCode || user?.address?.postalCode;
  city = city || user?.address?.city;
  region = region || user?.address?.region;
  state = state || user?.address?.state;
  state = state && typeof state === 'string' && statesMap[state] ? statesMap[state] : state;
  country = country || user?.address?.country;

  return (
    <div {...props}>
      <Text>
        {firstName}
        {lastName && ` ${lastName}`}
        <br />
        {houseNumber && `${houseNumber} `}
        {street}
        <br />
        {additionalStreetInfo && (
          <>
            {additionalStreetInfo}
            <br />
          </>
        )}
        {`${city}, `}
        {state && `${state} `}
        {region && `${region} `}
        {postalCode}
        <br />
        {country}
      </Text>
    </div>
  );
}
