import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { useActiveState, useHoveredState } from '../../component-hooks';
import { ChevronDown, ChevronUp } from '../../component-icons';
import { forwardRef } from 'react';
export const ExpandAreaButton = forwardRef(({ as: Component = 'button', expanded, ...props }, ref) => {
    const { active, ...activeProps } = useActiveState(props);
    const { hovered, ...hoveredProps } = useHoveredState(props);
    return (_jsx(Component, { css: {
            width: '100%',
            border: 'none',
            padding: 'unset',
            cursor: 'pointer',
            backgroundColor: 'transparent',
        }, ...props, ...activeProps, ...hoveredProps, ref: ref, children: _jsx(ButtonAction, { as: "span", variant: "subtle", size: "sm", hovered: hovered, active: active, icon: !expanded ? ChevronDown : ChevronUp }) }));
});
ExpandAreaButton.displayName = 'ExpandAreaButton';
