import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { label } from './label.styles';
/**
 * The `Label` component should be used for highlighted, mostly clickable text components.
 */
export const Label = forwardRef(({ size, color, emphasis, as: Component = 'p', ...props }, ref) => {
    const tokens = useTokens();
    const styles = label(tokens)({ size, color, emphasis });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
Label.displayName = 'Label';
