import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { accordionItemButtonTitleSubline } from './styles/accordion-item-button-title-subline';
/**
 * Is used to display `AccordionItemButtonTitleSubline` for `AccordionItemButtonTitle`.
 */
export const AccordionItemButtonTitleSubline = forwardRef(({ as: Component = 'div', size, state, ...props }, ref) => {
    const tokens = useTokens();
    const styles = accordionItemButtonTitleSubline(tokens)({
        size,
        state,
    });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
AccordionItemButtonTitleSubline.displayName = 'AccordionItemButtonTitleSubline';
