import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { Check } from '../../component-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display the icon for `ProgressStepsStepListStepIndicator`.
 */
export const ProgressStepsStepListStepIndicatorIcon = forwardRef(({ state, active, ...props }, ref) => {
    const tokens = useTokens();
    const activeState = active ? 'active' : 'inactive';
    const interactionState = state === 'initial' ? 'hover' : 'pressed';
    return (_jsx(Icon, { css: {
            color: tokens.styles[`progressSteps-stepList-step-indicator-icon-${activeState}-${state}-background`],
            opacity: tokens.styles[`progressSteps-stepList-step-indicator-icon-${activeState}-${state}-opacity`],
            transition: `all ${tokens.styles[`interaction-${interactionState}-duration`]} ${tokens.styles[`interaction-${interactionState}-timing`]}`,
        }, size: "sm", as: Check, ...props, ref: ref }));
});
ProgressStepsStepListStepIndicatorIcon.displayName =
    'ProgressStepsStepListStepIndicatorIcon';
