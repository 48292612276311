import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const loadingSpinnerBase = (tokens) => ({ size, color, }) => {
    const offset = 62;
    const duration = 1.4;
    return {
        wrapper: mergeStyles([
            {
                display: 'flex',
                lineHeight: 1,
                '@keyframes rotation': {
                    '0%': { transform: 'rotate(0deg)' },
                    '100%': { transform: 'rotate(270deg)' },
                },
                ['@keyframes colors']: {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.8 },
                    '100%': { opacity: 1 },
                },
                ['@keyframes dash']: {
                    '0%': { 'stroke-dashoffset': offset },
                    '50%': {
                        'stroke-dashoffset': offset / 4,
                        transform: 'rotate(135deg)',
                    },
                    '100%': {
                        'stroke-dashoffset': offset,
                        transform: 'rotate(450deg)',
                    },
                },
            },
        ]),
        spinner: mergeStyles([
            {
                animation: `rotation ${duration}s linear infinite;`,
                width: tokens.fontSize[size],
                height: tokens.fontSize[size],
            },
        ]),
        path: mergeStyles([
            {
                'stroke-dasharray': offset,
                'stroke-dashoffset': 0,
                'transform-origin': 'center',
                stroke: color,
                animation: `dash ${duration}s ease-in-out infinite, colors ${duration * 2}s ease-in-out infinite`,
            },
        ]),
    };
};
export const loadingSpinner = (tokens) => ({ size, color, }) => ({
    wrapper: getResponsiveStyles(size, (size) => loadingSpinnerBase(tokens)({ size, color }).wrapper),
    spinner: getResponsiveStyles(size, (size) => loadingSpinnerBase(tokens)({ size, color }).spinner),
    path: getResponsiveStyles(size, (size) => loadingSpinnerBase(tokens)({ size, color }).path),
});
