import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display an item in `OrderSummaryContentDetailList`
 */
export const OrderSummaryContentDetailListItem = forwardRef(({ size, items, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { direction: "vertical", css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`orderSummary-content-detailList-item-base-${size}-gap`],
            borderRadius: tokens.styles[`orderSummary-content-detailList-item-base-${size}-borderRadius`],
            borderWidth: tokens.styles[`orderSummary-content-detailList-item-base-${size}-borderWidth`],
            borderStyle: tokens.styles[`orderSummary-content-detailList-item-base-${size}-borderStyle`],
            borderColor: tokens.styles[`orderSummary-content-detailList-item-base-${size}-borderColor`],
            paddingBlockEnd: tokens.styles[`orderSummary-content-detailList-item-base-${size}-paddingBottom`],
            paddingInlineEnd: tokens.styles[`orderSummary-content-detailList-item-base-${size}-paddingRight`],
            paddingInlineStart: tokens.styles[`orderSummary-content-detailList-item-base-${size}-paddingLeft`],
            paddingBlockStart: tokens.styles[`orderSummary-content-detailList-item-base-${size}-paddingTop`],
        })), ...props, ref: ref, children: items({ size }) }));
});
OrderSummaryContentDetailListItem.displayName =
    'OrderSummaryContentDetailListItem';
