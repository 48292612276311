import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { notificationCounterBlob } from './notification-counter-blob.styles';
/**
 * Is used to display the blob in the top right cornor of the `NotificationCounter`
 */
export const NotificationCounterBlob = forwardRef(({ variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = notificationCounterBlob(tokens)({ variant });
    return (_jsx(Text, { as: "div", size: "xs", variant: "default", css: styles, ...props, ref: ref }));
});
NotificationCounterBlob.displayName = 'NotificationCounterBlob';
