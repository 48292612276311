import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { getResponsiveStyles } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniServicesItemDescription = forwardRef(({ size, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            color: tokens.styles[`productTeaserMini-services-item-description-${size}-color`],
            fontFamily: tokens.styles[`productTeaserMini-services-item-description-${size}-fontFamily`],
            fontWeight: tokens.styles[`productTeaserMini-services-item-description-${size}-fontWeight`],
            lineHeight: tokens.styles[`productTeaserMini-services-item-description-${size}-lineHeight`],
            letterSpacing: tokens.styles[`productTeaserMini-services-item-description-${size}-letterSpacing`],
            fontSize: tokens.styles[`productTeaserMini-services-item-description-${size}-fontSize`],
        })), ...props, ref: ref }));
});
ProductTeaserMiniServicesItemDescription.displayName =
    'ProductTeaserMiniServicesItemDescription';
