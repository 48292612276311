import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { tabBarItems } from './styles/tabbar-items';
export const TabBarItems = forwardRef(({ items, size, variant, animationGroupId = 'tabbar-group-id', as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = tabBarItems(tokens)({ size });
    return (_jsx(Component, { css: styles, ...props, ref: ref, children: items({
            animationGroupId,
            size,
            variant,
            ...props,
        }) }));
});
TabBarItems.displayName = 'TabBarItems';
