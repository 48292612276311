import { getResponsiveStyles } from '../../../component-themes';
export const overlayCardFooter = (tokens) => ({ size, isSticky, }) => {
    const sticky = isSticky ? 'sticky' : 'initial';
    return getResponsiveStyles(size, (size) => ({
        width: '100%',
        position: isSticky ? 'sticky' : 'relative',
        transition: `all  ${tokens.animation['duration-slow']} ${tokens.animation['timing-soft']}`,
        bottom: sticky ? 0 : 'auto',
        backgroundColor: tokens.styles[`overlayCard-footer-base-${sticky}-${size}-background`],
        paddingBlockStart: tokens.styles[`overlayCard-footer-base-${sticky}-${size}-paddingTop`],
        paddingBlockEnd: tokens.styles[`overlayCard-footer-base-${sticky}-${size}-paddingBottom`],
        paddingInlineStart: tokens.styles[`overlayCard-footer-base-${sticky}-${size}-paddingLeft`],
        paddingInlineEnd: tokens.styles[`overlayCard-footer-base-${sticky}-${size}-paddingRight`],
        borderBlockStart: `
            ${tokens.styles[`overlayCard-footer-base-${sticky}-${size}-borderTopWidth`]}
            ${tokens.styles[`overlayCard-footer-base-${sticky}-${size}-borderStyle`]} ${tokens.styles[`overlayCard-footer-base-${sticky}-${size}-borderColor`]}`,
        gap: tokens.styles[`overlayCard-footer-base-${sticky}-${size}-gap`],
    }));
};
