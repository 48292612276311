import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ChevronRight } from '../../component-icons';
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { Children, forwardRef } from 'react';
import { BreadcrumbIcon } from './breadcrumb-icon';
/**
 * Should be used to render breadcrumbs within the application.
 */
export const Breadcrumb = forwardRef(({ items, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { align: "center", css: { gap: tokens.styles['breadcrumb-base-gap'] }, divider: _jsx(BreadcrumbIcon, { as: ChevronRight, css: {
                color: tokens.styles['breadcrumb-divider-background'],
                opacity: tokens.styles['breadcrumb-divider-opacity'],
            } }), ...props, ref: ref, children: Children.map(items.map((item) => item?.({})), (item) => item) }));
});
Breadcrumb.displayName = 'Breadcrumb';
