import { combineResponsiveValues, getResponsiveStyles, } from '../../../component-themes';
export const editorialTeaser = (tokens) => ({ size, orientation, mediaAlignment, variant, }) => {
    const paddingBlockMap = {
        vertical: {
            sm: tokens.space['small-lg'],
            md: tokens.space['small-lg'],
            lg: tokens.space['small-xl'],
        },
        horizontal: {
            sm: tokens.space['small-xl'],
            md: tokens.space['small-2xl'],
            lg: tokens.space['small-3xl'],
        },
    };
    const paddingInlineMap = {
        vertical: {
            sm: tokens.space['small-md'],
            md: tokens.space['small-md'],
            lg: tokens.space['small-lg'],
        },
        horizontal: {
            sm: tokens.space['small-xl'],
            md: tokens.space['small-xl'],
            lg: tokens.space['small-2xl'],
        },
    };
    return {
        content: [
            getResponsiveStyles(combineResponsiveValues(size, orientation), ([size, orientation]) => ({
                width: orientation === 'horizontal' ? '50%' : 'auto',
                flex: orientation === 'horizontal' ? 'none' : 1,
                paddingBlock: paddingBlockMap[orientation][size],
                paddingInline: paddingInlineMap[orientation][size],
            })),
        ],
        media: [
            {
                position: 'relative',
            },
            getResponsiveStyles(combineResponsiveValues(mediaAlignment, orientation), ([mediaAlignment, orientation]) => ({
                width: orientation === 'horizontal' ? '50%' : '100%',
                flexBasis: orientation === 'horizontal' ? 'auto' : '100%',
                order: mediaAlignment === 'start' ? 0 : 1,
            })),
        ],
        wrapper: [
            {
                backgroundColor: tokens.styles[`editorialTeaser-base-${variant}-background`],
                transitionDuration: tokens.animation['duration-quick'],
                transitionTimingFunction: tokens.animation['timing-soft'],
                ':hover': {
                    filter: `${tokens.styles['interaction-hover-contrast']}`,
                },
                ':active': {
                    filter: `${tokens.styles['interaction-pressed-contrast']}`,
                },
            },
        ],
    };
};
