import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { Close } from '../../component-icons';
import { forwardRef } from 'react';
/**
 * The `PopoverCloseButton` component is used to display the close button of a popover.
 */
export const PopoverCloseButton = forwardRef((props, ref) => {
    return (_jsx(ButtonAction, { size: "sm", variant: "subtle", icon: Close, ...props, ref: ref }));
});
PopoverCloseButton.displayName = 'PopoverCloseButton';
