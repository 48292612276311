import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { logo } from './styles/logo';
/**
 * Should be used when a logo is displayed within the navigation.
 */
export const Logo = forwardRef(({ size, as: Component = 'img', ...props }, ref) => {
    const tokens = useTokens();
    const styles = logo(tokens)({ size });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
Logo.displayName = 'Logo';
