import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box } from '../../component-box';
import { Star, StarEmpty } from '../../component-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { ratingIcon } from './styles/rating-icon';
export const RatingIcon = forwardRef(({ value, size, ...props }, ref) => {
    const tokens = useTokens();
    const styles = ratingIcon(tokens)({
        value,
        size,
    });
    return (_jsxs(Box, { ...props, style: { position: 'relative', ...props.style }, ref: ref, children: [value > 0 && value < 1 && (_jsx(Box, { styles: styles.background, as: StarEmpty })), _jsx(Box, { styles: styles.icon, as: value > 0 ? Star : StarEmpty })] }));
});
RatingIcon.displayName = 'RatingIcon';
