import { mergeStyles } from '../../component-themes';
import Color from 'color';
export const notificationCounterBlob = (tokens) => ({ variant }) => {
    return mergeStyles([
        {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: tokens.size['small-md'],
            height: tokens.size['small-md'],
            paddingInlineStart: tokens.space['small-xxs'],
            paddingInlineEnd: tokens.space['small-xxs'],
            borderRadius: tokens.radii.full,
        },
        variant === 'default'
            ? {
                background: tokens.colors['surface-base-200'],
                color: Color(tokens.colors['surface-onBase-200'])
                    .alpha(tokens.emphasis.high)
                    .string(),
            }
            : {},
        variant === 'highlighted'
            ? {
                background: tokens.colors['surface-base-100'],
                color: Color(tokens.colors['surface-onBase-100'])
                    .alpha(tokens.emphasis.high)
                    .string(),
            }
            : {},
        variant === 'prominent'
            ? {
                background: tokens.colors['primary-ui-default'],
                color: Color(tokens.colors['primary-onUi-default'])
                    .alpha(tokens.emphasis.high)
                    .string(),
            }
            : {},
        variant === 'image'
            ? {
                background: tokens.colors['light-900'],
                color: Color(tokens.colors['dark-900'])
                    .alpha(tokens.emphasis.high)
                    .string(),
            }
            : {},
    ]);
};
