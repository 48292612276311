import { getResponsiveStyles, mergeStyles } from '../../component-themes';
export const textBase = (tokens) => ({ variant, size, color, emphasis }) => mergeStyles([
    {
        margin: 0,
        padding: 0,
        color: tokens.styles[`page-${variant}-onBase`],
    },
    size === '2xs'
        ? {
            letterSpacing: tokens.styles['bodytext-nano-letterSpacing'],
            fontSize: tokens.styles['bodytext-nano-fontSize'],
            lineHeight: tokens.styles['bodytext-nano-lineHeight'],
            fontFamily: tokens.styles['bodytext-nano-fontFamily'],
            fontWeight: tokens.styles['bodytext-nano-fontWeight'],
        }
        : {},
    size === 'xs'
        ? {
            letterSpacing: tokens.styles['bodytext-tiny-letterSpacing'],
            fontSize: tokens.styles['bodytext-tiny-fontSize'],
            lineHeight: tokens.styles['bodytext-tiny-lineHeight'],
            fontFamily: tokens.styles['bodytext-tiny-fontFamily'],
            fontWeight: tokens.styles['bodytext-tiny-fontWeight'],
        }
        : {},
    size === 'sm'
        ? {
            letterSpacing: tokens.styles['bodytext-small-letterSpacing'],
            fontSize: tokens.styles['bodytext-small-fontSize'],
            lineHeight: tokens.styles['bodytext-small-lineHeight'],
            fontFamily: tokens.styles['bodytext-small-fontFamily'],
            fontWeight: tokens.styles['bodytext-small-fontWeight'],
        }
        : {},
    size === 'md'
        ? {
            letterSpacing: tokens.styles['bodytext-medium-letterSpacing'],
            fontSize: tokens.styles['bodytext-medium-fontSize'],
            lineHeight: tokens.styles['bodytext-medium-lineHeight'],
            fontFamily: tokens.styles['bodytext-medium-fontFamily'],
            fontWeight: tokens.styles['bodytext-medium-fontWeight'],
        }
        : {},
    size === 'lg'
        ? {
            letterSpacing: tokens.styles['bodytext-large-letterSpacing'],
            fontSize: tokens.styles['bodytext-large-fontSize'],
            lineHeight: tokens.styles['bodytext-large-lineHeight'],
            fontFamily: tokens.styles['bodytext-large-fontFamily'],
            fontWeight: tokens.styles['bodytext-large-fontWeight'],
        }
        : {},
    color ? { color: tokens.colors[color] } : {},
    emphasis
        ? {
            opacity: tokens.emphasis[emphasis],
        }
        : {},
]);
export const text = (tokens) => ({ variant, size, color, emphasis }) => getResponsiveStyles(size, (size) => textBase(tokens)({ size, variant, color, emphasis }));
