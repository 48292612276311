import React, { ReactNode } from 'react';
import type { ResponsiveValue } from '../../../../deps/@bshgroup/component-themes';
import type { CSSObject } from '@emotion/react';
import AutoOrientationService from '../../../services/autoOrientation';

export type LinearLayoutSpacing = 'none' | 'tiny' | 'small' | 'adjacent' | 'default';

export interface LinearLayoutProps {
  /**
   * Item layout orientation
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * Items to lay out
   */
  children: ReactNode;
  /**
   * Spacing between items
   */
  spacing?: LinearLayoutSpacing;
  /**
   * Item alignment
   */
  align?: ResponsiveValue<CSSObject['alignItems']>;
  /**
   * Item justification
   */
  justify?: ResponsiveValue<CSSObject['justifyContent']>;
}

const spacings = {
  mobile: {
    none: 0,
    tiny: '0.5rem',
    small: '1rem',
    adjacent: '1.5rem',
    default: '2.5rem',
  },
  desktop: {
    none: 0,
    tiny: '0.5rem',
    small: '1.5rem',
    adjacent: '2.5rem',
    default: '5rem',
  },
};

/**
 * The `LinearLayout` component is used to display content in a linear layout.
 */
export function LinearLayout({
  orientation = 'vertical',
  children,
  spacing = 'default',
  align,
  justify,
  ...props
}: LinearLayoutProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof LinearLayoutProps>) {
  return (
    <div
      css={
        {
          display: 'flex',
          flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          alignItems: align ?? 'normal',
          justifyContent: justify ?? 'normal',
          ...AutoOrientationService.getStyles({ gap: spacings.mobile[spacing] }, { gap: spacings.desktop[spacing] }),
        } as CSSObject
      }
      {...props}
    >
      {children}
    </div>
  );
}
