import { jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { forwardRef } from 'react';
/**
 * The `FeatureListItem` should be used to display a single or multiple items inside of the `FeatureList`.
 */
export const FeatureListItem = forwardRef(({ size, description, headline, icon, variant, ...props }, ref) => (_jsxs(Stack, { direction: "vertical", spacing: "small-xxs", ...props, ref: ref, children: [headline && icon ? (_jsxs(Stack, { align: "center", spacing: "small-xs", children: [headline?.({
                    size,
                    variant,
                }), icon?.({
                    size,
                    variant,
                })] })) : (_jsxs(_Fragment, { children: [headline?.({
                    size,
                    variant,
                }), icon?.({
                    size,
                    variant,
                })] })), description?.({ size, variant })] })));
FeatureListItem.displayName = 'FeatureListFeatureContent';
