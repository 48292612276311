import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const textMap = {
    sm: 'sm',
    md: 'sm',
    lg: 'lg',
};
const spacingMap = {
    sm: 'small-xs',
    md: 'small-sm',
    lg: 'small-sm',
};
/**
 * Is used to display a section in the `Footer` where the user can get information about and sign up for a newsletter.
 */
export const FooterNewsletter = forwardRef(({ size, headline, text, ...props }, ref) => {
    return (_jsxs(Stack, { direction: "vertical", spacing: mapResponsiveValue(size, (size) => spacingMap[size]), ...props, ref: ref, children: [headline({ size }), text({
                size: mapResponsiveValue(size, (size) => textMap[size]),
            })] }));
});
FooterNewsletter.displayName = 'FooterNewsletter';
