import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useState } from 'react';
/**
 * Is used for the individual accordion items.
 */
export const AccordionItem = forwardRef(({ size, button, content, extended, as: Component = 'div', ...props }, ref) => {
    const [onActive, setOnActive] = useState(false);
    const [onHover, setOnHover] = useState(false);
    return (_jsxs(Component, { ...props, ref: ref, children: [_jsx("div", { onMouseDown: () => {
                    setOnActive(true);
                }, onMouseUp: () => {
                    setOnActive(false);
                }, onTouchStart: () => {
                    setOnActive(true);
                }, onTouchEnd: () => {
                    setOnActive(false);
                }, onMouseOver: () => {
                    setOnHover(true);
                }, onMouseOut: () => {
                    setOnHover(false);
                }, children: button({
                    size,
                    extended,
                    active: onActive,
                    hover: onHover,
                }) }), content?.({
                size,
                extended,
            })] }));
});
AccordionItem.displayName = 'AccordionItem';
