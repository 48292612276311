import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Slot to render the steps list displayed in `ProgressSteps`.
 */
export const ProgressStepsStepList = forwardRef(({ size, variant, steps, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { ...props, ref: ref, align: "center", css: {
            gap: tokens.styles['progressSteps-base-gap'],
            justifyContent: 'space-between',
        }, children: steps({ size, variant }) }));
});
ProgressStepsStepList.displayName = 'ProgressStepsStepList';
