import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCurrentResponsiveValue, useTokens, } from '../../component-themes';
import { framerDuration, framerEase } from '../../component-utilities';
import { m as motion } from 'framer-motion';
import { forwardRef } from 'react';
/**
 * Is used to set the content of accordion items.
 */
export const AccordionItemContent = forwardRef(({ as: Component = 'div', size, extended, children, ...props }, ref) => {
    const tokens = useTokens();
    const fast = framerDuration(tokens.animation['duration-fast']);
    const slow = framerDuration(tokens.animation['duration-slow']);
    const currentSize = useCurrentResponsiveValue(size) || 'sm';
    const delayedSpringTransition = {
        type: 'spring',
        stiffness: 120,
        damping: 15,
        mass: 1,
        delay: fast,
    };
    const promptSpringTransition = {
        type: 'spring',
        stiffness: 120,
        damping: 15,
        mass: 1,
        delay: 0,
    };
    const delayedCubicTransition = {
        duration: slow,
        ease: framerEase(tokens.animation['timing-soft']),
        delay: fast,
    };
    const promptCubicTransition = {
        duration: slow,
        ease: framerEase(tokens.animation['timing-soft']),
        delay: 0,
    };
    return (_jsxs(Component, { ...props, ref: ref, children: [_jsx(motion.div, { initial: false, animate: {
                    height: extended ? 'fit-content' : 0,
                    transition: extended
                        ? promptSpringTransition
                        : delayedCubicTransition,
                }, css: { overflow: 'hidden' }, children: children }), _jsx(motion.div, { initial: false, animate: {
                    paddingBlockEnd: extended
                        ? tokens.styles[`accordion-content-${currentSize}-paddingBottom`]
                        : 0,
                    transition: extended
                        ? delayedSpringTransition
                        : promptCubicTransition,
                } })] }));
});
AccordionItemContent.displayName = 'AccordionItemContent';
