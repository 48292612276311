import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const featureTileBase = (tokens) => ({ variant, size, imageURL }) => mergeStyles([
    {
        boxSizing: 'border-box',
        borderRadius: tokens.radii['xs'],
        background: imageURL
            ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${imageURL})
      50% 50% / cover`
            : '',
        ':hover': {
            cursor: 'pointer',
        },
    },
    variant === 'default'
        ? {
            backgroundColor: tokens.colors['surface-base-100'],
        }
        : {},
    variant === 'highlighted'
        ? {
            backgroundColor: tokens.colors['surface-base-50'],
        }
        : {},
    size === 'sm'
        ? {
            paddingBlockStart: tokens.space['small-lg'],
            paddingBlockEnd: tokens.space['small-md'],
            paddingInline: tokens.space['small-md'],
        }
        : {},
    size === 'md'
        ? {
            paddingBlockStart: tokens.space['small-lg'],
            paddingBlockEnd: tokens.space['small-md'],
            paddingInline: tokens.space['small-md'],
        }
        : {},
    size === 'lg'
        ? {
            paddingBlockStart: tokens.space['small-xl'],
            paddingBlockEnd: tokens.space['small-lg'],
            paddingInline: tokens.space['small-lg'],
        }
        : {},
    size === 'xl'
        ? {
            paddingBlockStart: tokens.space['small-2xl'],
            paddingBlockEnd: tokens.space['small-xl'],
            paddingInline: tokens.space['small-xl'],
        }
        : {},
]);
export const featureTile = (tokens) => ({ variant, size, imageURL }) => getResponsiveStyles(size, (size) => featureTileBase(tokens)({ variant, size, imageURL }));
