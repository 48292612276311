import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBarButtonAction } from './styles/navigation-bar-button-action';
/**
 * Should be used to display a primary action button in the navigation.
 */
export const NavigationBarButtonAction = forwardRef(({ size, variant, onBackground, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarButtonAction(tokens)({
        variant,
        size,
        onBackground,
    });
    return (_jsx(ButtonAction, { size: mapResponsiveValue(size, (size) => (size === 'lg' ? 'md' : 'sm')), variant: variant, interactive: true, css: styles, ...props, ref: ref }));
});
NavigationBarButtonAction.displayName = 'NavigationBarButtonAction';
