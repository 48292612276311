import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { selectAmount } from './styles/select-amount';
/**
 * `SelectAmount` component is used to change the amount of a product the user wants to add into his shopping cart.
 */
export const SelectAmount = forwardRef(({ size, decrement, fullWidth, count, increment, variant, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = selectAmount(tokens)({ size, variant, fullWidth });
    return (_jsxs(Component, { css: styles, ...props, ref: ref, children: [decrement({
                size,
                variant,
            }), count({ size, variant }), increment({
                size,
                variant,
            })] }));
});
SelectAmount.displayName = 'SelectAmount';
