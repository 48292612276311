import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Box } from '../../component-box';
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * This component can be used to display an image of the logo of a trusted shop.
 */
export const TrustedShopsIcon = forwardRef(({ size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Box, { as: "img", styles: getResponsiveStyles(size, (size) => size === 'sm'
            ? { height: tokens.size['small-2xl'] }
            : { height: tokens.size['small-3xl'] }), ...props, ref: ref }));
});
TrustedShopsIcon.displayName = 'TrustedShopsIcon';
