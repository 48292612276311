import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const Hint = forwardRef(({ as: Component = 'div', content, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            backgroundColor: tokens.styles['hint-base-background'],
            paddingBlockStart: tokens.styles['hint-base-paddingTop'],
            paddingInlineEnd: tokens.styles['hint-base-paddingRight'],
            paddingBlockEnd: tokens.styles['hint-base-paddingBottom'],
            paddingInlineStart: tokens.styles['hint-base-paddingLeft'],
            borderRadius: tokens.styles['hint-base-borderRadius'],
        }, ...props, ref: ref, children: content({}) }));
});
Hint.displayName = 'Hint';
