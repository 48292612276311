import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue } from '../../component-themes';
import { Children, forwardRef } from 'react';
export const RatingIcons = forwardRef(({ value, limit, icon, size }, ref) => {
    const spacingMap = {
        sm: 'small-xxs',
        md: 'small-xxs',
        lg: 'small-xs',
    };
    return (_jsx(Stack, { direction: "horizontal", spacing: mapResponsiveValue(size, (size) => spacingMap[size]), align: "center", ref: ref, children: Children.map(Array.from({ length: limit }).map((_, index) => icon({ size, value: value - index })), (child) => child) }));
});
RatingIcons.displayName = 'RatingIcons';
