import { combineResponsiveValues, getResponsiveStyles, mergeStyles, } from '../../../component-themes';
export const stackBase = (tokens) => ({ align, justify, spacing, direction, }) => mergeStyles([
    {
        display: 'flex',
        gap: spacing ? tokens.space[spacing] : 0,
        alignItems: align ?? 'normal',
        justifyContent: justify ?? 'normal',
    },
    direction
        ? {
            flexDirection: {
                vertical: 'column',
                horizontal: 'row',
                'vertical-reverse': 'column-reverse',
                'horizontal-reverse': 'row-reverse',
            }[direction],
        }
        : {},
]);
export const stack = (tokens) => ({ align, justify, spacing, direction, }) => getResponsiveStyles(combineResponsiveValues(align, justify, spacing, direction), ([align, justify, spacing, direction]) => stackBase(tokens)({ align, justify, spacing, direction }));
