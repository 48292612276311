import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { PaymentIcon } from '../../component-payment-icons';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display a credit card provider as an indicator in the `Input` component.
 */
export const InputIndicatorCreditCardProvider = forwardRef(({ 
// we need to remove the props from the IndicatorProps type, because they are not used by the component
// otherwise they are spread and lead to a console warning
// eslint-disable-next-line @typescript-eslint/no-unused-vars
disabled, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
focused, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
filled, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
hovered, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
invalid, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(PaymentIcon, { css: {
            marginInlineStart: tokens.styles['input-indicator-creditCardProvider-base-paddingLeft'],
            marginInlineEnd: tokens.styles['input-indicator-creditCardProvider-base-paddingRight'],
        }, ...props, ref: ref }));
});
InputIndicatorCreditCardProvider.displayName =
    'InputIndicatorCreditCardProvider';
