import { getResponsiveStyles } from '../../../component-themes';
export const contentCard = (tokens) => ({ variant, size, }) => getResponsiveStyles(size, (size) => ({
    background: tokens.styles[`contentCard-${variant}-${size}-background`],
    paddingBlockStart: tokens.styles[`contentCard-${variant}-${size}-paddingTop`],
    paddingBlockEnd: tokens.styles[`contentCard-${variant}-${size}-paddingBottom`],
    paddingInlineStart: tokens.styles[`contentCard-${variant}-${size}-paddingLeft`],
    paddingInlineEnd: tokens.styles[`contentCard-${variant}-${size}-paddingRight`],
    borderColor: tokens.styles[`contentCard-${variant}-${size}-borderColor`],
    borderStyle: tokens.styles[`contentCard-${variant}-${size}-borderStyle`],
    borderRadius: tokens.styles[`contentCard-${variant}-${size}-borderRadius`],
    borderWidth: tokens.styles[`contentCard-${variant}-${size}-borderWidth`],
}));
