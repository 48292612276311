import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { OverlayCardHeader } from './overlay-card-header';
/**
 * Header with a headline as content.
 */
export const OverlayCardHeaderWithHeadline = forwardRef(({ size, headline, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(OverlayCardHeader, { content: headline
            ? () => (_jsx("div", { css: [
                    {
                        minHeight: tokens.size['small-2xl'],
                        boxSizing: 'border-box',
                    },
                    getResponsiveStyles(size, (size) => ({
                        paddingBlock: size === 'sm'
                            ? `calc(${tokens.space['small-sm']} - ${tokens.space['small-xxs']} / 2)`
                            : tokens.space['small-sm'],
                        paddingInlineStart: size === 'sm'
                            ? tokens.space['small-md']
                            : tokens.space['small-lg'],
                    })),
                ], children: headline({ size }) }))
            : undefined, size: size, ...props, ref: ref }));
});
OverlayCardHeaderWithHeadline.displayName = 'OverlayCardHeaderWithHeadline';
