import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const badgeIconIconBase = (tokens) => ({ variant, size, }) => {
    return mergeStyles([
        {
            color: tokens.styles[`badge-${variant}-icon-${size}-background`],
        },
        variant === 'subtle'
            ? {
                opacity: tokens.styles[`badge-subtle-icon-${size}-opacity`],
            }
            : {},
    ]);
};
export const badgeIconIcon = (tokens) => ({ variant, size, }) => {
    return getResponsiveStyles(size, (size) => badgeIconIconBase(tokens)({ size, variant }));
};
