import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `ProductTeaserMini` is used to display product information in a more compressed version as the `ProductTeaser`. It has a number of configurations and accordingly can be reused in multiple components.
 * In the `Configurations` Folder there are examples for different configurations and for different components:
 * - Default
 * - Compare Modal
 * - Order Summary
 * - Search Flyout
 */
export const ProductTeaserMini = forwardRef(({ as: Component = 'div', size, variant, content, services, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Component, { css: getResponsiveStyles(size, (size) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: tokens.styles[`productTeaserMini-base-${size}-${variant}-gap`],
            backgroundColor: tokens.styles[`productTeaserMini-base-${size}-${variant}-background`],
            borderStyle: 'solid',
            borderColor: tokens.styles[`productTeaserMini-base-${size}-${variant}-borderColor`],
            borderWidth: tokens.styles[`productTeaserMini-base-${size}-${variant}-borderWidth`],
            borderRadius: variant === 'default'
                ? tokens.styles[`productTeaserMini-base-${size}-${variant}-borderRadius`]
                : undefined,
            paddingBlockStart: tokens.styles[`productTeaserMini-base-${size}-${variant}-paddingTop`],
            paddingInlineStart: tokens.styles[`productTeaserMini-base-${size}-${variant}-paddingLeft`],
            paddingInlineEnd: tokens.styles[`productTeaserMini-base-${size}-${variant}-paddingRight`],
            paddingBlockEnd: tokens.styles[`productTeaserMini-base-${size}-${variant}-paddingBottom`],
        })), ...props, ref: ref, children: [content({ size }), services?.({ size })] }));
});
ProductTeaserMini.displayName = 'ProductTeaserMini';
