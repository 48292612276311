import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display images of products.
 */
export const ProductImage = forwardRef(({ as: Component = 'img', padding, aspectRatio, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: [
            {
                aspectRatio: aspectRatio === 'none'
                    ? 'auto'
                    : aspectRatio === '4:5'
                        ? '4 / 5'
                        : aspectRatio === '16:9'
                            ? '16 / 9'
                            : '5 / 4',
                width: '100%',
                height: 'auto',
            },
            getResponsiveStyles(padding, (padding) => ({
                paddingBlockStart: padding === 'none'
                    ? 0
                    : tokens.styles[`productImage-${padding}-paddingTop`],
                paddingInlineEnd: padding === 'none'
                    ? 0
                    : tokens.styles[`productImage-${padding}-paddingRight`],
                paddingBlockEnd: padding === 'none'
                    ? 0
                    : tokens.styles[`productImage-${padding}-paddingBottom`],
                paddingInlineStart: padding === 'none'
                    ? 0
                    : tokens.styles[`productImage-${padding}-paddingLeft`],
            })),
        ], ...props, ref: ref }));
});
ProductImage.displayName = 'ProductImage';
