import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { combineResponsiveValues, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
import { editorialTeaser } from './styles/editorial-teaser';
export const EditorialTeaser = forwardRef(({ size, variant, content, orientation, mediaAlignment, media, ...props }, ref) => {
    const tokens = useTokens();
    const styles = editorialTeaser(tokens)({
        size,
        orientation,
        mediaAlignment,
        variant,
    });
    return (_jsxs(Stack, { direction: orientation, align: "center", justify: "center", css: styles.wrapper, ...props, ref: ref, children: [media && (_jsx("div", { css: styles.media, children: media?.({
                    orientation,
                    size: mapResponsiveValue(combineResponsiveValues(size, orientation), ([size, orientation]) => ({
                        vertical: { sm: 'sm', md: 'sm', lg: 'lg' },
                        horizontal: { sm: 'sm', md: 'sm', lg: 'lg' },
                    }[orientation][size])),
                }) })), content && (_jsx("div", { css: styles.content, children: content?.({
                    size: mapResponsiveValue(combineResponsiveValues(size, orientation), ([size, orientation]) => orientation === 'horizontal'
                        ? size
                        : { sm: 'xs', md: 'sm', lg: 'md' }[size]),
                    variant,
                }) }))] }));
});
EditorialTeaser.displayName = 'EditorialTeaser';
