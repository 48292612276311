import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { contentCard } from './styles/content-card';
/**
 * Content Card Component provides structure as a layout element
 */
export const ContentCard = forwardRef(({ content, size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = contentCard(tokens)({ size, variant });
    return (_jsx(Stack, { align: "center", justify: "center", css: styles, ...props, ref: ref, children: content({}) }));
});
ContentCard.displayName = 'ContentCard';
