import React, { ReactNode } from 'react';
import { breakpoints } from '../../../global/global-config';
import { Grid as SherpaGrid } from '../../../../deps/@bshgroup/component-grid';

export interface GridLayoutProps {
  /**
   * Number of columns for each breakpoint [sm, md, lg], default is [1, 2, 3]
   */
  columns?: number[];
  /**
   * Grid content
   */
  children: ReactNode;
}

/**
 * The `GridLayout` component is used to display content in a grid.
 */
export function GridLayout({
  columns = [],
  children,
  ...props
}: GridLayoutProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof GridLayoutProps>) {
  columns = [1, 2, 3].map((e, i) => columns[i] ?? e);

  return (
    <SherpaGrid
      size="lg"
      columns={{
        [breakpoints.sm]: columns[0],
        [breakpoints.md]: columns[1],
        [breakpoints.lg]: columns[2],
      }}
      variant="default"
      {...props}
    >
      {children}
    </SherpaGrid>
  );
}
