import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
const featureListItemHeadlineBase = (tokens) => ({ size, variant, }) => mergeStyles([
    {
        display: 'inline',
        verticalAlign: 'middle',
        color: tokens.styles[`featureList-feature-content-header-headline-${variant}-${size}-color`],
        fontSize: tokens.styles[`featureList-feature-content-header-headline-${variant}-${size}-fontSize`],
        fontWeight: tokens.styles[`featureList-feature-content-header-headline-${variant}-${size}-fontWeight`],
        fontFamily: tokens.styles[`featureList-feature-content-header-headline-${variant}-${size}-fontFamily`],
        lineHeight: tokens.styles[`featureList-feature-content-header-headline-${variant}-${size}-lineHeight`],
        letterSpacing: tokens.styles[`featureList-feature-content-header-headline-${variant}-${size}-letterSpacing`],
    },
]);
export const featureListItemHeadline = (tokens) => ({ size, variant, }) => getResponsiveStyles(size, (size) => featureListItemHeadlineBase(tokens)({ size, variant }));
