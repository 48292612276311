import { getResponsiveStyles, mergeStyles } from '../../component-themes';
const fontSizeMap = {
    sm: 'small',
    md: 'medium',
    lg: 'large',
};
export const labelBase = (tokens) => ({ size, color, emphasis, }) => mergeStyles([
    {
        margin: 0,
        padding: 0,
        color: tokens.colors['surface-onBase-50'],
        fontFamily: tokens.styles[`labeltext-${fontSizeMap[size]}-fontFamily`],
        fontWeight: tokens.styles[`labeltext-${fontSizeMap[size]}-fontWeight`],
        lineHeight: tokens.styles[`labeltext-${fontSizeMap[size]}-lineHeight`],
        fontSize: tokens.styles[`labeltext-${fontSizeMap[size]}-fontSize`],
        letterSpacing: tokens.styles[`labeltext-${fontSizeMap[size]}-letterSpacing`],
    },
    color ? { color: tokens.colors[color] } : {},
    emphasis ? { opacity: tokens.emphasis[emphasis] } : {},
]);
export const label = (tokens) => ({ size, color, emphasis }) => getResponsiveStyles(size, (size) => labelBase(tokens)({ size, color, emphasis }));
