import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ArrowLeft } from '../../component-icons';
import { NavigationButton } from '../../component-navigation-button';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Button that should be used in the `backButton` slot of the `OverlayCardHeaderWithBackButton`.
 */
export const OverlayCardHeaderBackButton = forwardRef((props, ref) => {
    const tokens = useTokens();
    return (_jsx(NavigationButton, { variant: "secondary", iconLeft: ArrowLeft, css: { maxHeight: tokens.size['small-2xl'] }, ...props, ref: ref }));
});
OverlayCardHeaderBackButton.displayName = 'OverlayCardHeaderBackButton';
