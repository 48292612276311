import { combineResponsiveValues } from '../../../component-themes';
import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
import Color from 'color';
export const buttonActionBase = (tokens) => ({ variant, size, isLoading, interactive, hovered, active, }) => {
    const buttonActionInitialStyles = {
        backgroundColor: tokens.styles[`buttonAction-base-${variant}-${size}-initial-background`],
        height: tokens.styles[`buttonAction-base-${variant}-${size}-initial-height`],
        width: tokens.styles[`buttonAction-base-${variant}-${size}-initial-width`],
        borderStyle: tokens.styles[`buttonAction-base-${variant}-${size}-initial-borderStyle`],
        borderRadius: tokens.styles[`buttonAction-base-${variant}-${size}-initial-borderRadius`],
        borderWidth: tokens.styles[`buttonAction-base-${variant}-${size}-initial-borderWidth`],
        borderColor: tokens.styles[`buttonAction-base-${variant}-${size}-initial-borderColor`],
        opacity: tokens.styles[`buttonAction-base-${variant}-${size}-initial-opacity`],
        gap: tokens.styles[`buttonAction-base-${variant}-${size}-initial-gap`],
    };
    const buttonActionHoverStyles = {
        backgroundColor: tokens.styles[`buttonAction-base-${variant}-${size}-hover-background`],
        height: tokens.styles[`buttonAction-base-${variant}-${size}-hover-height`],
        width: tokens.styles[`buttonAction-base-${variant}-${size}-hover-width`],
        borderStyle: tokens.styles[`buttonAction-base-${variant}-${size}-hover-borderStyle`],
        borderRadius: tokens.styles[`buttonAction-base-${variant}-${size}-hover-borderRadius`],
        borderWidth: tokens.styles[`buttonAction-base-${variant}-${size}-hover-borderWidth`],
        borderColor: tokens.styles[`buttonAction-base-${variant}-${size}-hover-borderColor`],
        opacity: tokens.styles[`buttonAction-base-${variant}-${size}-hover-opacity`],
        gap: tokens.styles[`buttonAction-base-${variant}-${size}-hover-gap`],
    };
    const buttonActionPressedStyles = {
        backgroundColor: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-background`],
        height: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-height`],
        width: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-width`],
        borderStyle: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-borderStyle`],
        borderRadius: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-borderRadius`],
        borderWidth: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-borderWidth`],
        borderColor: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-borderColor`],
        opacity: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-opacity`],
        gap: tokens.styles[`buttonAction-base-${variant}-${size}-pressed-gap`],
    };
    const buttonActionFocusedStyles = {
        backgroundColor: tokens.styles[`buttonAction-base-${variant}-${size}-focused-background`],
        height: tokens.styles[`buttonAction-base-${variant}-${size}-focused-height`],
        width: tokens.styles[`buttonAction-base-${variant}-${size}-focused-width`],
        borderStyle: tokens.styles[`buttonAction-base-${variant}-${size}-focused-borderStyle`],
        borderRadius: tokens.styles[`buttonAction-base-${variant}-${size}-focused-borderRadius`],
        borderWidth: tokens.styles[`buttonAction-base-${variant}-${size}-focused-borderWidth`],
        borderColor: tokens.styles[`buttonAction-base-${variant}-${size}-focused-borderColor`],
        opacity: tokens.styles[`buttonAction-base-${variant}-${size}-focused-opacity`],
        gap: tokens.styles[`buttonAction-base-${variant}-${size}-focused-gap`],
    };
    const buttonActionDisabledStyles = {
        backgroundColor: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-background`],
        height: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-height`],
        width: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-width`],
        borderStyle: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-borderStyle`],
        borderRadius: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-borderRadius`],
        borderWidth: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-borderWidth`],
        borderColor: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-borderColor`],
        opacity: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-opacity`],
        gap: tokens.styles[`buttonAction-base-${variant}-${size}-disabled-gap`],
    };
    const iconInitialStyles = {
        color: Color(tokens.styles[`buttonAction-icon-${variant}-${size}-initial-background`])
            .alpha(tokens.styles[`buttonAction-icon-${variant}-${size}-initial-opacity`])
            .string(),
    };
    const iconHoverStyles = {
        color: Color(tokens.styles[`buttonAction-icon-${variant}-${size}-hover-background`])
            .alpha(tokens.styles[`buttonAction-icon-${variant}-${size}-hover-opacity`])
            .string(),
    };
    const iconPressedStyles = {
        color: Color(tokens.styles[`buttonAction-icon-${variant}-${size}-pressed-background`])
            .alpha(tokens.styles[`buttonAction-icon-${variant}-${size}-pressed-opacity`])
            .string(),
    };
    const iconFocusedStyles = {
        color: Color(tokens.styles[`buttonAction-icon-${variant}-${size}-focused-background`])
            .alpha(tokens.styles[`buttonAction-icon-${variant}-${size}-focused-opacity`])
            .string(),
    };
    const iconDisabledStyles = {
        color: Color(tokens.styles[`buttonAction-icon-${variant}-${size}-disabled-background`])
            .alpha(tokens.styles[`buttonAction-icon-${variant}-${size}-disabled-opacity`])
            .string(),
    };
    return mergeStyles([
        {
            cursor: 'pointer',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'inline-flex',
            transition: `all ${tokens.animation['duration-quick']} ${tokens.animation['timing-soft']}`,
            flexShrink: 0,
            padding: 0,
            ...buttonActionInitialStyles,
            ...iconInitialStyles,
            '@media (hover: hover)': {
                ':hover': interactive && hovered == null
                    ? { ...buttonActionHoverStyles, ...iconHoverStyles }
                    : undefined,
            },
            ':focus-visible': interactive
                ? { ...buttonActionFocusedStyles, ...iconFocusedStyles }
                : undefined,
            '@supports not selector(:focus-visible)': {
                ':focus': { ...buttonActionFocusedStyles, ...iconFocusedStyles },
            },
            ':active': interactive && active == null
                ? { ...buttonActionPressedStyles, ...iconPressedStyles }
                : undefined,
            ':disabled': { ...buttonActionDisabledStyles, ...iconDisabledStyles },
        },
        interactive && hovered
            ? { ...buttonActionHoverStyles, ...iconHoverStyles }
            : {},
        interactive && active
            ? { ...buttonActionPressedStyles, ...iconPressedStyles }
            : {},
        isLoading
            ? {
                ...buttonActionDisabledStyles,
                ...iconDisabledStyles,
                cursor: 'wait',
            }
            : {},
    ]);
};
export const buttonAction = (tokens) => ({ variant, size, isLoading, interactive = true, hovered, active, }) => getResponsiveStyles(combineResponsiveValues(variant, size), ([variant, size]) => buttonActionBase(tokens)({
    variant,
    size,
    isLoading,
    hovered,
    active,
    interactive,
}));
