import React, { ReactNode } from 'react';
import { Link as SherpaLink } from '../../../../deps/@bshgroup/component-link';
import { ChevronRight as SherpaChevronRight } from '../../../../deps/@bshgroup/component-icons';

export interface LinkProps {
  /**
   * Link content
   */
  children: ReactNode;
  /**
   * Linked URL
   */
  to: string;

  /**
   * Show a chevron after the link content
   */
  chevron?: boolean;
  /**
   * Display variant
   */
  variant?: 'prominent' | 'neutral';
  /**
   * Link text size
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /**
   * Open link in new tab instead of the same
   */
  openInNewTab?: boolean | string;
  /**
   * The Link component to be used e.g. GatsbyLink or NextLink
   */
  as?: any;
  /**
   * Whether the link is disabled
   */
  disabled?: boolean;
}

/**
 * The `Link` component is used to display a styled link.
 */
export function Link({
  children,
  to,
  chevron = false,
  variant = 'prominent',
  size = 'lg',
  openInNewTab = false,
  as = 'a',
  disabled,
  ...props
}: LinkProps & Omit<Partial<React.HTMLAttributes<HTMLAnchorElement>>, keyof LinkProps>) {
  return (
    <SherpaLink
      as={disabled ? 'p' : as}
      {...(as === 'a' ? { href: to } : { to })}
      {...(chevron ? { alignIcon: 'right', icon: SherpaChevronRight } : {})}
      size={size}
      variant={variant}
      target={openInNewTab === true ? '_blank' : openInNewTab || undefined}
      disabled={disabled}
      css={{ opacity: disabled ? '0.4 !important' : 1 }}
      {...props}
    >
      {children}
    </SherpaLink>
  );
}
