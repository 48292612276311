import { getResponsiveStyles } from '../../../component-themes';
export const tabBarItems = (tokens) => ({ size }) => getResponsiveStyles(size, (size) => ({
    display: 'flex',
    gap: tokens.styles[`tabBar-tabList-base-${size}-gap`],
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
        display: 'none',
    },
}));
