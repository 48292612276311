import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the legal text in `OrderSummaryFooterSummaryList`
 */
export const OrderSummaryFooterSummaryListLegal = forwardRef(({ as: Component = 'div', size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            color: tokens.styles[`orderSummary-footer-summaryList-legal-${size}-color`],
            fontSize: tokens.styles[`orderSummary-footer-summaryList-legal-${size}-fontSize`],
            fontFamily: tokens.styles[`orderSummary-footer-summaryList-legal-${size}-fontFamily`],
            lineHeight: tokens.styles[`orderSummary-footer-summaryList-legal-${size}-lineHeight`],
            fontWeight: tokens.styles[`orderSummary-footer-summaryList-legal-${size}-fontWeight`],
            letterSpacing: tokens.styles[`orderSummary-footer-summaryList-legal-${size}-letterSpacing`],
        })), ...props, ref: ref }));
});
OrderSummaryFooterSummaryListLegal.displayName =
    'OrderSummaryFooterSummaryListLegal';
