import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display the description in `EditorialTeaserContent`.
 */
export const EditorialTeaserContentDescription = forwardRef(({ as: Component = 'div', variant, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            lineClamp: 3,
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
            color: tokens.styles[`editorialTeaser-content-title-description-${variant}-color`],
            fontSize: tokens.styles[`editorialTeaser-content-title-description-${variant}-fontSize`],
            fontWeight: tokens.styles[`editorialTeaser-content-title-description-${variant}-fontWeight`],
            fontFamily: tokens.styles[`editorialTeaser-content-title-description-${variant}-fontFamily`],
            lineHeight: tokens.styles[`editorialTeaser-content-title-description-${variant}-lineHeight`],
            letterSpacing: tokens.styles[`editorialTeaser-content-title-description-${variant}-letterSpacing`],
            opacity: tokens.styles[`editorialTeaser-content-title-description-${variant}-opacity`],
        }, ...props, ref: ref }));
});
EditorialTeaserContentDescription.displayName =
    'EditorialTeaserContentDescription';
