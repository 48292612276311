import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { breadcrumbText } from './styles/breadcrumb-text';
/**
 * Should be used to render last link within the breadcrumbs as text.
 */
export const BreadcrumbText = forwardRef(({ as: Component = 'p', ...props }, ref) => {
    const tokens = useTokens();
    const styles = breadcrumbText(tokens)();
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
BreadcrumbText.displayName = 'BreadcrumbText';
