import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ProductTitleHeadline } from '../../component-product-title';
import { forwardRef } from 'react';
export const ProductTeaserMiniContentInformationProductTitleHeadline = forwardRef(({ ...props }, ref) => (_jsx(ProductTitleHeadline, { css: {
        lineClamp: 2,
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
    }, ...props, ref: ref })));
ProductTeaserMiniContentInformationProductTitleHeadline.displayName =
    'ProductTeaserMiniContentInformationProductTitleHeadline';
