import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const galleryThumbListThumbBase = (tokens) => ({ size, variant, }) => mergeStyles([
    {
        position: 'relative',
        cursor: 'pointer',
        boxSizing: 'border-box',
        zIndex: variant === 'inactive' ? 1 : 2,
        backgroundColor: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-background`],
        width: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-width`],
        height: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-height`],
        paddingBlockStart: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-paddingTop`],
        paddingInlineEnd: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-paddingRight`],
        paddingBlockEnd: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-paddingBottom`],
        paddingInlineStart: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-paddingLeft`],
        borderColor: tokens.styles[`gallery-thumbList-thumb-${size}-inactive-initial-borderColor`],
        borderStyle: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderStyle`],
        borderWidth: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderWidth`],
        borderRadius: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderRadius`],
        filter: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-filter`],
        transitionDuration: tokens.styles['interaction-hover-duration'],
        transitionTimingFunction: tokens.styles['interaction-hover-timing'],
        transitionProperty: 'border-color, filter, background-color',
        ':hover': {
            backgroundColor: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-background`],
            width: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-width`],
            height: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-height`],
            paddingBlockStart: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-paddingTop`],
            paddingInlineEnd: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-paddingRight`],
            paddingBlockEnd: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-paddingBottom`],
            paddingInlineStart: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-paddingLeft`],
            borderColor: variant === 'active'
                ? tokens.styles[`gallery-thumbList-thumb-${size}-inactive-hover-borderColor`]
                : tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderColor`],
            borderStyle: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderStyle`],
            borderWidth: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderWidth`],
            borderRadius: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderRadius`],
            filter: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-filter`],
        },
        ':active': {
            backgroundColor: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-background`],
            width: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-width`],
            height: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-height`],
            paddingBlockStart: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-paddingTop`],
            paddingInlineEnd: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-paddingRight`],
            paddingBlockEnd: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-paddingBottom`],
            paddingInlineStart: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-paddingLeft`],
            borderColor: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderColor`],
            borderStyle: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderStyle`],
            borderWidth: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderWidth`],
            borderRadius: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderRadius`],
            filter: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-filter`],
            transitionDuration: tokens.styles['interaction-pressed-duration'],
            transitionTimingFunction: tokens.styles['interaction-pressed-timing'],
            transitionProperty: 'border-color, filter, background-color',
        },
    },
]);
export const galleryThumbListThumbHighlight = (tokens) => ({ size, variant, }) => mergeStyles([
    {
        position: 'absolute',
        boxSizing: 'content-box',
        zIndex: variant === 'inactive' ? 1 : 2,
        height: '100%',
        width: '100%',
        borderColor: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderColor`],
        borderStyle: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderStyle`],
        borderWidth: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderWidth`],
        borderRadius: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderRadius`],
        filter: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-filter`],
        transition: `filter ${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
        ':hover': {
            borderColor: variant === 'inactive'
                ? tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderColor`]
                : tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderColor`],
            borderStyle: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderStyle`],
            borderWidth: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderWidth`],
            borderRadius: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-borderRadius`],
            filter: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-hover-filter`],
        },
        ':active': {
            borderColor: variant === 'inactive'
                ? tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderColor`]
                : tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-initial-borderColor`],
            borderStyle: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderStyle`],
            borderWidth: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderWidth`],
            borderRadius: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-borderRadius`],
            filter: tokens.styles[`gallery-thumbList-thumb-${size}-${variant}-pressed-filter`],
            transition: `filter ${tokens.styles['interaction-pressed-duration']} ${tokens.styles['interaction-pressed-timing']}`,
        },
    },
]);
export const galleryThumbListThumb = (tokens) => ({ size, variant, }) => {
    return {
        thumb: getResponsiveStyles(size, (size) => galleryThumbListThumbBase(tokens)({ size, variant })),
        highlight: getResponsiveStyles(size, (size) => galleryThumbListThumbHighlight(tokens)({ size, variant })),
    };
};
