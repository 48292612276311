import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the footer in `OrderSummary`
 */
export const OrderSummaryFooter = forwardRef(({ size, sticky, button, summaryList, ...props }, ref) => {
    const tokens = useTokens();
    const stickyState = sticky ? 'sticky' : 'default';
    return (_jsxs(Stack, { direction: "vertical", css: getResponsiveStyles(size, (size) => ({
            width: '100%',
            gap: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-gap`],
            paddingBlockStart: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-paddingTop`],
            paddingBlockEnd: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-paddingBottom`],
            paddingInlineStart: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-paddingLeft`],
            paddingInlineEnd: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-paddingRight`],
            backgroundColor: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-background`],
            boxShadow: tokens.styles[`orderSummary-footer-base-${size}-${stickyState}-boxShadow`]['box-shadow'],
            transitionProperty: 'all',
            transitionDuration: tokens.animation['duration-slow'],
            transitionTimingFunction: tokens.animation['timing-soft'],
        })), ...props, ref: ref, children: [summaryList?.({ size }), button?.({
                size: mapResponsiveValue(size, (size) => size === 'lg' ? 'lg' : 'md'),
                variant: 'prominent',
            })] }));
});
OrderSummaryFooter.displayName = 'OrderSummaryFooter';
