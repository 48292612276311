import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Icon } from '../../component-icon';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const FeatureTileFooterIndicatorIcon = forwardRef(({ variant, ...props }, ref) => {
    const tokens = useTokens();
    const colorMap = {
        default: tokens.styles['page-highlighted-onBase'],
        highlighted: tokens.styles['page-highlighted-onBase'],
        image: tokens.colors['light-900'],
    };
    return (_jsx(Icon, { size: "lg", css: { color: colorMap[variant] }, ...props, ref: ref }));
});
FeatureTileFooterIndicatorIcon.displayName = 'FeatureTileFooterIndicatorIcon';
