import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { accordionItemButton } from './styles/accordion-item-button';
/**
 * Is used to set the button of accordion items.
 */
export const AccordionItemButton = forwardRef(({ size, title, icon, extended, active, hover, as: Component = 'button', ...props }, ref) => {
    const tokens = useTokens();
    const state = active ? 'pressed' : hover ? 'hover' : 'initial';
    const styles = accordionItemButton(tokens)({
        size,
        extended,
        state,
    });
    return (_jsxs(Component, { css: styles, ...props, ref: ref, children: [title({ size, state }), icon({ size, extended, active, state })] }));
});
AccordionItemButton.displayName = 'AccordionItemButton';
