import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display the indicator for `ProgressStepsStepListStep`. An icon or label can be provided.
 * They should be provided exclusively.
 */
export const ProgressStepsStepListStepIndicator = forwardRef(({ active, state, label, icon, ...props }, ref) => {
    const tokens = useTokens();
    const activeState = active ? 'active' : 'inactive';
    const interactionState = state === 'initial' ? 'hover' : 'pressed';
    return (_jsxs(Stack, { align: "center", justify: "center", css: {
            width: tokens.styles[`progressSteps-stepList-step-indicator-base-${activeState}-${state}-width`],
            height: tokens.styles[`progressSteps-stepList-step-indicator-base-${activeState}-${state}-height`],
            background: tokens.styles[`progressSteps-stepList-step-indicator-base-${activeState}-${state}-background`],
            borderRadius: tokens.styles[`progressSteps-stepList-step-indicator-base-${activeState}-${state}-borderRadius`],
            transition: `all ${tokens.styles[`interaction-${interactionState}-duration`]} ${tokens.styles[`interaction-${interactionState}-timing`]}`,
        }, ...props, ref: ref, children: [label?.({ state, active }), icon?.({ state, active })] }));
});
ProgressStepsStepListStepIndicator.displayName =
    'ProgressStepsStepListStepIndicator';
