import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to display the progress bar for `ProgressSteps`.
 */
export const ProgressStepsProgressBar = forwardRef(({ as: Component = 'div', progress, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            backgroundColor: tokens.styles['progressSteps-progressBar-active-background'],
            borderRadius: tokens.styles['progressSteps-progressBar-active-borderRadius'],
            height: tokens.styles['progressSteps-progressBar-base-height'],
            width: `${progress}%`,
        }, ...props, ref: ref }));
});
ProgressStepsProgressBar.displayName = 'ProgressStepsProgressBar';
