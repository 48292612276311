import React, { MouseEventHandler, ReactNode } from 'react';
import { Button as SherpaButton } from '../../../../deps/@bshgroup/component-button';
import { ClassNames } from '@emotion/react';
import { Icon } from '../../Icon/Icon';
import { useTheme } from '../../ThemeProvider/ThemeProvider';

export type ButtonAlignment = 'left' | 'center' | 'right' | 'stretch';

export interface ButtonProps {
  /**
   * Button label
   */
  children?: ReactNode;
  /**
   * URL to which the button should lead to
   */
  link?: string;
  /**
   * Open link in new tab instead of the same
   */
  openLinkInNewTab?: boolean;
  /**
   * On click handler
   */
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  /**
   * Button alignment, stretch takes up the full width
   */
  alignment?: ButtonAlignment;
  /**
   * Display variant
   */
  variant?: 'default' | 'prominent' | 'subtle' | 'arrow' | 'linklike' | 'icononly';
  /**
   * Button is disabled
   */
  disabled?: boolean;
  /**
   * Icon to include, either string to take from the `Icon` component or custom icon element
   */
  icon?: ReactNode;
  /**
   * Color of the icon
   */
  iconColor?: string;
  /**
   * Default color of the button (arrow variant)
   */
  defaultColor?: string;
  /**
   * Button type attribute value
   */
  buttonType?: 'button' | 'submit' | 'reset';
  /**
   * Button size
   */
  size?: 'sm' | 'md' | 'lg';

  /**
   * Custom link component to use eg. react-router-dom's `Link` or GatsbyLink
   */
  linkComponent?: any;
  /**
   * Arrow direction for arrow variant
   */
  arrowDirection?: 'forward' | 'back';
}

/**
 * The `Button` displays a button with a label and an optional icon.
 */
export function Button({
  children,
  link,
  openLinkInNewTab,
  onClick,
  alignment = 'center',
  variant = 'default',
  disabled = false,
  icon,
  iconColor,
  defaultColor,
  buttonType,
  size = 'md',
  linkComponent,
  arrowDirection = 'forward',
  ...props
}: ButtonProps &
  Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof ButtonProps> &
  Omit<Partial<React.HTMLAttributes<HTMLButtonElement>>, keyof ButtonProps>) {
  const { tokens } = useTheme();

  // sort for props that need to be assigned to the button element directly
  const buttonProps: Partial<React.HTMLAttributes<HTMLButtonElement>> = {};
  const divProps: Partial<React.HTMLAttributes<HTMLDivElement>> = {};
  Object.entries(props).forEach(([key, value]) => {
    if (['onClick', 'onMouseDown', 'onMouseUp', 'aria-label'].includes(key)) {
      buttonProps[key as keyof Partial<React.HTMLAttributes<HTMLButtonElement>>] = value;
    } else {
      divProps[key as keyof Partial<React.HTMLAttributes<HTMLDivElement>>] = value;
    }
  });

  const LinkComponent = linkComponent || 'a';

  const RenderedButton = () => (
    <ClassNames>
      {({ css }) =>
        variant === 'arrow' || variant === 'linklike' ? (
          <button
            onClick={onClick}
            disabled={disabled}
            type={buttonType}
            className={css({
              border: 'none',
              background: 'none',
              fontFamily: 'Bosch Sans, sans-serif',
              fontSize: '1rem',
              color:
                !disabled && defaultColor
                  ? defaultColor
                  : tokens.colors[disabled ? 'bosch-gray-70' : 'primary-base-500'],
              cursor: disabled ? 'default' : 'pointer',
              display: 'inline-flex',
              justifyContent: 'space-between',
              gap: '0.5rem',
              alignItems: 'center',
              ...(disabled
                ? {}
                : {
                    '&:hover': {
                      color: tokens.colors['primary-base-500'],
                      textDecoration: 'underline',
                    },
                    '&:hover svg > *': {
                      fill: `${tokens.colors['primary-base-500']} !important`,
                    },
                    '&:active': {
                      color: tokens.colors['primary-base-600'],
                    },
                    '&:active svg > *': {
                      fill: `${tokens.colors['primary-base-600']} !important`,
                    },
                  }),
            })}
            {...buttonProps}
          >
            {variant === 'arrow' && arrowDirection === 'back' && (
              <Icon
                value="back-left"
                color={
                  !disabled && defaultColor
                    ? defaultColor
                    : tokens.colors[disabled ? 'bosch-gray-70' : 'primary-base-500']
                }
                size={size}
              />
            )}
            {icon && typeof icon === 'string' ? <Icon value={icon} size={size} color={iconColor} /> : icon}
            {children}
            {variant === 'arrow' && arrowDirection === 'forward' && (
              <Icon
                value="forward-right"
                color={
                  !disabled && defaultColor
                    ? defaultColor
                    : tokens.colors[disabled ? 'bosch-gray-70' : 'primary-base-500']
                }
                size={size}
              />
            )}
          </button>
        ) : variant === 'icononly' ? (
          <button
            onClick={onClick}
            disabled={disabled}
            type={buttonType}
            className={css({
              border: 'none',
              background: 'none',
              ...(disabled
                ? {}
                : {
                    cursor: 'pointer',
                    '&:hover svg > *': {
                      fill: `${tokens.colors[iconColor === 'white' ? 'secondary-base-200' : 'primary-base-600']} !important`,
                    },
                    '&:active svg > *': {
                      fill: `${tokens.colors[iconColor === 'white' ? 'secondary-base-300' : 'primary-base-700']} !important`,
                    },
                  }),
            })}
            {...buttonProps}
          >
            {icon && typeof icon === 'string' ? <Icon value={icon} size={size} color={iconColor} /> : icon}
          </button>
        ) : (
          <SherpaButton
            size={size}
            variant={variant}
            onClick={onClick}
            disabled={disabled}
            type={buttonType}
            className={css({
              '& > div': { display: children || icon ? 'flex' : 'none', alignItems: 'center', gap: '0.5rem' },
            })}
            {...buttonProps}
          >
            {icon && typeof icon === 'string' ? <Icon value={icon} size={size} color={iconColor} /> : icon}
            {children}
          </SherpaButton>
        )
      }
    </ClassNames>
  );

  return (
    <div
      css={{
        textAlign: alignment === 'stretch' ? 'center' : alignment,
        ...(alignment === 'stretch' ? { button: { width: '100%' } } : {}),
      }}
      {...divProps}
    >
      {link ? (
        !linkComponent ? (
          <a href={link} onClick={onClick} target={openLinkInNewTab ? '_blank' : undefined}>
            <RenderedButton />
          </a>
        ) : (
          <LinkComponent
            to={link}
            onClick={onClick}
            target={openLinkInNewTab ? '_blank' : undefined}
            openInNewTab={openLinkInNewTab}
          >
            <RenderedButton />
          </LinkComponent>
        )
      ) : (
        <RenderedButton />
      )}
    </div>
  );
}
