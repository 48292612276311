import React, { ReactNode, useMemo } from 'react';
import {
  boschLight,
  ThemeProvider as SherpaThemeProvider,
  useTokens as sherpaUseTokens,
} from '../../../deps/@bshgroup/component-themes';
import { BoschFonts } from '../../../deps/@bshgroup/component-fonts';
import boschColors from './bosch-colors.json';

export interface ThemeProviderProps {
  children: ReactNode;
}

export function useTheme(): { tokens: { colors: { [key: string]: string } } } {
  const sherpaTokens = sherpaUseTokens();
  const tokens = useMemo(
    () => ({ ...sherpaTokens, colors: { ...sherpaTokens.colors, ...boschColors } }),
    [sherpaTokens],
  ) as { colors: { [key: string]: string } };
  return { tokens };
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <SherpaThemeProvider theme={boschLight}>
      <BoschFonts />
      {children}
    </SherpaThemeProvider>
  );
}
