import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const FormControl = forwardRef(({ as: Component = 'label', control, label, disabled, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Component, { css: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: tokens.space['small-xs'],
            cursor: disabled ? 'not-allowed' : 'pointer',
        }, ...props, ref: ref, children: [control({ disabled }), label?.({ disabled })] }));
});
FormControl.displayName = 'FormControl';
