import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniServicesItem = forwardRef(({ as: Component = 'div', size, description, price, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Component, { css: getResponsiveStyles(size, (size) => ({
            display: 'flex',
            flexDirection: 'row',
            gap: tokens.styles[`productTeaserMini-services-item-base-${size}-gap`],
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })), ...props, ref: ref, children: [description({ size }), price({ size })] }));
});
ProductTeaserMiniServicesItem.displayName = 'ProductTeaserMiniServicesItem';
