import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used for additional information related to `PriceMain` if necessary.
 */
export const PriceSubline = forwardRef(({ size, ...props }, ref) => {
    return (_jsx(Text, { size: mapResponsiveValue(size, (size) => ({
            '2xs': '2xs',
            xs: '2xs',
            sm: 'xs',
            md: 'xs',
            lg: 'xs',
        }[size])), variant: "default", emphasis: "high", color: "surface-onBase-50", ...props, ref: ref }));
});
PriceSubline.displayName = 'PriceSubline';
