import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef, useContext, useEffect } from 'react';
import { InputContext } from './input-context';
/**
 * Should be used to display the input of `InputContent`.
 */
export const InputContentInput = forwardRef(({ as: Component = 'input', value, disabled, onBlur, onFocus, ...props }, ref) => {
    const { focused, setFocused, setFilled } = useContext(InputContext);
    useEffect(() => {
        if (value != null) {
            setFilled(value.toString() !== '');
        }
    }, [value, setFilled]);
    const state = disabled ? 'filled-disabled' : focused ? 'focused' : 'filled';
    const tokens = useTokens();
    return (_jsx(Component, { disabled: disabled, value: value, css: {
            width: '100%',
            textOverflow: 'ellipsis',
            backgroundColor: 'transparent',
            position: 'relative',
            transition: `all ${tokens.animation['duration-fast']} ${tokens.animation['timing-soft']}`,
            color: tokens.styles[`input-content-input-${state}-color`],
            fontFamily: tokens.styles[`input-content-input-${state}-fontFamily`],
            fontSize: tokens.styles[`input-content-input-${state}-fontSize`],
            fontWeight: tokens.styles[`input-content-input-${state}-fontWeight`],
            letterSpacing: tokens.styles[`input-content-input-${state}-letterSpacing`],
            lineHeight: tokens.styles[`input-content-input-${state}-lineHeight`],
            opacity: tokens.styles[`input-content-input-${state}-opacity`],
            '::placeholder': {
                color: tokens.styles['input-content-placeholder-color'],
                fontFamily: tokens.styles['input-content-placeholder-fontFamily'],
                fontSize: tokens.styles['input-content-placeholder-fontSize'],
                fontWeight: tokens.styles['input-content-placeholder-fontWeight'],
                letterSpacing: tokens.styles['input-content-placeholder-letterSpacing'],
                lineHeight: tokens.styles['input-content-placeholder-lineHeight'],
                opacity: focused
                    ? tokens.styles['input-content-placeholder-opacity']
                    : 0,
            },
            outline: 'none',
            border: 'none',
            padding: 0,
        }, onBlur: (event) => {
            setFocused(false);
            if (onBlur) {
                onBlur(event);
            }
        }, onFocus: (event) => {
            setFocused(true);
            if (onFocus) {
                onFocus(event);
            }
        }, ...props, ref: ref }));
});
InputContentInput.displayName = 'InputContentInput';
