import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the price in an `OrderSummaryContentDetailListItemPriceLine`
 */
export const OrderSummaryContentDetailListItemPriceLinePrice = forwardRef(({ size, variant, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            color: tokens.styles[`orderSummary-content-detailList-item-priceLine-price-${variant}-${size}-color`],
            fontSize: tokens.styles[`orderSummary-content-detailList-item-priceLine-price-${variant}-${size}-fontSize`],
            fontFamily: tokens.styles[`orderSummary-content-detailList-item-priceLine-price-${variant}-${size}-fontFamily`],
            lineHeight: tokens.styles[`orderSummary-content-detailList-item-priceLine-price-${variant}-${size}-lineHeight`],
            fontWeight: tokens.styles[`orderSummary-content-detailList-item-priceLine-price-${variant}-${size}-fontWeight`],
            letterSpacing: tokens.styles[`orderSummary-content-detailList-item-priceLine-price-${variant}-${size}-letterSpacing`],
        })), ...props, ref: ref }));
});
OrderSummaryContentDetailListItemPriceLinePrice.displayName =
    'OrderSummaryContentDetailListItemPriceLinePrice';
