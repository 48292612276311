import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBarActionsSecondary } from './styles/navigation-bar-actions-secondary';
/**
 * Can be used to display secondary actions.
 */
export const NavigationBarActionsSecondary = forwardRef(({ as: Component = Stack, size, background, actions, menuButton, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarActionsSecondary(tokens)({
        size,
    });
    return (_jsxs(Component, { spacing: "small-xs", ...props, ref: ref, children: [_jsx(Stack, { css: styles, children: actions?.map((action) => action({
                    size,
                    onBackground: background,
                })) }), _jsx("div", { css: getResponsiveStyles(size, (size) => ({
                    display: size === 'sm' ? 'block' : 'none',
                })), children: menuButton({
                    size,
                    onBackground: background,
                }) })] }));
});
NavigationBarActionsSecondary.displayName = 'NavigationBarActionsSecondary';
