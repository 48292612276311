const getIcon = (color) => encodeURIComponent(`<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91423 16.1841L4.65867 11.0418C4.05046 10.4467 3.06436 10.4467 2.45616 11.0418C1.84795 11.6369 1.84795 12.6018 2.45616 13.1969L8.95302 19.5537C9.61325 20.1996 10.702 20.136 11.2789 19.4176L21.6671 6.46536C22.1986 5.8036 22.0811 4.84563 21.4047 4.32568C20.7284 3.80573 19.7493 3.92068 19.2179 4.58244L9.91423 16.1841Z"
        fill="${color}"
      />
    </svg>`);
export const checkbox = (tokens) => () => {
    return [
        {
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            cursor: 'pointer',
            margin: 0,
            width: tokens.styles['checkbox-base-unselected-initial-width'],
            height: tokens.styles['checkbox-base-unselected-initial-height'],
            backgroundColor: tokens.styles['checkbox-base-unselected-initial-background'],
            borderStyle: 'solid',
            borderRadius: tokens.styles['checkbox-base-unselected-initial-borderRadius'],
            borderWidth: tokens.styles['checkbox-base-unselected-initial-borderWidth'],
            borderColor: tokens.styles['checkbox-base-unselected-initial-borderColor'],
            transitionDuration: tokens.styles['interaction-hover-duration'],
            transitionTimingFunction: tokens.styles['interaction-hover-timing'],
            transitionProperty: 'background-color, border-color',
            opacity: tokens.styles['checkbox-base-unselected-initial-opacity'],
            ':hover': {
                width: tokens.styles['checkbox-base-unselected-hover-width'],
                height: tokens.styles['checkbox-base-unselected-hover-height'],
                backgroundColor: tokens.styles['checkbox-base-unselected-hover-background'],
                borderRadius: tokens.styles['checkbox-base-unselected-hover-borderRadius'],
                borderWidth: tokens.styles['checkbox-base-unselected-hover-borderWidth'],
                borderColor: tokens.styles['checkbox-base-unselected-hover-borderColor'],
                opacity: tokens.styles['checkbox-base-unselected-hover-opacity'],
            },
            //pressed
            ':active': {
                width: tokens.styles['checkbox-base-unselected-pressed-width'],
                height: tokens.styles['checkbox-base-unselected-pressed-height'],
                backgroundColor: tokens.styles['checkbox-base-unselected-pressed-background'],
                borderRadius: tokens.styles['checkbox-base-unselected-pressed-borderRadius'],
                borderWidth: tokens.styles['checkbox-base-unselected-pressed-borderWidth'],
                borderColor: tokens.styles['checkbox-base-unselected-pressed-borderColor'],
                opacity: tokens.styles['checkbox-base-unselected-pressed-opacity'],
                transitionDuration: tokens.styles['interaction-pressed-duration'],
                transitionTimingFunction: tokens.styles['interaction-pressed-timing'],
            },
            ':disabled': {
                width: tokens.styles['checkbox-base-unselected-disabled-width'],
                height: tokens.styles['checkbox-base-unselected-disabled-height'],
                backgroundColor: tokens.styles['checkbox-base-unselected-disabled-background'],
                borderRadius: tokens.styles['checkbox-base-unselected-disabled-borderRadius'],
                borderWidth: tokens.styles['checkbox-base-unselected-disabled-borderWidth'],
                borderColor: tokens.styles['checkbox-base-unselected-disabled-borderColor'],
                opacity: tokens.styles['checkbox-base-unselected-disabled-opacity'],
                cursor: 'not-allowed',
            },
            ':checked': {
                width: tokens.styles['checkbox-base-selected-initial-width'],
                height: tokens.styles['checkbox-base-selected-initial-height'],
                backgroundColor: tokens.styles['checkbox-base-selected-initial-background'],
                borderRadius: tokens.styles['checkbox-base-selected-initial-borderRadius'],
                borderWidth: tokens.styles['checkbox-base-selected-initial-borderWidth'],
                borderColor: tokens.styles['checkbox-base-selected-initial-borderColor'],
                opacity: tokens.styles['checkbox-base-selected-initial-opacity'],
                backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['checkbox-icon-selected-initial-background'])}')`,
                backgroundPosition: '50% 50%',
                backgroundSize: '16px',
                backgroundRepeat: 'no-repeat',
                ':hover': {
                    width: tokens.styles['checkbox-base-selected-hover-width'],
                    height: tokens.styles['checkbox-base-selected-hover-height'],
                    backgroundColor: tokens.styles['checkbox-base-selected-hover-background'],
                    borderRadius: tokens.styles['checkbox-base-selected-hover-borderRadius'],
                    borderWidth: tokens.styles['checkbox-base-selected-hover-borderWidth'],
                    borderColor: tokens.styles['checkbox-base-selected-hover-borderColor'],
                    opacity: tokens.styles['checkbox-base-selected-hover-opacity'],
                    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['checkbox-icon-selected-hover-background'])}')`,
                },
                //pressed
                ':active': {
                    width: tokens.styles['checkbox-base-selected-pressed-width'],
                    height: tokens.styles['checkbox-base-selected-pressed-height'],
                    backgroundColor: tokens.styles['checkbox-base-selected-pressed-background'],
                    borderRadius: tokens.styles['checkbox-base-selected-pressed-borderRadius'],
                    borderWidth: tokens.styles['checkbox-base-selected-pressed-borderWidth'],
                    borderColor: tokens.styles['checkbox-base-selected-pressed-borderColor'],
                    opacity: tokens.styles['checkbox-base-selected-pressed-opacity'],
                    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['checkbox-icon-selected-pressed-background'])}')`,
                    transitionDuration: tokens.styles['interaction-pressed-duration'],
                    transitionTimingFunction: tokens.styles['interaction-pressed-timing'],
                },
                ':disabled': {
                    width: tokens.styles['checkbox-base-selected-disabled-width'],
                    height: tokens.styles['checkbox-base-selected-disabled-height'],
                    backgroundColor: tokens.styles['checkbox-base-selected-disabled-background'],
                    borderRadius: tokens.styles['checkbox-base-selected-disabled-borderRadius'],
                    borderWidth: tokens.styles['checkbox-base-selected-disabled-borderWidth'],
                    borderColor: tokens.styles['checkbox-base-selected-disabled-borderColor'],
                    opacity: tokens.styles['checkbox-base-selected-disabled-opacity'],
                    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${getIcon(tokens.styles['checkbox-icon-selected-disabled-background'])}')`,
                },
            },
        },
    ];
};
