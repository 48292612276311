import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `GalleryContent` is the main content of the gallery.
 */
export const GalleryContent = forwardRef(({ size, slider, navigation, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { align: "center", direction: "vertical", css: getResponsiveStyles(size, (size) => ({
            paddingBlockStart: tokens.styles[`gallery-content-base-${size}-paddingTop`],
            paddingInlineStart: tokens.styles[`gallery-content-base-${size}-paddingLeft`],
            paddingInlineEnd: tokens.styles[`gallery-content-base-${size}-paddingRight`],
            gap: tokens.styles[`gallery-content-base-${size}-gap`],
            minHeight: 0,
        })), ...props, ref: ref, children: [slider({ size }), navigation?.({})] }));
});
GalleryContent.displayName = 'GalleryContent';
