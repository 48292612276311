import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationButtonHelper } from './styles/navigation-button-helper';
/**
 * Can be used to set the label on the `NavigationButton`
 */
export const NavigationButtonHelper = forwardRef(({ as: Component = 'span', active, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationButtonHelper(tokens)({
        active: active ? 'active' : 'inactive',
    });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
NavigationButtonHelper.displayName = 'NavigationButtonHelper';
