import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { featureTile } from './styles/feature-tile';
const headerFooterSpacingMap = {
    sm: 'small-lg',
    md: 'small-xl',
    lg: 'small-2xl',
    xl: 'small-3xl',
};
const headlineDescriptionSpacingMap = {
    sm: 'small-xxs',
    md: 'small-xs',
    lg: 'small-sm',
    xl: 'small-md',
};
const descriptionMap = {
    sm: 'sm',
    md: 'sm',
    lg: 'sm',
    xl: 'lg',
};
export const FeatureTile = forwardRef(({ size, variant, imageURL, headline, description, footer, as: Component = Stack, ...props }, ref) => {
    const tokens = useTokens();
    const styles = featureTile(tokens)({ variant, size, imageURL });
    const subcomponentVariant = imageURL ? 'image' : variant;
    return (_jsxs(Component, { css: styles, ...props, ref: ref, direction: "vertical", justify: "space-between", spacing: mapResponsiveValue(size, (size) => headerFooterSpacingMap[size]), children: [description ? (_jsxs(Stack, { direction: "vertical", spacing: mapResponsiveValue(size, (size) => headlineDescriptionSpacingMap[size]), children: [headline({ size, variant: subcomponentVariant }), description({
                        size: mapResponsiveValue(size, (size) => descriptionMap[size]),
                        variant: subcomponentVariant,
                    })] })) : (headline({ size, variant: subcomponentVariant })), footer({ variant: subcomponentVariant })] }));
});
FeatureTile.displayName = 'FeatureTile';
