import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { editorialTeaserContentHeadline } from './styles/editorial-teaser-content-headline';
/**
 * Should be used to display the headline in `EditorialTeaserContent`.
 */
export const EditorialTeaserContentHeadline = forwardRef(({ as: Component = 'h3', size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = editorialTeaserContentHeadline(tokens)({ size, variant });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
EditorialTeaserContentHeadline.displayName = 'EditorialTeaserContentHeadline';
