import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Heading } from '../../component-heading';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
const headingMap = {
    sm: 'small-5',
    md: 'small-4',
    lg: 'large-4',
    xl: 'large-2',
};
const colorMap = {
    default: 'surface-onBase-100',
    highlighted: 'surface-onBase-50',
    image: 'light-900',
};
export const FeatureTileHeadline = forwardRef(({ size, variant, ...props }, ref) => {
    return (_jsx(Heading, { size: mapResponsiveValue(size, (size) => headingMap[size]), color: colorMap[variant], style: {
            lineClamp: 3,
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
        }, ...props, ref: ref }));
});
FeatureTileHeadline.displayName = 'FeatureTileHeadline';
