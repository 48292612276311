import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { icon } from './styles/icon';
/**
 * The `Icon` component is used to render Icons. The size for the icon is determined by the font size token, as icons are often paired with various text labels.
 */
export const Icon = forwardRef(({ size, color, emphasis, as: Component = 'svg', ...props }, ref) => {
    const tokens = useTokens();
    const styles = icon(tokens)({ size, color, emphasis });
    return _jsx(Component, { css: styles, color: color, ...props, ref: ref });
});
Icon.displayName = 'Icon';
