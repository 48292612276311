import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const AccordionItemDivider = forwardRef(({ as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            background: tokens.styles['accordion-divider-background'],
            height: tokens.styles['accordion-divider-height'],
        }, ...props, ref: ref }));
});
AccordionItemDivider.displayName = 'AccordionItemDivider';
