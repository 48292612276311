import BoschSansBoldTtf from './BoschSans-Bold.ttf';
import BoschSansBoldWoff from './BoschSans-Bold.woff';
import BoschSansBoldWoff2 from './BoschSans-Bold.woff2';
import BoschSansRegularTtf from './BoschSans-Regular.ttf';
import BoschSansRegularWoff from './BoschSans-Regular.woff';
import BoschSansRegularWoff2 from './BoschSans-Regular.woff2';
const fonts = [
    {
        ttf: BoschSansBoldTtf,
        woff: BoschSansBoldWoff,
        woff2: BoschSansBoldWoff2,
        family: 'Bosch Sans',
        weight: 'bold',
        style: 'normal',
    },
    {
        ttf: BoschSansRegularTtf,
        woff: BoschSansRegularWoff,
        woff2: BoschSansRegularWoff2,
        family: 'Bosch Sans',
        weight: 'normal',
        style: 'normal',
    },
];
export default fonts;
