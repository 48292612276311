import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const AvatarImage = forwardRef(({ as: Component = 'img', size, ...props }, ref) => {
    const tokens = useTokens();
    const sizeMap = {
        xs: tokens.size['small-lg'],
        sm: tokens.size['small-xl'],
        md: tokens.size['small-2xl'],
        lg: tokens.size['small-3xl'],
    };
    return (_jsx(Component, { css: [
            { objectFit: 'cover', borderRadius: tokens.radii.full },
            getResponsiveStyles(size, (size) => ({
                width: sizeMap[size],
                height: sizeMap[size],
            })),
        ], ...props, ref: ref }));
});
AvatarImage.displayName = 'AvatarImage';
