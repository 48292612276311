import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../..//component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const HintContent = forwardRef(({ icon, description, ...props }, ref) => {
    const tokens = useTokens();
    return icon ? (_jsxs(Stack, { direction: "horizontal", justify: "flex-start", align: "center", css: {
            gap: tokens.styles['hint-content-base-gap'],
        }, ...props, ref: ref, children: [icon?.({}), description({})] })) : (description({}));
});
HintContent.displayName = 'HintContent';
