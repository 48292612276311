import { mergeStyles } from '../../../component-themes';
export const navigationBarActionsPrimaryListItem = (tokens) => ({ active = false, onBackground, }) => {
    const state = active ? 'active' : 'inactive';
    return mergeStyles([
        {
            cursor: 'pointer',
            lineClamp: 1,
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
            overflow: 'hidden',
            transition: `${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
            ': active': {
                transition: `${tokens.styles['interaction-pressed-duration']} ${tokens.styles['interaction-pressed-timing']}`,
            },
            color: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-color`],
            opacity: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-opacity`],
            fontSize: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-fontSize`],
            fontFamily: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-fontFamily`],
            fontWeight: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-fontWeight`],
            lineHeight: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-lineHeight`],
            letterSpacing: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-initial-letterSpacing`],
            ':hover': {
                color: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-color`],
                opacity: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-opacity`],
                fontSize: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-fontSize`],
                fontFamily: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-fontFamily`],
                fontWeight: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-fontWeight`],
                lineHeight: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-lineHeight`],
                letterSpacing: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-hover-letterSpacing`],
            },
            ':active': {
                color: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-color`],
                opacity: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-opacity`],
                fontSize: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-fontSize`],
                fontFamily: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-fontFamily`],
                fontWeight: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-fontWeight`],
                lineHeight: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-lineHeight`],
                letterSpacing: tokens.styles[`navigation-bar-actions-primary-navigationList-item-${onBackground}-${state}-pressed-letterSpacing`],
            },
        },
    ]);
};
