import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBarActionsPrimaryListItem } from './styles/navigation-bar-actions-primary-list-item';
/**
 * An item within the navigation list which links to another page.
 */
export const NavigationBarActionsPrimaryListItem = forwardRef(({ as: Component = 'li', active, onBackground, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarActionsPrimaryListItem(tokens)({
        active,
        onBackground,
    });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
NavigationBarActionsPrimaryListItem.displayName =
    'NavigationBarActionsPrimaryListItem';
