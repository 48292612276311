import { getResponsiveStyles } from '../../../component-themes';
export const navigationBarButtonActionBase = (tokens) => ({ variant, onBackground, size, }) => {
    return (size && {
        borderColor: tokens.styles[`navigation-buttonAction-borderColor-${onBackground}-${variant}-initial`],
        background: tokens.styles[`navigation-buttonAction-base-${onBackground}-${variant}-initial`],
        color: tokens.styles[`navigation-buttonAction-onBase-${onBackground}-${variant}-initial`],
        ':hover': {
            borderColor: tokens.styles[`navigation-buttonAction-borderColor-${onBackground}-${variant}-hover`],
            background: tokens.styles[`navigation-buttonAction-base-${onBackground}-${variant}-hover`],
            color: tokens.styles[`navigation-buttonAction-onBase-${onBackground}-${variant}-hover`],
            ':focus': {
                borderColor: tokens.styles[`navigation-buttonAction-borderColor-${onBackground}-${variant}-focused`],
                background: tokens.styles[`navigation-buttonAction-base-${onBackground}-${variant}-focused`],
                color: tokens.styles[`navigation-buttonAction-onBase-${onBackground}-${variant}-focused`],
            },
            ':active': {
                borderColor: tokens.styles[`navigation-buttonAction-borderColor-${onBackground}-${variant}-pressed`],
                background: tokens.styles[`navigation-buttonAction-base-${onBackground}-${variant}-pressed`],
                color: tokens.styles[`navigation-buttonAction-onBase-${onBackground}-${variant}-pressed`],
            },
            ':disabled': {
                borderColor: tokens.styles[`navigation-buttonAction-borderColor-${onBackground}-${variant}-disabled`],
                background: tokens.styles[`navigation-buttonAction-base-${onBackground}-${variant}-disabled`],
                color: tokens.styles[`navigation-buttonAction-onBase-${onBackground}-${variant}-disabled`],
            },
        },
    });
};
export const navigationBarButtonAction = (tokens) => ({ variant, onBackground, size, }) => getResponsiveStyles(size, (size) => navigationBarButtonActionBase(tokens)({ variant, onBackground, size }));
