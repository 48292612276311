import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { Children, forwardRef } from 'react';
/**
 * @deprecated
 * Use `PaymentList` instead.
 */
export const PaymentIcons = forwardRef(({ icons, ...props }, ref) => {
    return (_jsx(Stack, { spacing: "small-md", ...props, ref: ref, children: Children.map(icons.map((icon) => icon({})), (icon) => icon) }));
});
PaymentIcons.displayName = 'PaymentIcons';
