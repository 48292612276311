import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from '../../component-text';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
const styleMap = {
    sm: { textAlign: 'center' },
    lg: { textAlign: 'start' },
};
/**
 * Is used to display text within the `FooterNewsletter`.
 */
export const FooterNewsletterText = forwardRef(({ size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Text, { variant: "default", size: "sm", css: [
            getResponsiveStyles(size, (size) => styleMap[size]),
            { color: tokens.styles['footer-social-text-background'] },
        ], ...props, ref: ref }));
});
FooterNewsletterText.displayName = 'FooterNewsletterText';
