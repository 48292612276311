import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ProductImage } from '../../component-product-image';
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniContentProductImage = forwardRef(({ size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(ProductImage, { padding: "none", aspectRatio: "4:5", css: getResponsiveStyles(size, (size) => ({
            width: tokens.styles[`productTeaserMini-content-productImage-${size}-width`],
            objectFit: 'contain',
        })), ...props, ref: ref }));
});
ProductTeaserMiniContentProductImage.displayName =
    'ProductTeaserMiniContentProductImage';
