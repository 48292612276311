import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniServicesItemPrice = forwardRef(({ size, variant, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            color: tokens.styles[`productTeaserMini-services-item-price-${size}-${variant}-color`],
            fontFamily: tokens.styles[`productTeaserMini-services-item-price-${size}-${variant}-fontFamily`],
            fontWeight: tokens.styles[`productTeaserMini-services-item-price-${size}-${variant}-fontWeight`],
            lineHeight: tokens.styles[`productTeaserMini-services-item-price-${size}-${variant}-lineHeight`],
            letterSpacing: tokens.styles[`productTeaserMini-services-item-price-${size}-${variant}-letterSpacing`],
            fontSize: tokens.styles[`productTeaserMini-services-item-price-${size}-${variant}-fontSize`],
        })), ...props, ref: ref }));
});
ProductTeaserMiniServicesItemPrice.displayName =
    'ProductTeaserMiniServicesItemPrice';
