import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * @deprecated
 */
export const PaymentIcon = forwardRef(({ as: Component = 'img', ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: {
            backgroundColor: tokens.colors['light-900'],
            display: 'inline-flex',
            boxSizing: 'border-box',
            borderRadius: tokens.radii.xs,
            height: tokens.size['small-xl'],
            width: tokens.size['small-2xl'],
            border: `solid ${tokens.borderWidth.xs} ${tokens.colors['surface-base-300']}`,
            padding: tokens.space['small-xxs'],
            objectFit: 'contain',
        }, ...props, ref: ref }));
});
PaymentIcon.displayName = 'PaymentIcon';
