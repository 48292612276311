import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display information like offers, coupon codes etc. within the navigation.
 */
export const NavigationInfoBar = forwardRef(({ size, content, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { css: getResponsiveStyles(size, (size) => ({
            paddingBlockStart: tokens.styles[`navigation-infobar-base-${size}-paddingTop`],
            paddingBlockEnd: tokens.styles[`navigation-infobar-base-${size}-paddingBottom`],
            paddingInlineStart: tokens.styles[`navigation-infobar-base-${size}-paddingLeft`],
            paddingInlineEnd: tokens.styles[`navigation-infobar-base-${size}-paddingRight`],
            backgroundColor: tokens.styles[`navigation-infobar-base-${size}-background`],
            cursor: 'pointer',
        })), ...props, ref: ref, children: content({ size }) }));
});
NavigationInfoBar.displayName = 'NavigationInfoBar';
