import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Info } from '../../component-icons';
import { Link } from '../../component-link';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Used to show the link within `ProductTitle`.
 */
export const ProductTitleLink = forwardRef(({ size, ...props }, ref) => {
    return (_jsx(Link, { variant: "neutral", icon: Info, alignIcon: "right", size: mapResponsiveValue(size, (size) => (size === 'sm' ? 'xs' : 'sm')), ...props, ref: ref }));
});
ProductTitleLink.displayName = 'ProductTitleLink';
