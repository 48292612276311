import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the price line in `OrderSummaryFooterSummaryList`
 */
export const OrderSummaryFooterSummaryListPriceLine = forwardRef(({ size, label, price, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { css: getResponsiveStyles(size, (size) => ({
            gap: tokens.styles[`orderSummary-footer-summaryList-priceLine-base-${size}-gap`],
            flex: 1,
        })), justify: "space-between", ...props, ref: ref, children: [label({ size }), price?.({ size })] }));
});
OrderSummaryFooterSummaryListPriceLine.displayName =
    'OrderSummaryFooterSummaryListPriceLine';
