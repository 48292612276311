import { getResponsiveStyles, mergeStyles } from '../../../component-themes';
export const accordionItemButtonBase = (tokens) => ({ size, extended, state, }) => {
    const extendedState = extended ? 'open' : 'closed';
    return mergeStyles([
        {
            width: '100%',
            cursor: 'pointer',
            border: 'none',
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: tokens.colors['surface-onBase-50'],
            transition: `all ${tokens.animation['duration-quick']} ${tokens.animation['timing-soft']}`,
            paddingBlockStart: tokens.styles[`accordion-button-base-${size}-${extendedState}-${state}-paddingTop`],
            paddingInlineEnd: tokens.styles[`accordion-button-base-${size}-${extendedState}-${state}-paddingRight`],
            paddingBlockEnd: tokens.styles[`accordion-button-base-${size}-${extendedState}-${state}-paddingBottom`],
            paddingInlineStart: tokens.styles[`accordion-button-base-${size}-${extendedState}-${state}-paddingLeft`],
        },
        extended
            ? {
                '@media (hover: hover)': {
                    '&:hover > svg': {
                        transform: `rotateZ(-90deg) ${tokens.transform['scale-down-lg']}`,
                    },
                },
            }
            : {
                '@media (hover: hover)': {
                    '&:hover > svg': {
                        transform: `rotateZ(-90deg) ${tokens.transform['scale-down-lg']}`,
                    },
                },
            },
    ]);
};
export const accordionItemButton = (tokens) => ({ size, extended, state, }) => getResponsiveStyles(size, (size) => accordionItemButtonBase(tokens)({ size, extended, state }));
