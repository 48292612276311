import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Box } from '../../component-box';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * This component can be used to display an image of the logo of a social media plattform.
 */
export const SocialMediaIcon = forwardRef(({ ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Box, { as: "img", style: { height: tokens.size['small-lg'], display: 'block' }, ...props, ref: ref }));
});
SocialMediaIcon.displayName = 'SocialMediaIcon';
