import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { getResponsiveStyles } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The Gallery can be used as a stand alone Gallery or as a Gallery with thumbnails.
 *
 * If swiping functionality is needed please use the functional Gallery component from the snowflake library: https://github.com/bshgroup/snowflake/tree/main/packages/gallery
 */
export const Gallery = forwardRef(({ size, badgeList, content, thumbList, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { css: { position: 'relative' }, direction: "vertical", spacing: "small-xl", ...props, ref: ref, children: [badgeList && (_jsx("div", { css: getResponsiveStyles(size, (size) => ({
                    zIndex: 2,
                    position: 'absolute',
                    insetBlockStart: {
                        sm: tokens.space['small-md'],
                        md: tokens.space['small-xl'],
                        lg: tokens.space['small-xl'],
                    }[size],
                    insetInlineStart: {
                        sm: tokens.space['small-md'],
                        md: tokens.space['small-xl'],
                        lg: tokens.space['small-2xl'],
                    }[size],
                })), children: badgeList?.({}) })), content({ size }), thumbList?.({ size })] }));
});
Gallery.displayName = 'Gallery';
