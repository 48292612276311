import { getResponsiveStyles } from '../../../component-themes';
const badgeIconBase = (tokens) => ({ variant, size, }) => {
    return {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: tokens.styles[`badge-${variant}-base-${size}-background`],
        height: tokens.styles[`badge-${variant}-base-${size}-height`],
        paddingInlineStart: tokens.styles[`badge-${variant}-base-${size}-paddingLeft`],
        paddingInlineEnd: tokens.styles[`badge-${variant}-base-${size}-paddingRight`],
        borderRadius: tokens.styles[`badge-${variant}-base-${size}-borderRadius`],
    };
};
export const badgeIcon = (tokens) => ({ variant, size, }) => {
    return getResponsiveStyles(size, (size) => badgeIconBase(tokens)({ size, variant }));
};
