import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
/**
 * Should be used to display a (non product) image in the image slot of `EditorialTeaserMedia`.
 */
export const EditorialTeaserMediaImage = forwardRef(({ as: Component = 'img', height, withImagePadding, ...props }, ref) => {
    return (_jsx(Component, { css: {
            width: '100%',
            objectFit: height === 'fill'
                ? withImagePadding
                    ? 'contain'
                    : 'cover'
                : 'fill',
            height: height === 'fill' ? '100%' : 'auto',
            aspectRatio: height === 'fill' ? 'unset' : '4 / 3 ',
        }, ...props, ref: ref }));
});
EditorialTeaserMediaImage.displayName = 'EditorialTeaserMediaImage';
