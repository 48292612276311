import { combineResponsiveValues, getResponsiveStyles, mergeStyles, } from '../../../component-themes';
export const modalBase = (tokens) => ({ size, fullscreen, }) => ({
    container: mergeStyles([
        {
            inset: 0,
            display: 'grid',
            position: 'fixed',
            placeItems: 'center',
            overflowY: 'auto',
            padding: fullscreen
                ? 0
                : tokens.size[size === 'sm'
                    ? 'small-md'
                    : size === 'md'
                        ? 'small-lg'
                        : 'small-xl'],
        },
    ]),
    modal: mergeStyles([
        {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: tokens.colors['surface-base-50'],
            boxSizing: 'border-box',
            transition: `${tokens.animation['duration-fast']} ${tokens.animation['timing-soft']}`,
            outline: `1px solid ${tokens.colors['surface-base-100']}`,
            boxShadow: tokens.shadow['boxShadow-bottom-lg']['box-shadow'],
        },
        size === 'sm' && !fullscreen
            ? {
                width: 'min(42rem, 100%)',
                borderRadius: tokens.radii.xs,
            }
            : {},
        size === 'md' && !fullscreen
            ? {
                width: 'min(50rem, 100%)',
                borderRadius: tokens.radii.xs,
            }
            : {},
        size === 'lg' && !fullscreen
            ? {
                width: 'min(60rem, 100%)',
                borderRadius: tokens.radii.xs,
            }
            : {},
        size === 'sm' && fullscreen
            ? {
                minWidth: '100%',
                width: '100%',
                height: '100%',
            }
            : {},
        size === 'md' && fullscreen
            ? {
                minWidth: '100%',
                width: '100%',
                height: '100%',
            }
            : {},
        size === 'lg' && fullscreen
            ? {
                minWidth: '100%',
                width: '100%',
                height: '100%',
            }
            : {},
    ]),
});
export const modal = (tokens) => ({ size, fullscreen, }) => ({
    container: getResponsiveStyles(combineResponsiveValues(size, fullscreen), ([size, fullscreen]) => modalBase(tokens)({ size, fullscreen }).container),
    modal: getResponsiveStyles(combineResponsiveValues(size, fullscreen), ([size, fullscreen]) => modalBase(tokens)({ size, fullscreen }).modal),
});
