import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { Children, forwardRef } from 'react';
import { ProductTeaserMiniServicesDivider } from './product-teaser-mini-services-divider';
export const ProductTeaserMiniServices = forwardRef(({ as: Component = 'div', size, items, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: tokens.styles[`productTeaserMini-services-base-${size}-gap`],
        })), ...props, ref: ref, children: Children.map(items.map((item) => item?.({ size })), (child, index) => (_jsxs(_Fragment, { children: [child, items.length - 1 !== index ? (_jsx(ProductTeaserMiniServicesDivider, {})) : undefined] }))) }));
});
ProductTeaserMiniServices.displayName = 'ProductTeaserMiniServices';
