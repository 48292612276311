import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Divider } from '../../component-divider';
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
import { footer } from './styles/footer';
const trustedShopsIconsMap = {
    sm: 'sm',
    md: 'sm',
    lg: 'lg',
};
const copyRightMap = {
    sm: 'sm',
    md: 'sm',
    lg: 'lg',
};
/**
 * The `Footer` component is used to display the footer at the bottom of the page.
 */
export const Footer = forwardRef(({ size, headline, newsletter, socialMediaIcons, links, accordion, trustedShopsIcons, paymentIcons, breadcrumb, breadcrumbBackLink, legalInformation, copyright, legalLinks, as: Component = 'div', ...props }, ref) => {
    const tokens = useTokens();
    const styles = footer(tokens)({
        size,
    });
    return (_jsxs(Component, { css: styles, ...props, ref: ref, children: [headline({ size }), _jsxs(Stack, { direction: mapResponsiveValue(size, (size) => size === 'lg' ? 'horizontal' : 'vertical'), justify: "flex-start", align: mapResponsiveValue(size, (size) => size === 'lg' ? 'flex-start' : 'center'), spacing: "small-2xl", children: [_jsxs(Stack, { spacing: mapResponsiveValue(size, (size) => size === 'sm' ? 'small-xl' : 'small-2xl'), direction: "vertical", css: { maxWidth: '464px' }, children: [newsletter?.({ size }), socialMediaIcons?.({ size })] }), _jsx(Stack, { css: [
                            getResponsiveStyles(size, (size) => size === 'lg' ? { display: 'flex' } : { display: 'none' }),
                            { width: '100%' },
                        ], justify: "center", children: _jsx(Stack, { justify: "space-between", spacing: "small-2xl", css: [
                                { width: '100%', maxWidth: '80rem' },
                                getResponsiveStyles(size, (size) => size === 'lg' ? { padding: '0 5.5rem' } : { padding: 0 }),
                            ], children: links?.map((linkList) => linkList({})) }) })] }), _jsx("div", { css: [
                    getResponsiveStyles(size, (size) => size === 'lg' ? { display: 'none' } : { display: 'block' }),
                    { width: '100%' },
                ], children: accordion({ size: 'sm' }) }), _jsx(Divider, { emphasis: "low", orientation: "horizontal", css: [
                    { backgroundColor: tokens.styles['footer-divider-background'] },
                    getResponsiveStyles(size, (size) => size === 'lg'
                        ? {
                            display: 'block',
                        }
                        : {
                            display: 'none',
                        }),
                ] }), trustedShopsIcons && paymentIcons ? (_jsxs(Stack, { direction: mapResponsiveValue(size, (size) => size === 'sm' ? 'vertical' : 'horizontal'), align: "stretch", spacing: mapResponsiveValue(size, (size) => size === 'sm' ? 'small-xl' : 'small-2xl'), children: [trustedShopsIcons?.({
                        size: mapResponsiveValue(size, (size) => trustedShopsIconsMap[size]),
                    }), _jsx(Divider, { emphasis: "high", orientation: "horizontal", css: {
                            display: size === 'sm' ? 'inline' : 'none',
                            backgroundColor: tokens.styles['footer-divider-background'],
                        } }), _jsx("div", { css: getResponsiveStyles(size, (size) => size === 'sm'
                            ? { display: 'none' }
                            : { display: 'flex', alignItems: 'center' }), children: _jsx(Divider, { emphasis: "low", orientation: "vertical", css: {
                                backgroundColor: tokens.styles['footer-divider-background'],
                            } }) }), paymentIcons && _jsx(Stack, { align: "center", children: paymentIcons?.({}) })] })) : undefined, trustedShopsIcons && !paymentIcons
                ? trustedShopsIcons?.({
                    size: mapResponsiveValue(size, (size) => trustedShopsIconsMap[size]),
                })
                : undefined, !trustedShopsIcons && paymentIcons ? paymentIcons?.({}) : undefined, _jsx("div", { css: getResponsiveStyles(size, (size) => size === 'sm' ? { display: 'none' } : { display: 'block' }), children: breadcrumb({}) }), _jsx("div", { css: getResponsiveStyles(size, (size) => size !== 'sm' ? { display: 'none' } : { display: 'block' }), children: breadcrumbBackLink({}) }), legalInformation && (_jsx("div", { css: getResponsiveStyles(size, (size) => size === 'lg' ? { maxWidth: '640px' } : {}), children: legalInformation?.({}) })), _jsxs(Stack, { direction: "vertical", spacing: mapResponsiveValue(size, (size) => size === 'sm' ? 'small-xl' : 'small-lg'), children: [copyright({
                        size: mapResponsiveValue(size, (size) => copyRightMap[size]),
                    }), legalLinks({ size })] })] }));
});
Footer.displayName = 'Footer';
