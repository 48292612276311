import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display the label of `InputContent`.
 */
export const InputContentLabel = forwardRef(({ as: Component = 'div', disabled, focused, filled, hovered, ...props }, ref) => {
    const tokens = useTokens();
    const state = disabled
        ? filled
            ? 'filled-disabled'
            : 'initial-disabled'
        : focused
            ? 'focused'
            : filled
                ? 'filled'
                : hovered
                    ? 'hover'
                    : 'initial';
    return (_jsx(Component, { css: {
            color: tokens.styles[`input-content-label-${state}-color`],
            fontFamily: tokens.styles[`input-content-label-${state}-fontFamily`],
            fontSize: tokens.styles[`input-content-label-${state}-fontSize`],
            fontWeight: tokens.styles[`input-content-label-${state}-fontWeight`],
            letterSpacing: tokens.styles[`input-content-label-${state}-letterSpacing`],
            lineHeight: tokens.styles[`input-content-label-${state}-lineHeight`],
            opacity: tokens.styles[`input-content-label-${state}-opacity`],
        }, ...props, ref: ref }));
});
InputContentLabel.displayName = 'InputContentLabel';
