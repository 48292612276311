import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { featureListItemHeadline } from './styles/feature-list-item-headline';
/**
 * The `FeatureListItemHeadline` should be used to display a heading label inside of the `FeatureListItem`.
 */
export const FeatureListItemHeadline = forwardRef(({ as: Component = 'div', size, variant, ...props }, ref) => {
    const tokens = useTokens();
    const styles = featureListItemHeadline(tokens)({
        size,
        variant,
    });
    return _jsx(Component, { css: styles, ref: ref, ...props });
});
FeatureListItemHeadline.displayName = 'FeatureListItemHeadline';
