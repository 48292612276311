import { getResponsiveStyles } from '../../../component-themes';
import { mergeStyles } from '../../../component-themes';
export const tabBarItemBase = (tokens) => ({ size, variant, active, }) => {
    const isSelected = active ? 'selected' : 'unselected';
    const isActive = active ? 'active' : 'inactive';
    return {
        wrapper: mergeStyles([
            {
                width: 'auto',
                display: 'inline-flex',
                alignItems: 'center',
                position: 'relative',
                height: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-initial-height`],
                paddingBlock: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-initial-paddingBlock`],
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                ':hover': {
                    opacity: tokens.emphasis.high,
                    filter: [
                        tokens.styles['interaction-hover-brightness'],
                        tokens.styles['interaction-hover-contrast'],
                    ].join(' '),
                },
                ':active': {
                    opacity: tokens.emphasis.medium,
                    filter: [
                        tokens.styles['interaction-pressed-brightness'],
                        tokens.styles['interaction-pressed-contrast'],
                    ].join(' '),
                },
                transitionProperty: 'opacity, filter',
                transitionDuration: tokens.styles['interaction-hover-duration'],
                transitionTimingFunction: tokens.styles['interaction-hover-timing'],
            },
        ]),
        line: mergeStyles([
            {
                display: 'block',
                position: 'absolute',
                insetInlineStart: 0,
                insetInlineEnd: 0,
                insetBlockEnd: 0,
                transition: `width ${tokens.animation['duration-quick']} ${tokens.animation['timing-soft']}`,
                background: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-initial-borderColor`],
                height: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-initial-borderBottomWidth`],
                ':hover': {
                    background: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-hover-borderColor`],
                    height: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-hover-borderBottomWidth`],
                },
                ':active': {
                    background: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-pressed-borderColor`],
                    height: tokens.styles[`tabBar-tab-base-${variant}-${size}-${isSelected}-pressed-borderBottomWidth`],
                },
            },
        ]),
        label: mergeStyles([
            {
                userSelect: 'none',
                margin: 0,
                padding: 0,
                color: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-color`],
                opacity: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-opacity`],
                fontSize: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-fontSize`],
                fontFamily: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-fontFamily`],
                lineHeight: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-lineHeight`],
                fontWeight: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-fontWeight`],
                letterSpacing: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-initial-letterSpacing`],
                ':hover': {
                    color: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-color`],
                    opacity: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-opacity`],
                    fontSize: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-fontSize`],
                    fontFamily: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-fontFamily`],
                    lineHeight: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-lineHeight`],
                    fontWeight: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-fontWeight`],
                    letterSpacing: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-hover-letterSpacing`],
                },
                ':active': {
                    color: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-color`],
                    opacity: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-opacity`],
                    fontSize: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-fontSize`],
                    fontFamily: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-fontFamily`],
                    lineHeight: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-lineHeight`],
                    fontWeight: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-fontWeight`],
                    letterSpacing: tokens.styles[`tabBar-tab-label-${variant}-${size}-${isActive}-pressed-letterSpacing`],
                },
            },
        ]),
    };
};
export const tabBarItem = (tokens) => ({ size, variant, active = false, }) => {
    return {
        wrapper: getResponsiveStyles(size, (size) => tabBarItemBase(tokens)({ size, variant, active }).wrapper),
        line: getResponsiveStyles(size, (size) => tabBarItemBase(tokens)({ size, variant, active }).line),
        label: getResponsiveStyles(size, (size) => tabBarItemBase(tokens)({ size, variant, active }).label),
    };
};
