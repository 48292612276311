import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { text } from './text.styles';
/**
 * The `Text` component is a polymorphic component used to set any body text. It takes the form of a `div` element by default but can be morphed into any other element like `p`, `span`, `em` etc. by using the `as` prop of `StyledComponents`
 */
export const Text = forwardRef(({ size, color, variant, emphasis, as: Component = 'p', ...props }, ref) => {
    const tokens = useTokens();
    const styles = text(tokens)({ variant, size, color, emphasis });
    return _jsx(Component, { css: styles, ...props, ref: ref });
});
Text.displayName = 'Text';
