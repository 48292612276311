import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ButtonAction } from '../../component-button-action';
import { forwardRef } from 'react';
/**
 * `GalleryThumbListThumbButtonAction` component is used to display an action within `GalleryThumbsListThumb`.
 */
export const GalleryThumbListThumbButtonAction = forwardRef((props, ref) => {
    return _jsx(ButtonAction, { size: "sm", variant: "prominent", ...props, ref: ref });
});
GalleryThumbListThumbButtonAction.displayName =
    'GalleryThumbListThumbButtonAction';
