import React, { ReactNode } from 'react';
import { CSSObject } from '@emotion/react';
import AutoOrientationService from '../../../services/autoOrientation';

export type HeadingStyles = 'header' | 'subheader' | 'title' | 'minor';

export interface HeadingProps {
  /**
   * Heading style<br />
   * `header`: Text 2XL Bold (Bosch Styleguide)<br />
   * `subheader`: Text XL Bold (Bosch Styleguide)<br />
   * `title`: Text L Bold (Bosch Styleguide)
   */
  style?: HeadingStyles;
  /**
   * Heading content
   */
  children?: ReactNode;
}

/**
 * The `Heading` component displays a heading with a specific style.
 */
export function Heading({
  style,
  children,
  ...props
}: HeadingProps & Omit<Partial<React.HTMLAttributes<HTMLHeadingElement>>, keyof HeadingProps>) {
  const commonStyles = {
    fontFamily: 'Bosch Sans, sans-serif',
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    margin: 0,
    padding: 0,
  };

  switch (style) {
    case 'subheader':
      return (
        <h2
          {...props}
          css={
            {
              ...commonStyles,
              ...AutoOrientationService.getStyles({ fontSize: '1.25rem' }, { fontSize: '1.5rem' }),
            } as CSSObject
          }
        >
          {children}
        </h2>
      );
    case 'title':
      return (
        <h3
          {...props}
          css={
            {
              ...commonStyles,
              ...AutoOrientationService.getStyles({ fontSize: '1rem' }, { fontSize: '1.25rem' }),
            } as CSSObject
          }
        >
          {children}
        </h3>
      );
    case 'minor':
      return (
        <h4
          {...props}
          css={
            {
              ...commonStyles,
              fontSize: '1rem',
            } as CSSObject
          }
        >
          {children}
        </h4>
      );
    default: // 'header'
      return (
        <h1
          {...props}
          css={
            {
              ...commonStyles,
              ...AutoOrientationService.getStyles({ fontSize: '1.5rem' }, { fontSize: '2rem' }),
            } as CSSObject
          }
        >
          {children}
        </h1>
      );
  }
}
