import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { combineResponsiveValues, getResponsiveStyles, } from '../../component-themes';
import { forwardRef } from 'react';
export const ProductTeaserMiniContent = forwardRef(({ as: Component = 'div', size, alignment, productImage, product, buttonAction, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Component, { css: getResponsiveStyles(combineResponsiveValues(size, alignment), ([size, alignment]) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: alignment === 'top' ? 'flex-start' : 'center',
            gap: tokens.styles[`productTeaserMini-content-base-${size}-gap`],
        })), ...props, ref: ref, children: [productImage({ size }), product({ size }), buttonAction?.({})] }));
});
ProductTeaserMiniContent.displayName = 'ProductTeaserMiniContent';
