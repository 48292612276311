import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { FormField } from '../../component-form-field';
import { forwardRef } from 'react';
/**
 * Component to display a voucher code in an `OrderSummaryContentDetailListItem`
 */
export const OrderSummaryContentDetailListItemVoucherCode = forwardRef(({ size, input, message, ...props }, ref) => {
    return (_jsx(FormField, { control: input
            ? (config) => input({
                ...config,
                size,
                variant: 'default',
            })
            : undefined, message: message
            ? () => message({
                invalid: props.invalid,
                variant: 'default',
            })
            : undefined, ...props, ref: ref }));
});
OrderSummaryContentDetailListItemVoucherCode.displayName =
    'OrderSummaryContentDetailListItemVoucherCode';
