import { combineResponsiveValues, getResponsiveStyles, } from '../../../component-themes';
export const editorialTeaserMedia = (tokens) => ({ size, orientation, withImagePadding, }) => {
    const paddingBlockMap = {
        vertical: {
            sm: tokens.space['small-lg'],
            lg: tokens.space['small-xl'],
        },
        horizontal: {
            sm: tokens.space['small-2xl'],
            lg: tokens.space['small-3xl'],
        },
    };
    const paddingInlineMap = {
        vertical: {
            sm: tokens.space['small-md'],
            lg: tokens.space['small-lg'],
        },
        horizontal: {
            sm: tokens.space['small-xl'],
            lg: tokens.space['small-2xl'],
        },
    };
    return {
        wrapper: [
            { position: 'relative', height: '100%' },
            withImagePadding
                ? getResponsiveStyles(combineResponsiveValues(size, orientation), ([size, orientation]) => ({
                    paddingInline: paddingInlineMap[orientation][size],
                    paddingBlockStart: paddingBlockMap[orientation][size],
                    paddingBlockEnd: orientation === 'horizontal'
                        ? paddingBlockMap[orientation][size]
                        : 0,
                }))
                : {},
        ],
        action: [
            {
                position: 'absolute',
                insetInlineEnd: 0,
                insetBlockEnd: 0,
                insetInlineStart: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            getResponsiveStyles(combineResponsiveValues(size, orientation), ([size, orientation]) => orientation === 'vertical' && withImagePadding
                ? { insetBlockStart: paddingBlockMap[orientation][size] }
                : { insetBlockStart: 0 }),
        ],
    };
};
