import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBarActions } from './styles/navigation-bar-actions';
/**
 * Can be used to display bar actions such as primary and secondary actions.
 */
export const NavigationBarActions = forwardRef(({ as: Component = Stack, size, variant, background, primary, secondary, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBarActions(tokens)({
        size: mapResponsiveValue(size, (size) => (size === 'md' ? 'lg' : 'sm')),
    });
    return (_jsxs(Component, { justify: "space-between", css: styles, ...props, ref: ref, children: [primary?.({
                size: mapResponsiveValue(size, (size) => size === 'sm' ? 'sm' : 'lg'),
                variant,
                background,
            }), secondary?.({
                size: mapResponsiveValue(size, (size) => size === 'sm' ? 'sm' : 'lg'),
                variant,
                background,
            })] }));
});
NavigationBarActions.displayName = 'NavigationBarActions';
