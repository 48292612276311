import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display the title of a product including headline, rating, subline and a link.
 */
export const ProductTitle = forwardRef(({ size, variant, rating, headline, subline, link, ...props }, ref) => {
    return (_jsxs(Stack, { direction: "vertical", spacing: mapResponsiveValue(size, (size) => size === 'lg' || size === 'md' ? 'small-sm' : 'small-xs'), ...props, ref: ref, children: [rating?.({
                size: mapResponsiveValue(size, (size) => ({
                    lg: 'lg',
                    md: 'lg',
                    sm: 'md',
                    xs: 'sm',
                    '2xs': 'sm',
                }[size])),
            }), headline?.({ size, variant }), subline?.({
                size: mapResponsiveValue(size, (size) => ({
                    lg: 'lg',
                    md: 'md',
                    sm: 'sm',
                    xs: 'sm',
                    '2xs': 'xs',
                }[size])),
                variant,
            }), link?.({
                size: mapResponsiveValue(size, (size) => ({
                    lg: 'lg',
                    md: 'lg',
                    sm: 'lg',
                    xs: 'sm',
                    '2xs': 'sm',
                }[size])),
            })] }));
});
ProductTitle.displayName = 'ProductTitle';
