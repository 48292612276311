import { getResponsiveStyles } from '../../../component-themes';
export const editorialTeaserContentHeadline = (tokens) => ({ size, variant, }) => [
    {
        margin: 0,
        lineClamp: 3,
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
    },
    getResponsiveStyles(size, (size) => ({
        color: tokens.styles[`editorialTeaser-content-title-headline-${size}-${variant}-color`],
        fontSize: tokens.styles[`editorialTeaser-content-title-headline-${size}-${variant}-fontSize`],
        fontWeight: tokens.styles[`editorialTeaser-content-title-headline-${size}-${variant}-fontWeight`],
        fontFamily: tokens.styles[`editorialTeaser-content-title-headline-${size}-${variant}-fontFamily`],
        lineHeight: tokens.styles[`editorialTeaser-content-title-headline-${size}-${variant}-lineHeight`],
        letterSpacing: tokens.styles[`editorialTeaser-content-title-headline-${size}-${variant}-letterSpacing`],
    })),
];
