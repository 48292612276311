import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
import { priceStrikethrough } from './styles/price-strikethrough';
/**
 * Can be used to show a striked through price indicating the price of a product before it was discounted.
 */
export const PriceStrikethrough = forwardRef(({ as: Component = 'div', size, ...props }, ref) => {
    const tokens = useTokens();
    const styles = priceStrikethrough(tokens)({ size });
    return _jsx(Component, { css: styles, ref: ref, ...props });
});
PriceStrikethrough.displayName = 'PriceStrikethrough';
