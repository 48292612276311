import { jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
const footerSizeMap = {
    xs: 'sm',
    sm: 'sm',
    md: 'lg',
    lg: 'lg',
};
/**
 * Should be used to display the content of the `EditorialTeaser`.
 */
export const EditorialTeaserContent = forwardRef(({ size, variant, headline, description, footer, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: "vertical", justify: "space-between", css: [
            {
                height: '100%',
            },
            getResponsiveStyles(size, (size) => ({
                gap: tokens.styles[`editorialTeaser-content-base-${size}-${variant}-gap`],
            })),
        ], ...props, ref: ref, children: [headline && description ? (_jsxs(Stack, { direction: "vertical", css: getResponsiveStyles(size, (size) => ({
                    gap: tokens.styles[`editorialTeaser-content-title-base-${size}-${variant}-gap`],
                })), children: [headline?.({ size, variant }), description?.({ variant })] })) : (_jsxs(_Fragment, { children: [headline?.({ size, variant }), description?.({ variant })] })), footer?.({
                size: mapResponsiveValue(size, (size) => footerSizeMap[size]),
                variant,
            })] }));
});
EditorialTeaserContent.displayName = 'EditorialTeaserContent';
