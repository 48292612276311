import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { Children, forwardRef } from 'react';
/**
 * Used to display a list of `EditorialTeaserContentFooterTag` components.
 */
export const EditorialTeaserContentFooterTags = forwardRef(({ tags, variant, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Stack, { spacing: "small-md", css: {
            flexWrap: 'wrap-reverse',
            overflow: 'hidden',
            justifyContent: 'end',
            height: tokens.size['small-lg'],
        }, ...props, ref: ref, children: Children.map(tags.map((tag) => tag({ variant })), (tag) => tag) }));
});
EditorialTeaserContentFooterTags.displayName =
    'EditorialTeaserContentFooterTags';
