import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { getResponsiveStyles, mapResponsiveValue, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
import { navigationBar } from './styles/navigation-bar';
/**
 * Can be used to display navigation bar which contains logo, menuButton and other components.
 */
export const NavigationBar = forwardRef(({ as: Component = 'div', size, variant, background, brandArea, actions, progressSteps, ...props }, ref) => {
    const tokens = useTokens();
    const styles = navigationBar(tokens)({
        variant,
        size,
        background,
    });
    return (_jsxs(Component, { css: styles, ...props, ref: ref, children: [brandArea?.({
                variant,
                size: mapResponsiveValue(size, (size) => size === 'md' ? 'lg' : size),
                background,
            }), variant === 'progress' ? (_jsx(Stack, { direction: "horizontal", justify: "center", css: {
                    alignSelf: 'stretch',
                }, children: _jsx("div", { css: getResponsiveStyles(size, (size) => ({
                        flex: 1,
                        paddingInlineEnd: tokens.styles[`navigation-bar-brandArea-base-${size === 'sm' ? 'sm' : 'lg'}-paddingRight`],
                        maxWidth: size === 'lg' || size === 'md'
                            ? tokens.styles[`navigation-progressSteps-base-lg-maxWidth`]
                            : 'auto',
                        paddingBlockStart: tokens.styles[`navigation-progressSteps-base-${size === 'sm' ? 'sm' : 'lg'}-paddingTop`],
                        paddingBlockEnd: tokens.styles[`navigation-progressSteps-base-${size === 'sm' ? 'sm' : 'lg'}-paddingBottom`],
                    })), children: progressSteps?.({
                        size: mapResponsiveValue(size, (size) => size === 'sm' ? 'sm' : 'lg'),
                        onBackground: background,
                    }) }) })) : (actions?.({ variant, size, background }))] }));
});
NavigationBar.displayName = 'NavigationBar';
