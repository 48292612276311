import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles, useTokens } from '../../component-themes';
import { forwardRef } from 'react';
export const Brandline = forwardRef(({ as: Component = 'img', size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: [
            {
                width: '100%',
                display: 'block',
                objectFit: 'cover',
            },
            getResponsiveStyles(size, (size) => ({
                height: size === 'sm'
                    ? tokens.size['small-xxs']
                    : tokens.size['small-xs'],
            })),
        ], ...props, ref: ref }));
});
Brandline.displayName = 'Brandline';
