import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { getResponsiveStyles } from '../../component-themes';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Component to display the label in `OrderSummaryFooterSummaryListPriceLine`
 */
export const OrderSummaryFooterSummaryListPriceLineLabel = forwardRef(({ as: Component = 'div', size, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(size, (size) => ({
            color: tokens.styles[`orderSummary-footer-summaryList-priceLine-label-${size}-color`],
            fontSize: tokens.styles[`orderSummary-footer-summaryList-priceLine-label-${size}-fontSize`],
            fontFamily: tokens.styles[`orderSummary-footer-summaryList-priceLine-label-${size}-fontFamily`],
            lineHeight: tokens.styles[`orderSummary-footer-summaryList-priceLine-label-${size}-lineHeight`],
            fontWeight: tokens.styles[`orderSummary-footer-summaryList-priceLine-label-${size}-fontWeight`],
            letterSpacing: tokens.styles[`orderSummary-footer-summaryList-priceLine-label-${size}-letterSpacing`],
        })), ...props, ref: ref }));
});
OrderSummaryFooterSummaryListPriceLineLabel.displayName =
    'OrderSummaryFooterSummaryListPriceLineLabel';
