import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `InputContent` should be used to display the content of an input field.
 */
export const InputContent = forwardRef(({ variant, input, label, disabled, focused, filled, hovered, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsxs(Stack, { direction: "vertical", css: {
            width: '100%',
            position: 'relative',
            gap: tokens.styles[`input-content-base-${variant}-gap`],
            paddingInlineStart: tokens.styles[`input-content-base-${variant}-paddingLeft`],
            paddingInlineEnd: tokens.styles[`input-content-base-${variant}-paddingRight`],
        }, ...props, ref: ref, children: [_jsx("div", { css: {
                    position: !(focused || filled) ? 'absolute' : 'relative',
                    display: variant === 'reduced' && (focused || filled) ? 'none' : 'block',
                }, children: label({ disabled, focused, filled, hovered }) }), input({ disabled })] }));
});
InputContent.displayName = 'InputContent';
