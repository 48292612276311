import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useActiveState, useFocusedState, useHoveredState, } from '../../component-hooks';
import { Stack } from '../../component-stack';
import { useTokens } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Can be used to display a country selector as an indicator in the `Input` component.
 */
export const InputIndicatorCountrySelector = forwardRef(({ flag, icon, disabled, 
// we need to remove the props from the IndicatorProps type, because they are not used by the component
// otherwise they are spread and lead to a console warning
// eslint-disable-next-line @typescript-eslint/no-unused-vars
focused, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
filled, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
hovered, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
invalid, onClick, ...props }, ref) => {
    const { active: pressed, ...activeProps } = useActiveState(props);
    const { hovered: hover, ...hoveredProps } = useHoveredState(props);
    const { focused: focus, ...focusedProps } = useFocusedState(props);
    const state = disabled
        ? 'disabled'
        : pressed
            ? 'pressed'
            : hover
                ? 'hover'
                : focus
                    ? 'focused'
                    : 'initial';
    const tokens = useTokens();
    return (_jsxs(Stack, { align: "center", css: {
            flexShrink: 0,
            height: '100%',
            width: 'fit-content',
            cursor: 'pointer',
            gap: tokens.styles['input-indicator-countrySelector-base-gap'],
            paddingLeft: tokens.styles['input-indicator-countrySelector-base-paddingLeft'],
            paddingRight: tokens.styles['input-indicator-countrySelector-base-paddingRight'],
            ':focus': { outline: 'none' },
            '-webkit-tap-highlight-color': 'transparent',
        }, ...props, ...activeProps, ...hoveredProps, ...focusedProps, onClick: (event) => {
            event.preventDefault();
            if (onClick) {
                onClick(event);
            }
        }, ref: ref, children: [flag({ state }), icon({ state }), _jsx("div", { css: {
                    backgroundColor: tokens.styles[`input-indicator-countrySelector-divider-${state}-background`],
                    opacity: tokens.styles[`input-indicator-countrySelector-divider-${state}-opacity`],
                    width: tokens.styles[`input-indicator-countrySelector-divider-${state}-width`],
                    height: tokens.styles[`input-indicator-countrySelector-divider-${state}-height`],
                    transition: state === 'pressed'
                        ? `all ${tokens.styles['interaction-pressed-duration']} ${tokens.styles['interaction-pressed-timing']}`
                        : `all ${tokens.styles['interaction-hover-duration']} ${tokens.styles['interaction-hover-timing']}`,
                } })] }));
});
InputIndicatorCountrySelector.displayName = 'InputIndicatorCountrySelector';
