import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Button } from '../../component-button';
import { ArrowRight } from '../../component-icons';
import { mapResponsiveValue } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * Should be used to display a button in the `EditorialTeaserContentFooter`.
 */
export const EditorialTeaserContentFooterButton = forwardRef(({ size, ...props }, ref) => {
    return (_jsx(Button, { size: mapResponsiveValue(size, (size) => (size === 'lg' ? 'md' : 'sm')), variant: "default", icon: ArrowRight, alignIcon: "right", style: { flexShrink: 0 }, ...props, ref: ref }));
});
EditorialTeaserContentFooterButton.displayName =
    'EditorialTeaserContentFooterButton';
