import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { combineResponsiveValues, getResponsiveStyles, useTokens, } from '../../component-themes';
import { forwardRef } from 'react';
/**
 * The `Container` component should be used to create a page layout. It renders a `div` tag.
 */
export const Container = forwardRef(({ as: Component = 'div', space, maxWidth, leftInset = true, rightInset = true, ...props }, ref) => {
    const tokens = useTokens();
    return (_jsx(Component, { css: getResponsiveStyles(combineResponsiveValues(space, maxWidth, leftInset, rightInset), ([space, maxWidth, leftInset, rightInset]) => ({
            marginInlineStart: leftInset
                ? maxWidth > 0
                    ? `max(${tokens.space[space]}, calc((100vw - ${maxWidth}px) / 2))`
                    : tokens.space[space]
                : '0',
            marginInlineEnd: rightInset
                ? maxWidth > 0
                    ? `max(${tokens.space[space]}, calc((100vw - ${maxWidth}px) / 2))`
                    : tokens.space[space]
                : '0',
        })), ...props, ref: ref }));
});
Container.displayName = 'Container';
