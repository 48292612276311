import { getResponsiveStyles } from '../../../component-themes';
import { mergeStyles } from '../../../component-themes';
export const headingBase = (tokens) => ({ size, color, emphasis, }) => {
    const [_size, level] = size.split('-');
    return mergeStyles([
        {
            margin: 0,
            padding: 0,
            color: tokens.colors['surface-onBase-50'],
            letterSpacing: tokens.styles[`headline-${_size}-level${level}-letterSpacing`],
            fontSize: tokens.styles[`headline-${_size}-level${level}-fontSize`],
            lineHeight: tokens.styles[`headline-${_size}-level${level}-lineHeight`],
            fontFamily: tokens.styles[`headline-${_size}-level${level}-fontFamily`],
            fontWeight: tokens.styles[`headline-${_size}-level${level}-fontWeight`],
        },
        color ? { color: tokens.colors[color] } : {},
        emphasis ? { opacity: tokens.emphasis[emphasis] } : {},
    ]);
};
export const heading = (tokens) => ({ size, color, emphasis, }) => getResponsiveStyles(size, (size) => headingBase(tokens)({ size, color, emphasis }));
